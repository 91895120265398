
//import HomePage from '../pages/home.f7.html';
//import AboutPage from '../pages/about.f7.html';
//import FormPage from '../pages/form.f7.html';

import UtilisateurPage from '../pages/utilisateur.f7.html';
//import SettingsPage from '../pages/settings.f7.html';

//import DynamicRoutePage from '../pages/dynamic-route.f7.html';
//import RequestAndLoad from '../pages/request-and-load.f7.html';
import NotFoundPage from '../pages/404.f7.html';


import AscesePage from '../pages/ascese.f7.html';
import MessagesPage from '../pages/messages.f7.html';
import MessagesContentsPage from '../pages/messages-contents.f7.html';
import EscouadePage from '../pages/escouade.f7.html';
import ProfilPage from '../pages/profil.f7.html';
import MeditationPage from '../pages/meditation.f7.html';
import BilanPage from '../pages/bilan.f7.html';
import CalendrierPage from '../pages/calendrier.f7.html';
import LiturgiePage from '../pages/liturgie.f7.html';
import PrieresPage from '../pages/prieres.f7.html';
import ProgressionPage from '../pages/progression.f7.html';
import StatutPage from '../pages/statut.f7.html';
import EngagementPage from '../pages/engagement.f7.html';
import ContenuPage from '../pages/contenu.f7.html';

var routes = [
  {
    path: '/',
    component: AscesePage,
  },
  /*
  {
    path: '/about/',
    component: AboutPage,
  },
  */
  /*
  {
    path: '/form/',
    component: FormPage,
  },
  */
  {
    path: '/contenu/:titre/:id/',
    component: ContenuPage,
  },
  {
    path: '/progression/',
    component: ProgressionPage,
  },
  {
    path: '/statut/',
    component: StatutPage,
  },
  {
    path: '/engagement/',
    component: EngagementPage,
  },
  {
    path: '/messages/',
    component: MessagesPage,
  },
  {
    path: '/messagesContents/:type/:vue/',
    component: MessagesContentsPage,
  },
  {
    path: '/calendrier/:datemd',
    component: CalendrierPage,
  },
  {
    path: '/liturgie/',
    component: LiturgiePage,
  },
  {
    path: '/prieres/',
    component: PrieresPage,
  },
  {
    path: '/utilisateur/:userPseudonyme/',
    component: UtilisateurPage,
  },
  {
    path: '/escouade/:id/',
    component: EscouadePage,
  },
  {
    path: '/meditation/:id/',
    component: MeditationPage,
  },
  {
    path: '/bilan/',
    component: BilanPage,
  },
  /*
  {
    path: '/settings/',
    component: SettingsPage,
  },
  */
  {
    path: '/profil/',
    component: ProfilPage,
  },
  /*
  {
    path: '/dynamic-route/blog/:blogId/post/:postId/',
    component: DynamicRoutePage,
  },
  */
   /*
  {
    path: '/request-and-load/user/:userId/',
    async: function ({ router, to, resolve }) {
      // App instance
      var app = router.app;

      // Show Preloader
      app.preloader.show();

      // User ID from request
      var userId = to.params.userId;

      // Simulate Ajax Request
      setTimeout(function () {
        // We got user data from request
        var user = {
          firstName: 'Vladimir',
          lastName: 'Kharlampidi',
          about: 'Hello, i am creator of Framework7! Hope you like it!',
          links: [
            {
              title: 'Framework7 Website',
              url: 'http://framework7.io',
            },
            {
              title: 'Framework7 Forum',
              url: 'http://forum.framework7.io',
            },
          ]
        };
        // Hide Preloader
        app.preloader.hide();

        // Resolve route to load page
        resolve(
          {
            component: RequestAndLoad,
          },
          {
            props: {
              user: user,
            }
          }
        );
      }, 1000);
    },
  },
  */
  {
    path: '(.*)',
    component: NotFoundPage,
  },
];

export default routes;
