import $ from 'dom7';
import Framework7, { getDevice } from 'framework7/bundle';

// Import F7 Styles
import 'framework7/framework7-bundle.css';

// Import Icons and App Custom Styles
import '../css/icons.css';
import '../css/app.css';
// Import Cordova APIs
import cordovaApp from './cordova-app.js';

// Import Routes
import routes from './routes.js';
// Import Store
import store from './store.js';

// Import main app component
import App from '../app.f7.html';

///// SL imports //////////////
// FontAwesome
import FontAwsome from './../css/all.css';
//import { icon, dom,library } from '@fortawesome/fontawesome-svg-core';
//import { fas } from '@fortawesome/free-solid-svg-icons';
//import { far } from '@fortawesome/free-regular-svg-icons';
//import { fab } from '@fortawesome/free-brands-svg-icons';
//import { config } from '@fortawesome/fontawesome-svg-core';
//import { findIconDefinition, icon } from '@fortawesome/fontawesome-svg-core';
// Add all icons to the library so you can use it in your page
//library.add(fas, far, fab);
//console.log("//31 app.js config.autoA11y : ",config.autoA11y)




// localforage
import "./localForage/dist/localforage.min.js";
//console.log('localforage is: ', localforage);
	// Md5
import {Md5} from './ts-md5/dist/md5';

	// Charts.js
// import Chart from './chart.js';
	// Tocca.js
import tocca from './Tocca.js';
	// utils
import js_date from './utils.js/date.js';
import js_mktime from './utils.js/mktime.js';

/////////////////////////////////
var device = getDevice();
console.log("//53 app.js device",device);
console.log("//54 app.js device.cordova",device.cordova);

var app = new Framework7({
  name: 'Societas laudis', // App name
  theme: 'auto', // Automatic theme detection
  el: '#app', // App root element
  component: App, // App main component
  id: 'org.societaslaudis.app', // App bundle ID
  isOnLine:true,
  // App store
  store: store,
  // App routes
  routes: routes,
  dialog: {
    // change default "OK" button text
    buttonCancel : 'Annuler'
  },
  //server: "http://localhost", // dev
  server: "https://www.societaslaudis.org", // prod
  // Register service worker
  serviceWorker: {
    path: '/service-worker.js',
  },
  // Input settings
  input: {
    scrollIntoViewOnFocus: device.cordova && !device.electron,
    scrollIntoViewCentered: device.cordova && !device.electron,
  },
  // Cordova Statusbar settings
  statusbar: {
    iosOverlaysWebView: true,
    androidOverlaysWebView: false,
  },
  on: {
    init: function () {
      var f7 = this;
      if (f7.device.cordova) {
        // Init cordova APIs (see cordova-app.js)
        cordovaApp.init(f7);
        
      }
      /*
      console.log("//84 app.js navigator.contacts : ",navigator.contacts);
      if(navigator.contacts) {
		var notificationFull = app.notification.create({
			icon: '<i class="icon demo-icon">7</i>',
			title: 'Framework7',
			titleRightText: 'now',
			subtitle: 'contacts',
			text: 'navigator.contacts présent !',
			closeTimeout: 3000,
		  });
		  notificationFull.open();
	  }
	  */
      reSync().then(chargementProfilDepuisLF);
			//chargementProfilDepuisLF();
      
    },
  },
});

function parseDate(date) {
  const parsed = Date.parse(date);
  if (!isNaN(parsed)) {
    return parsed;
  }
  return Date.parse(date.replace(/-/g, '/').replace(/[a-z]+/gi, ' '));
}

// procédure de démarrage

// 1 - récupérer le profil dans LF
//var profil = app.store.getters.profil;
//chargementProfilDepuisLF();
// 	supprimmer les éventuelles valeurs inutiles
// 2 - tenter une connexion
//	si le profil.value.email est nul, retour
// 3 - syncProfil() : on fait un upload ou un download du profil
//	en prenant la valeur du profil la plus récente entre le client (app) et le serveur (sync.php)

//console.log("//104 app.js app.params.server : ",app.params.server);

//1 - On charge le profil à partir du LF
function chargementProfilDepuisLF() {
	//app.preloader.show();
	var profil = app.store.getters.profil;
	localforage.getItem('syncedProfil', function(err, syncedProfil) {
    // Run this code once the value has been
    // loaded from the offline store.
    if(! syncedProfil) syncedProfil = {};
    Object.assign(profil.value,syncedProfil);
    console.log("//107 app.js chargementProfilDepuisLF() profil.value.updated : ",profil.value.updated);
    console.log("//108 app.js chargementProfilDepuisLF() syncedProfil.updated",syncedProfil.updated);
    
    //profil.value.email =  syncedProfil.userEmail;
    //profil.value.password =  syncedProfil.userPWD;
    //profil.value.hashPassword =  syncedProfil.hashUserPWD;
    //profil.value.hashEmail =  syncedProfil.hashUserEmail;
    // on supprimme les données inutiles qu'il ne faut pas garder
		delete profil.value.connected;
		delete profil.value.hashUserPWD;
		delete profil.value.userEmail;
		delete profil.value.userPWD;
		delete profil.value.lastSyncedStats;
		delete profil.value.firstConnected;
		delete profil.value.clefEscouade;
		delete profil.value.dateInscription;
		delete profil.PWDChanged;
    //profil.value.updated = js_date("Y-m-d H:i");
    localforage.getItem('syncedStats', function(err, syncedStats) {
			if(syncedStats) profil.value.stats = syncedStats;
			localforage.getItem('syncedValidatedActivites', function(err, syncedValidatedActivites) {
				if(syncedValidatedActivites) profil.value.validatedActivities = syncedValidatedActivites;
				localforage.getItem('syncedValidatedMeditations', function(err, syncedValidatedMeditations) {
					if(syncedValidatedMeditations) profil.value.meditationsValidees = syncedValidatedMeditations;
					// ICI APPEL de 
						// 2 - tenter une connexion
						//	si le profil.value.email est nul, retour
						//connexion();
						// 3 - syncProfil() : on fait un upload ou un download du profil
						// DEBUG pour test on met le calculsattistiques ici. Pas sûr que ce soir le meilleur endroit.
						//calculStatistiques();
						app.store.dispatch('updateProfil', profil.value);
						syncProfil();
						upSyncJSONMessages();
						//downSyncJSONMessages(); en fait dès qu'on a une upsyncmessages, la fonction downsyncmessages est appelée en fin de routine
						//app.preloader.hide();
				}); //syncedValidatedMeditations
			}); // syncedValidatedActivites
		}); // syncedStats
	}); //syncedProfil
	
	
	
	
	//app.preloader.hide();
	
  //TODO on supprimme dans LF ! important sinon ça ecrasera les mises à jour directes dans store.profil dans le futur:
		// syncedSats
		// syncedValidatedActivites
		// syncedValidatedMeditations
	localforage.removeItem('syncedStats').then(function() {
		console.log('Key syncedStats is cleared!');
	}).catch(function(err) {
		// This code runs if there were any errors
		console.log(err);
	});
	localforage.removeItem('syncedValidatedActivites').then(function() {
		console.log('Key syncedValidatedActivites is cleared!');
	}).catch(function(err) {
		// This code runs if there were any errors
		console.log(err);
	});
	localforage.removeItem('syncedValidatedMeditations').then(function() {
		console.log('Key syncedValidatedMeditations is cleared!');
	}).catch(function(err) {
		// This code runs if there were any errors
		console.log(err);
	});
	
  
	
	//TODO on sauvegarde le profil.value mis à jour dans LF.syncedProfil !important
	console.log("//175 app.js profil.value ",profil.value);
	
	
	//app.emit('profilUpdated', true); //mute = true
	console.log("//179 app.js chargementProfilDepuisLF() profil.value.updated : ",profil.value.updated);
	return;
}


function syncProfil() {
	// on cherche la date de MAJ du profil sur le serveur sur sync.php / syncProfil() via une xhr à la sauce F7
	var profil = app.store.getters.profil;
	//console.log("//164 app.js syncProfil() profil.value : ",profil.value);
	var credentials = { hashEmail:profil.value.hashEmail };
	app.request.post(app.params.server+'/societas2/sync.php', { action:'syncProfil', credentials: JSON.stringify(credentials) })
		.then(function (res) {
		//console.log("//168 app.js syncProfil() profil.value.updated : "+profil.value.updated);
    //console.log("//169 app.js syncProfil() profil.value : ",profil.value);
    //console.log("//170 app.js syncProfil res.data : ",res.data);
    var remoteUdpatedDate = parseDate(res.data);
    var localUpdateDate = 0;
    if(profil.value.updated) localUpdateDate = parseDate(profil.value.updated);
    //console.log("//174 app.js syncProfil localUpdateDate : "+profil.value.updated+" "+localUpdateDate+" // remoteUdpatedDate : "+res.data+" "+remoteUdpatedDate);
    if(localUpdateDate > remoteUdpatedDate)  upSyncProfil();
    else downSyncProfil();
  });
	// si la date du serveur > date store.profil.updated downSyncProfil().
	//	sinon, upsSyncProfil
	function upSyncProfil(){
		//on prend le profil dans store.profil
		var profilToBeUpSynced = {};
		Object.assign(profilToBeUpSynced,profil.value);
		// on supprimme les valeurs sensibles 
		//	profil.value.email
		//	profil.value.password
		//	profil.value.hashPassword
		delete profilToBeUpSynced.email;
		delete profilToBeUpSynced.hashEmail;
		delete profilToBeUpSynced.password;
		delete profilToBeUpSynced.hashPassword;
		// on envoie le tout en xhr à la sauce F7 sur sync.php / upSyncProfil()
		var credentials = {
			hashEmail : profil.value.hashEmail,
			hashPassword : profil.value.hashPassword,
			profil: profilToBeUpSynced,
			GDH: js_date('Y-m-d H:i')
			};
		app.request.post(app.params.server+'/societas2/sync.php', { action:'upSyncProfil', credentials: JSON.stringify(credentials) })
		.then(function (res) {
			console.log("//230 app.js upSyncProfil res.data : ",res.data);
			
		});
		
	}
	function downSyncProfil(){
		// ??? vérifier la connexion ?
		//on recupère le profil sur le serveur en xhr à la sauce F7 sur sync.php downSyncProfil()
		var credentials = { 
			hashEmail:profil.value.hashEmail,
			hashPassword:profil.value.hashPassword,
			GDH:js_date('Y-m-d H:i')
		};
			app.request.post(app.params.server+'/societas2/sync.php', { action:'downSyncProfil', credentials: JSON.stringify(credentials) })
		  .then(function (res) {
				
				var reponse = JSON.parse(res.data);
				
				if(reponse.ACK == "OK") {
					console.log("//242 app.js downSyncProfil JSON.parse(reponse.profil) : ",JSON.parse(reponse.profil));
					var profilToBeLF = JSON.parse(reponse.profil);
					profilToBeLF.hashEmail = profil.value.hashEmail;
					profilToBeLF.hashPassword = profil.value.hashPassword;
					profilToBeLF.email = profil.value.email;
					profilToBeLF.password = profil.value.password;
					// on enregistre dans LF
					localforage.setItem('syncedProfil', profilToBeLF).then(function (syncedProfil) {
					}).catch(function(err) {
							console.log(err);
					});
					// on enregistre dans store.profil.value
					profil.value = JSON.parse(reponse.profil);
				}
				
				
			});
		
		//on met à jour le store.profil.value
		// event 
		
	}
	return;
}


if(localStorage.syncParams) { 
	var automaticSync = JSON.parse(localStorage.syncParams).automaticSync;
	console.log("//317 app.js automaticSync : ",automaticSync);
	if(automaticSync[0]=="on") reSync();
}

/*
// ASCESE IERUSALEM 
$(document).on('page:init','.page[data-name="ascese"]', function (e) {
	console.log("//316 (document).on('page:init' page[data-name=ascese]");

});

// MEDITATION

$(document).on('page:init','.page[data-name="meditation"]', function (e) {

});
*/
/*
// CALENDRIER
$(document).on('page:init','.page[data-name="calendrier"]', function (e) {
	
});

// LITURGIE
$(document).on('page:init','.page[data-name="liturgie"]', function (e) {
	
});

// PRIERES
$(document).on('page:init','.page[data-name="prieres"]', function (e) {
	
});
*/
app.on('calculStatistiques', function () {
	console.log("//344 app.js on('calculStatistiques' "); 
	calculStatistiques() 
});

function calculStatistiques(){
	// une fonction qui est appelée à chaque ajout d'une activité et du bilan
	// qui calcule les statistiques à partir des activités 
	//				!!! TODO depuis le dernier calcul des stats à mettre dans localStorage et jusqu'à maintenant
	// qui sauvegarde les stats dans profil
	// qui upsync les stats vers le serveur
	// 1 - statsToday : stats calculées pour aujourd'hui.
	// 		Pour cela on prend la valeur de ferveur de la veille dans syncedStats
	//		Si il n'y a rien pour syncedStats.aujourd'hui generalcalcul.ferveur = 100;
	// 2 - On calcule la ferveur pour les 7 jours suivants  
	//	?? voire plus si l'user ne se connecte pas dans les prochains jours ?
	// 		
	// 3 - On ajoute tout dans syncedStats après avoir retiré l'entrée d'aujourd'hui jusqu'à j+7
	
	var todayYmd = js_date("Ymd");
	var thisDateTS;
	var profil = app.store.getters.profil;
	
	 console.log("//335 app.js calculStatistiques() syncedProfil : ",profil);

	var dateTSInscription = parseDate(profil.value.statut[0])/1000;
	if(profil.value.statut.length>4) dateTSInscription = parseDate(profil.value.statut[4])/1000; // remise à zéro en cas d'engagement à partir vers Jéruslem
	var inscriptionYmd = js_date("Ymd",dateTSInscription);
	
	
	var dateTSAujourdhui = Date.now()/1000;
	// boucle dateTSInscription -> dateTSAujourdhui // plus tard mettre plutot veille date dernière sync ->aujourd'hui)
	//	calcul sur bilan
	//	calcul sur validated activities
	
	var ferveurNoMeditation, ferveurNoGrandeHeure, ferveurNoRosaire, ferveurAlcool, ferveurScreen;
	var ferveurNoColdShower, ferveurNoSilence, ferveurDessert; 
	var stats = [];

	var profil = app.store.getters.profil;
	var bilans = profil.value.bilans;
	if(!bilans)	bilans = [];
	
	var stats = [];	
		
	var ferveur = 100;	
	for (var ceJourTS = dateTSInscription; ceJourTS < (Date.now()/1000) +60*60*24 ; ceJourTS+= 60*60*24) { // boucle dateTSInscription -> aujourd'hui
			console.log("//356 app.js calculStatistiques() ceJourISO : ",js_date("Y-m-d"),ceJourTS);
			var ceJourStats={
				dateYmd: js_date("Ymd",ceJourTS),
				dateISO: js_date("Y-m-d",ceJourTS),
				dateTS : ceJourTS,
				ascese: 0,
				caritas: 0,
				km: 0,
				liturgie: 0,
				piete: 0,
				ferveur: ferveur
			};
			
			console.log("//368 app.js calculStatistiques() ceJourStats : ",ceJourStats);
			if(ceJourStats.dateISO == js_date('Y-m-d',dateTSInscription)) ceJourStats.ferveur = 100; // On remet à 100 la ferveur à l'inscription
			// TODO remettre la ferveur à 100 et les km totaux à 0 en fonction de la date de départ vers Jérusalem
			
			// BAREME : (ces valeurs sont mises à zéro si on a été observant.
			ferveurNoMeditation = -5; //on a manqué une méditation -5. 
			ferveurNoGrandeHeure = -5; // on n'a pas récité une grande heure (laudes vepres) par jour : - 5
			ferveurNoRosaire = -5; //On a raté le rosaire = -5
			ferveurAlcool = -5; // On a raté une pratique ascétique : -5.
			ferveurScreen = -5; // On a utilisé les ecrans pour le loisir
			ferveurNoColdShower = -5; // On a pris une douche chaude
			ferveurNoSilence = -5; // On n'a pas fait de vrai effort sur le silence;
			ferveurDessert = -5; // On a pris un dessert sucré
			
			var ceJourBilan = bilans.filter(d => d.dateISO == js_date('Y-m-d',ceJourTS))[0];
			// Calcul dans bilans
			// on enregistre le calcul des bilans avec un index entier pour pouvoir faire des bilans.filter()
			if(ceJourBilan){ // On fait le calcul avec le bilan du jour
				console.log("//418 app.js calculStatistiques()  ceJourBilan",ceJourBilan);
				if(ceJourBilan.asceseBilanCaritasBonte.checked == true) ceJourStats.caritas += 2.5;	
				if(ceJourBilan.asceseBilanCaritasDesinteressement.checked == true) ceJourStats.caritas += 2.5;	
				if(ceJourBilan.asceseBilanCaritasDouceur.checked == true) ceJourStats.caritas += 2.5;
				if(ceJourBilan.asceseBilanCaritasEndurance.checked == true) ceJourStats.caritas += 2.5;	
				if(ceJourBilan.asceseBilanCaritasPatience.checked == true) ceJourStats.caritas += 2.5;
				if(ceJourBilan.asceseBilanCommunionReceived.checked == true) ceJourStats.ferveur += 10;
				if(ceJourBilan.asceseBilanConfession.checked == true) ferveur += 10;
				if(ceJourBilan.asceseBilanJeune.checked == true) ceJourStats.ascese += 15;
				if(ceJourBilan.asceseBilanMeditationComprehension.checked == true) ferveur += 3;
				if(ceJourBilan.asceseBilanMeditationNodistraction.checked == true) ferveur += 3;
				if(ceJourBilan.asceseBilanMeditationPersonalprayer.checked == true) ferveur += 4;
				if(ceJourBilan.asceseBilanMeditationPrepared.checked == true) ferveur += 1;	
				if(ceJourBilan.asceseBilanMortificationNoAlcool.checked == true){
					ceJourStats.ascese += 2.5;
					ferveurAlcool = 0;
					}
				if(ceJourBilan.asceseBilanMortificationNoEcran.checked == true){
					ceJourStats.ascese += 2.5;
					ferveurScreen = 0;
					}
				if(ceJourBilan.asceseBilanMortificationNoHotShower.checked == true){
					ceJourStats.ascese += 2.5;
					ferveurNoColdShower = 0;
					}
				if(ceJourBilan.asceseBilanOfficeInCommon.checked == true) ceJourStats.ferveur += 3;
				if(ceJourBilan.asceseBilanOfficeSung.checked == true) ceJourStats.ferveur += 3;	
				if(ceJourBilan.asceseBilanParticipationMesse.checked == true) ceJourStats.ferveur += 5;
				if(ceJourBilan.asceseBilanPriereConcentration.checked == true) ceJourStats.ferveur += 3;
				if(ceJourBilan.asceseBilanSilence.checked == true){
					ceJourStats.ascese += 2.5;
					ferveurNoSilence = 0;
					}
				if(ceJourBilan.asceseBilanSommeil.checked == true) ceJourStats.ascese += 2.5;
				
				
					
				// TODO calculer si on a fait de l'exercice lors de la semaine passée
				// TODO calculer si on a jeûné le vendredi passé
			}
			/*
			else { // Il n'y a aucun bilan de fait pour le jour concerné todayYmd
				ceJourStats.ferveur +=  0 
					+ ferveurAlcool 
					+ ferveurScreen 
					+ ferveurNoColdShower 
					+ ferveurNoSilence 
					+ ferveurDessert;
					
			}*/
		
			////////////////////////////////////////////////////////////////
			// Suite du ceJourStats avec les profil.validatedAcivites /////
			////////////////////////////////////////////////////////////////
			
			var validatedActivities = profil.value.validatedActivities;
			if(!validatedActivities) validatedActivities=[];
			var ceJourActivites = validatedActivities.filter (d => js_date("Ymd",d.dateTS) == js_date("Ymd",ceJourTS));
			console.log("//440 app.js calculStatistiques() ceJourActivites",ceJourActivites);
				
			// BAREME : (ces valeurs sont mises à zéro si on a été observant.
			ferveurNoMeditation = -5; //on a manqué une méditation -5. 
			ferveurNoGrandeHeure = -5; // on n'a pas récité une grande heure (laudes vepres) par jour : - 5
			ferveurNoRosaire = -5; //On a raté le rosaire = -5
			
			for(var i=0; i <ceJourActivites.length; i++) { //boucle sur les  ceJourActivites
				if (ceJourActivites[i].activite=="messe") ceJourStats.liturgie += 10;
				if (ceJourActivites[i].activite=="laudes") {
					ceJourStats.liturgie += 7.5;
					ferveurNoGrandeHeure = 0;
				}
				if (ceJourActivites[i].activite=="vepres") {
					ceJourStats.liturgie += 7.5;
					ferveurNoGrandeHeure = 0;
				}
				if (ceJourActivites[i].activite=="complies") {
					ceJourStats.liturgie += 2.5;
					ferveurNoGrandeHeure = 0;
				}
					
				// en suivant les pratiques liturgiques quotidiennes, (grande heure + messe + complies) on gagne 20 km mini
				if (ceJourActivites[i].activite=="tierce") ceJourStats.liturgie += 1;
				if (ceJourActivites[i].activite =="sexte") ceJourStats.liturgie += 1;
				if (ceJourActivites[i].activite == "none") ceJourStats.liturgie += 1;
				
				if (ceJourActivites[i].activite=="rosario") {
					ceJourStats.piete += 7;
					ferveurNoRosaire = 0;
				}
				if (ceJourActivites[i].type=="meditation") {
					ceJourStats.piete += 12.5;
					ferveurNoMeditation = 0;
				}
				// seulement en récitant le chapelet + en faisant la méditation, on gagne 20 km
				if (ceJourActivites[i].activite=="regula") ceJourStats.piete += 1;
				if (ceJourActivites[i].activite=="commentaire") ceJourStats.piete += 2;
				if (ceJourActivites[i].activite=="compendium")ceJourStats.piete += 1;
				if (ceJourActivites[i].type=="priere") ceJourStats.piete += 0.5;
				//if (itemActivite.type=="visiteTSS" && js_date("Ymd",itemActivite.dateTS) == todayYmd) statsToday[todayYmd].piete += 7.5;
				
				//if (itemActivite.activite=="exercice" && js_date("Ymd",itemActivite.dateTS) == todayYmd) statsToday[todayYmd].ascese += 2.5;
				 
				// en suivant les pratiques ascétiques quotidiennes, on gagne 20 km mini.
				if (ceJourActivites[i].activite=="share") ceJourStats.ferveur += 1;
				
				
			} 
			// fin boucle sur les ceJourActivites
			
			// Calcul final pour ferveur
			//console.log("//1748 Calcul final pour ferveur");
			ferveur += 0
				+ ferveurNoMeditation 
				+ ferveurNoGrandeHeure 
				+ ferveurNoRosaire
				+ ferveurAlcool
				+ ferveurScreen 
				+ ferveurNoColdShower 
				+ ferveurNoSilence 
				+ ferveurDessert; 
				
			ceJourStats.km = ceJourStats.liturgie + ceJourStats.piete + ceJourStats.ascese + ceJourStats.caritas;
			ceJourStats.ferveur = ferveur;
			if(ceJourStats.ferveur < 0) {
				ceJourStats.ferveur = 0;
				ferveur = 0;
			}
			
			//on ajoute le calcul du jour dans stats
			stats.push(ceJourStats); 
			
		} 
		// fin boucle dateTSInscription -> aujourd'hui
		
		
		var statsFutur = [];
		var cetteFerveur ;
		if(stats[stats.length-1]) cetteFerveur = stats[stats.length-1].ferveur; // valeur de la ferveur qu'on fait diminuer dans la boucle de calcul pour le futur
		else cetteFerveur = 100;
		var todayTS = Date.now()/1000;
		// Boucle sur les prochains 10 jours
		for(var i=1;i <11;i++){
			cetteFerveur += 0
				+ ferveurNoMeditation //= -5; //on a manqué une méditation -5. 
				+ ferveurNoGrandeHeure //= -5; // on n'a pas récité une grande heure (laudes vepres) par jour : - 5
				+ ferveurNoRosaire //= -5; //On a raté le rosaire = -5
				+ ferveurAlcool //= -5; // On a raté une pratique ascétique : -5.
				+ ferveurScreen //= -5; // On a utilisé les evrans pour le loisir
				+ ferveurNoColdShower //= -5; // On a pris une douche chaude
				+ ferveurNoSilence //= -5; // On n'a pas fait de vrai effort sur le silence;
				+ ferveurDessert //= -5; // On a pris un dessert sucré
			if (cetteFerveur < 0) cetteFerveur = 0;
			var ceJourCalculFutur = {
				dateYmd : js_date("Ymd", todayTS+i*60*60*24),
				dateISO: js_date("Y-m-d",ceJourTS),
				dateTS : todayTS+i*60*60*24,
				ferveur: cetteFerveur,
				ascese: 0,
				caritas: 0,
				km: 0,
				liturgie: 0,
				piete: 0
			}
			stats.push(ceJourCalculFutur);
		} 

		console.log("//576 app.js calculStatistiques() en fin de routine - stats : ",stats);	
		// TODO Sauvegarde dans profil et event profil updated
		profil.value.stats = stats;
		app.store.dispatch('updateProfil', profil.value);
		//app.emit('profilUpdated', true); //mute = true
		app.emit('utilisateurUpdated');
			// est ce que cela ne va pas s'entrechoquer avec le syncProfil du lancement ? avec une async etc ?
			// gérer le multi terminal ? 
			
} //calculStatistiques


// on connection (au réseau internet)
app.on('connection', function (isOnline) {
  if (isOnline) {
    
    app.params.isOnLine = true;
    console.log('app is online now ?',app.params.isOnLine);
  } else {
    
    app.params.isOnLine = false;
    console.log('app is online now ?',app.params.isOnLine);
  }
});

// tous les tabs
app.on('tabShow', function (e) {
	console.log("//897 app.js on('tabShow'");
	downSyncJSONMessages();
		
});

async function downSyncJSONMessages() {
	console.log("//887 app.js downSyncJSONMessages()");
	var profil = app.store.getters.profil;
	if(app.params.isOnLine == true) { // l'application est en ligne
		if(! localStorage.dateLastSyncedMessagesTS) localStorage.dateLastSyncedMessagesTS = Date.now()/1000;
		var credentials = {
			userPseudonyme: profil.value.userPseudonyme,
			hashEmail : profil.value.hashEmail,
			hashPassword: profil.value.hashPassword,
			GDH: js_date('Y-m-d H:i'),
			dateISO: js_date('Y-m-d H:i'),
			dateLastSyncedMessagesTS: localStorage.dateLastSyncedMessagesTS
		};
		console.log("//627 app.js downSyncJSONMessages() credentials : ",credentials);
		console.log("//628 app.js downSyncJSONMessages() js_date('Y-m-d H:i:s',credentials.dateLastSyncedMessagesTS) : ",js_date('Y-m-d H:i:s',credentials.dateLastSyncedMessagesTS));
		app.request.post(app.params.server+'/societas2/sync.php', { action:'downSyncJSONMessages',credentials:JSON.stringify(credentials) })
		.then(function (res) {
		
			// on fait un append pour ne pas effacer ce qui a déjà été téléchargé
			// comparer avec le nb de messages lus dans localStorage. 
			//		mettre à jour l'indicateur badge de messages non lus.
			console.log("//635 app.js action:'downSyncJSONMessages' res.data : ",res.data);
			
			var reponse = JSON.parse(res.data);
			if(!reponse.messages) reponse = [];
			localforage.getItem('syncedJSONMessages', function(err, syncedJSONMessages) {
				if(! syncedJSONMessages) syncedJSONMessages = [];
				console.log("//641 app.js downSyncJSONMessages reponse.messages : ",reponse.messages);
				if(reponse.messages.length == 0) {
					localStorage.nouveauxMessages = Number(reponse.messages.length) + Number(localStorage.nouveauxMessages);
					// pour l'indicateur de nx messages.
					if(localStorage.nouveauxMessages == 0) {
						 $('.newMessages').hide();
					 } else {
						$('.newMessages').show();
						$('.newMessages').html(localStorage.nouveauxMessages);
					}
					
				}
				if(reponse.messages.length == 0) { // pas de nx mesages téléchargés
					localforage.setItem('syncedJSONMessages',[]).then(function (syncedJSONMessages) {	
					}).catch(function(err) {
							console.log(err);
					})
				}
				if(reponse.messages.length > 0) {
						
						for (var j = 0; j < syncedJSONMessages.length  ;  j ++) {
							var r = reponse.messages.filter(d => d.TS != syncedJSONMessages[j].TS);
							var s = reponse.messages.filter(d => d.TS == syncedJSONMessages[j].TS);
							if(s[0]) syncedJSONMessages[j].upSynced = s[0].upSynced;
							reponse.messages = r;
							//syncedJSONMessages[j].synced = r[0].synced;
							
							 }
						//}
					
					console.log("//973 app.js downSyncJSONMessages (après suppressions) reponse.messages : ",reponse.messages);
					//syncedJSONMessages.push(reponse.messsages);
					for (var i=0; i < reponse.messages.length; i++) {
						syncedJSONMessages.push(reponse.messages[i]);
					}
					console.log("//942 app.js downSyncJSONMessages syncedJSONMessages : ",syncedJSONMessages);
				
				//console.log(syncedJSONMessages);
				localStorage.dateLastSyncedMessagesTS = parseDate(credentials.dateISO)/1000;
					localforage.setItem('syncedJSONMessages',syncedJSONMessages).then(function (syncedJSONMessages) {
						//MAJ de l'indicateur nouveaux messages 
						localStorage.dateLastSyncedMessagesTS = Date.now()/1000; // on ne met à jour la date de sync de smessages que si on en a téléchargé de nouveaux
						localStorage.dateLastSyncedMessagesISO = js_date("Y-m-d H:i:s",localStorage.dateLastSyncedMessagesTS);
						localStorage.nouveauxMessages = Number(reponse.messages.length) + Number(localStorage.nouveauxMessages);
						if(localStorage.nouveauxMessages > 0) $('.newMessages').show();
						else $('.newMessages').hide();
						$('.newMessages').html(localStorage.nouveauxMessages);
				
						
						
					}).catch(function(err) {
							console.log(err);
					});
					//setItem syncedJSONMessages
					}
				});
			// getItem syncedJSONMessages
			
			
			
			}); // request downSyncJSONMessages
		}
}

app.on('addNouveauMessage', function (messageToAdd) {
	console.log("//708 app.js event addNouveauMessage messageToAdd = ",messageToAdd);
	if(messageToAdd.auteur == undefined) messageToAdd.auteur = "Anonyme";
	// On ajoute le message dans LF.syncedJSONMessages avec un notSynced pour qu'il apparaisse à l'écran.
	messageToAdd.upSynced= false;
	localforage.getItem('syncedJSONMessages', function(err, syncedJSONMessages) {
		syncedJSONMessages.push(messageToAdd);
		localforage.setItem('syncedJSONMessages', syncedJSONMessages).then(function (syncedJSONMessages) {
			localStorage.nouveauxMessages = Number(localStorage.nouveauxMessages)+1;
			console.log("//691 app.js event addNouveauMessage localStorage.nouveauxMessages =", localStorage.nouveauxMessages);
			
			$('.newMessages').html(localStorage.nouveauxMessages);
			// Si l'app est on line
			if(app.params.isOnLine == true) { // l'application est en ligne
				//	on tente un upSyncJSONMessages (TODO à créer) dans app.js 
				upSyncJSONMessages();
			}
			
			}).catch(function(err) {
				console.log(err);
			});
			//setItem('syncedJSONMessages'

	});
	// getItem('syncedJSONMessages'
	// Si l'app est offline !!! Dans event on line, rappeler la fonction upSyncJSONMessages 
	
});

function upSyncJSONMessages() {
	console.log("//819 app.js upSyncJSONMessages");
	//	TODO voir : celui de messages-contents.f7 n'est pas utilisable  mais il devrait appeler la même fonction sur sync.php 
	// On sélectionne dans LF. syncedJSONMessages ceux qui ont notSynced == true
	localforage.getItem('syncedJSONMessages', function(err, syncedJSONMessages) {
		if(syncedJSONMessages) {
			 var messagesToBeUpSynced = syncedJSONMessages.filter(d => d.upSynced == false);
			 console.log("//825 app.js upSyncJSONMessages messagesToBeUpSynced : ",messagesToBeUpSynced);
		 }
		else return;
		// credentials comportant
		// hashEmail, hashPassword, GDH, localStorage.dateLastSyncedMessagesTS, dateISO,  messagesToBeUpSynced
		var profil = app.store.getters.profil;
		var credentials = {
			hashEmail: profil.value.hashEmail,
			hashPassword: profil.value.hashPassword,
			GDH: js_date('Y-m-d H:i'),
			dateLastSyncedMessagesTS : localStorage.dateLastSyncedMessagesTS,
			dateISO: js_date('Y-m-d'),
			TS: Date.now()/1000,
			messagesToUpSync: messagesToBeUpSynced
		}
		console.log("//840 app.js upSyncJSONMessages credentials = ",credentials);
		// On envoie sur sync.php action:upSyncJSONMessages 
		app.request.post(app.params.server+'/societas2/sync.php', { action:'upSyncJSONMessages',credentials:JSON.stringify(credentials) })
		.then(function (res) {
			console.log("//844 app.js upSyncJSONMessages res.data = ",res.data);
			// On lance un downSyncJSONMessages qui doit supprimmer les LF.syncedJSONMessages qui sont déjà présents dans LF
			downSyncJSONMessages();
		}); // request upSyncJSONMessages
		
	}); 
	//LF getItem('syncedJSONMessages'
	
	
	
	// 		!! TODO cette fonction sur le serveur doit inscrire le(s) messages en question à la bonne date dans le bon container de message (laus perennis, escouade, perso)
	
	
}

// On profilUpdated
console.log("//1045 app.js app.store",app.store);
app.on('profilUpdated', function (mute) {
  console.log("//1047 app.js event profilUpdated mute = ",mute); 
  var profil = app.store.getters.profil;
  // TODO recalcul des statistiques
  calculStatistiques(); 
  
  var profilToUpSync = {};
  profil.value.updated=js_date("Y-m-d H:i:s");
  Object.assign(profilToUpSync,profil.value);
  // on enlève les données non publiques
  delete profilToUpSync.email;
  delete profilToUpSync.password;
  delete profilToUpSync.hashPassword;
  delete profilToUpSync.hashUserEmail;
  // on enlève les données inutiles qu'il ne faut pas garder
  delete profilToUpSync.connected;
  delete profilToUpSync.hashUserPWD;
  delete profilToUpSync.userEmail;
  delete profilToUpSync.userPWD;
  delete profilToUpSync.lastSyncedStats;
  delete profilToUpSync.firstConnected;
  delete profilToUpSync.clefEscouade;
  delete profilToUpSync.dateInscription;
  delete profilToUpSync.PWDChanged;
  //console.log("//358 app.js profil.value",profil.value);
  //Upsync du profil
  var credentials = {
		hashEmail : profil.value.hashEmail,
		hashPassword : profil.value.hashPassword,
		profil : profilToUpSync,
		GDH: js_date('Y-m-d H:i')
	};
	//console.log("//409 app.js event profilUpdated function credentials : ",credentials);
	// TODO Si l'app est on line
  if(app.params.isOnLine == true) {// l'application est en ligne
		app.request.post(app.params.server+'/societas2/sync.php', { 
			action:'upSyncProfil',
				credentials : JSON.stringify(credentials)
				})
				.then(function (res) {
					//on met à jour le profil dans sync.php
					//console.log("415 app.js event profilUpdated res : ",res.data);
					
					// si mute == false, notification
					if(mute == false) {
						
						//app.preloader.hide();
						var notificationFull = app.notification.create({
							icon: '<i class="f7-icons">person</i>',
							title: 'Profil',
							titleRightText: 'Maintenant',
							subtitle: 'Synchronisation de votre profil.',
							text: 'Votre profil a bien été mis à jour et synchronisé sur le serveur.',
							closeTimeout: 3000,
						});
						notificationFull.open();
						
						//TODO Laus perennis "X a mis à jour son profil"
					}
		});
	}
	if(app.params.isOnLine == false) {// l'application est hors ligne
		if(mute == false) {
			//app.preloader.hide();
			var notificationFull = app.notification.create({
				icon: '<i class="f7-icons">person</i>',
				title: 'Profil',
				titleRightText: 'Maintenant',
				subtitle: 'Synchronisation de votre profil.',
				text: 'Votre profil a bien été mis à jour en local mais n\'a pas pu être synchronisé avec le serveur car vous n\'êtes pas connecté à internet. Recommencez plus tard.',
				closeTimeout: 4000,
			});
			notificationFull.open();
			
		}
	}
	
  
  //app.preloader.show();
  var newSyncedProfil = {};
  Object.assign(newSyncedProfil,profil.value);
  // on enlève les données inutiles qu'il ne faut pas garder
  delete newSyncedProfil.connected;
  delete newSyncedProfil.hashUserEmail;
  delete newSyncedProfil.hashUserPWD;
  delete newSyncedProfil.userEmail;
  delete newSyncedProfil.userPWD;
  delete newSyncedProfil.lastSyncedStats;
  delete newSyncedProfil.firstConnected;
  delete newSyncedProfil.clefEscouade;
  delete newSyncedProfil.dateInscription;
  delete newSyncedProfil.PWDChanged;
  
  // Sauvegarde dans LF
  localforage.setItem('syncedProfil', newSyncedProfil).then(function (syncedProfil) {
			// Do other things once the value has been saved.
			console.log(syncedProfil);
	}).catch(function(err) {
			// This code runs if there were any errors
			console.log(err);
	});
  console.log("//299 app.js newSyncedProfil : ",newSyncedProfil);
  
  //app.preloader.hide();

}); //fin on('profilUpdated'

// On resync
app.on('reSync', reSync);
//reSync();
async function reSync(dateISO=null,nbJours=null) {
	console.log("//1155 app.js reSync()");
	
	// Prières
	app.request.post(app.params.server+'/societas2/sync.php', { action:'downSyncPrieres' })
		.then(function (res) {
			localforage.setItem('syncedOrationes', JSON.parse(res.data)).then(function (orationes) {
				
			}).catch(function(err) {
					console.log(err);
			});
	}); 
	// JSONContents
	app.request.post(app.params.server+'/societas2/sync.php', { action:'downSyncJSONContents' })
	  .then(function (res) {
		  if(res.data) {
			 console.log("//912 app.js JSON.parse(res.data)",JSON.parse(res.data));
			 var VezelayToJerusalem = JSON.parse(res.data).filter(d => d.id == "Vezelay-Jerusalem" )[0];
			console.log("//914 app.js VezelayToJerusalem",VezelayToJerusalem);	
			app.store.dispatch('updateVezelayToJerusalem',VezelayToJerusalem);
			console.log("//916 app.js downSyncJSONContents : app.store.getters.VezelayToJerusalem : ",app.store.getters.VezelayToJerusalem);	
				
		  }
		localforage.setItem('syncedJSONContents', JSON.parse(res.data)).then(function (JSONContents) {
				
			}).catch(function(err) {
				console.log(err);
			});
			
	  });
	
	
	// Contents
	app.request.post(app.params.server+'/societas2/sync.php', { action:'downSyncContents' })
	  .then(function (res) {
		localforage.setItem('syncedContents', JSON.parse(res.data)).then(function (contents) {
			
			}).catch(function(err) {
					console.log(err);
			});
			
	  });
  var credentials = {
		dateISO : js_date('Y-m-d'),
		nbMois : 12
	}
	
	// Calendrier
  app.request.post(app.params.server+'/societas2/sync.php', { action:'downSyncJSONKalendarium',credentials:JSON.stringify(credentials) })
  .then(function (res) {
		// TODO faire un append pour ne pas effacer ce qui a déjà été téléchargé
    localforage.setItem('syncedJSONKalendarium', JSON.parse(res.data)).then(function (contents) {
			
		}).catch(function(err) {
				console.log(err);
		});
		
  });
	
	if( app.params.isOnLine == true ) {
		//app.preloader.show();

	 //credentials par défaut en fonction des paramètres / onglet de droite et pris dans localStorage
	 // Attention : les 2 LF liturgie et ordo doivent être synchronisés de concert
	 if(! localStorage.syncParams) {
		 localStorage.syncParams = '{"duree_sync":"7","automaticSync":["on"]}';
	 }
	 console.log("//1199 app.js reSync JSON.parse(localStorage.syncParams duree_sync)",JSON.parse(localStorage.syncParams).duree_sync);
	 if(! dateISO) {
			var credentials = {
			 dateISO:js_date("Y-m-d"),
			 nbJours:JSON.parse(localStorage.syncParams).duree_sync
		 };	
	 } else { // C'est une synchro à partir de l'event resync
		 //TODO passer les paramètres dateISO et nbJours
		 var credentials = {
			 dateISO:dateISO,
			 nbJours:nbJours
		 };
	 }
	 
	 // Ordo
	 app.request.post(app.params.server+'/societas2/sync.php', { action:'downSyncJSONOrdo',credentials:JSON.stringify(credentials) })
			.then(function (res) {
				// TODO faire un append pour ne pas effacer ce qui a déjà été téléchargé
				localforage.setItem('syncedJSONOrdo', JSON.parse(res.data)).then(function (ordo) {
					//console.log(ordo);
				}).catch(function(err) {
						console.log(err);
				});
				
		}); 
	 				
	// Liturgie	
	app.request.post(app.params.server+'/', { action:'downSyncLiturgie',credentials:JSON.stringify(credentials) }) // dans liturgia.php
		.then(function (res) {
			// TODO faire un append pour ne pas effacer ce qui a déjà été téléchargé
			localforage.setItem('syncedLiturgie', JSON.parse(res.data)).then(function (liturgie) {
				console.log("//1231 app.js newSyncedliturgie : ",liturgie);
			}).catch(function(err) {
					console.log(err);
			});
			
		});
			
		// Meditations
		localforage.getItem('syncedProfil', function(err, profil) {
   
		credentials.num = profil.meditationsValidees.length -1;
		console.log("//986 app.js downSyncJSONMeditations credentials",credentials);
		
		app.request.post(app.params.server+'/societas2/sync.php', { action:'downSyncJSONMeditations',credentials:JSON.stringify(credentials) }) // dans sync.php
			.then(function (res) {
				//console.log("//1244 app.js downSyncJSONMeditations res ",res);
				// TODO faire un append pour ne pas effacer ce qui a déjà été téléchargé
				localforage.setItem('syncedJSONMeditations', JSON.parse(res.data)).then(function (meditations) {
					console.log("//991 app.js newSyncedJSONMeditations : ",meditations);
				}).catch(function(err) {
						console.log(err);
				});
			});	
		}); //localforage.getItem('syncedProfil',
		
			// Connected users
			app.request.post(app.params.server+'/societas2/sync.php', { action:'downSyncConnectedUsers',credentials:JSON.stringify(credentials) }) // dans sync.php
			.then(function (res) {
				//console.log("//1255 app.js downSyncConnectedUsers res ",res);
				var reponse = JSON.parse(res.data);
				if(reponse.ACK == "OK") {
					localforage.setItem('syncedConnectedUsers', reponse.users).then(function (syncedConnectedUsers) {
						console.log("//1260 app.js syncedConnectedUsers : ",syncedConnectedUsers);
						}).catch(function(err) {
								console.log(err);
						});
					}		
				});
				
			// Escouades
			console.log("//1273 app.js downSyncEscouades");
			app.request.post(app.params.server+'/societas2/sync.php', { action:'downSyncEscouades',credentials:JSON.stringify(credentials) }) // dans sync.php
			.then(function (res) {
				
				var reponse = JSON.parse(res.data);
				if(reponse.ACK == "OK") {
					localforage.setItem('syncedEscouades', reponse.escouades).then(function (syncedEscouades) {
						//app.store.dispatch('updateMonEscouade',monEscouade);
						console.log("//1281 app.js syncedEscouades : ",syncedEscouades);
						}).catch(function(err) {
								console.log(err);
						});
					}	
				});
			
			
		//app.preloader.hide();
	}
  console.log("//1255 app.js reSync terminé");
}

function connexion() {
	console.log("//1259 app.js connexion()");

	var profil = app.store.getters.profil;
	if(profil.email == null) return;
	console.log("//1263 app.js connexion() profil.value : ",profil.value);
	var credentials = {
		hashEmail : Md5.hashStr(profil.value.email),
		hashPassword: Md5.hashStr(profil.value.password),
		profil: profil.value,
		mute:false,
		GDH: js_date('Y-m-d H:i')
		
		};
	console.log("//1272 app.js connexion() credentials",credentials);
	/////////////////
	if(app.params.isOnLine == true) {// l'application est en ligne
		//app.preloader.show();
		app.request.post(app.params.server+'/societas2/sync.php', { 
			action:'connexion',
			credentials : JSON.stringify(credentials)
			})
			.then(function (res) {
				//console.log("//994 connexion : ",res.data);
				//app.preloader.hide();
				var cetteReponse = JSON.parse(res.data);
				console.log("//1283 app.js connexion reponse : ",cetteReponse);
				// if ACK == NOK 
				if(cetteReponse.ACK == "NOK") {
					profil.value.connected = false;
					var notificationFull = app.notification.create({
						icon: '<i class="f7-icons">person</i>',
						title: 'Connexion',
						titleRightText: 'Maintenant',
						subtitle: 'Vous n\'êtes pas connecté.',
						text: cetteReponse.message,
						closeTimeout: 3000,
					});
					notificationFull.open();
					return;
				}
				
				if(cetteReponse.ACK == "OK") {
				// if ACK == OK 
				//tester si la connexion est réussie
				//	mettre à jour le store.profil avec 
				//		connected = 1; 
				//		emailChecked = 1; 
				//		statut.push(js_date("Y-m-d H:i"));
				
				//on stocke l'email, le mot de passe, le hashEmail et le hashPassword dans $store.profil;
				profil.value.connected = true;
				profil.value.emailChecked = 1;
				profil.value.email = formData.email;
				profil.value.password = formData.password;
				profil.value.hashEmail = Md5.hashStr(formData.email);
				profil.value.hashPassword = Md5.hashStr(formData.password);
				
				profil.value.lastConnexion = js_date("Y-m-d H:i");
				if(! profil.value.statut) {
					 profil.value.statut= [];
					 profil.value.statut[0] = js_date("Y-m-d H:i");
					 profil.value.statut[1] = js_date("Y-m-d H:i");
				 }
				//profil.value.updated = js_date("Y-m-d H:i");
				
				// Puis on update le profil via un event.
				//app.emit('profilUpdated', true); //mute = true
				syncProfil();
				/*
				var notificationFull = app.notification.create({
					icon: '<i class="f7-icons">person</i>',
					title: 'Connexion',
					titleRightText: 'Maintenant',
					subtitle: 'Reconnexion réussie !',
					text: cetteReponse.message,
					closeTimeout: 3000,
				});
				notificationFull.open();
				*/ 
				// endif
				
				
				}		
		}); // request.post
	} // fin Online
	if(app.params.isOnLine == false) {// l'application est hors ligne
		var notificationFull = app.notification.create({
			icon: '<i class="f7-icons">person</i>',
			title: 'inscription',
			titleRightText: 'Maintenant',
			subtitle: 'Application hors ligne !',
			text: 'Votre terminal n\'est actuellement pas connecté à internet. Veuillez rééssayer de vous reconnecter plus tard.',
			closeTimeout: 3000,
		});
		notificationFull.open();
	} // fin offLine
	////////////////////////
	console.log("//445 app.js connexion-form formData",JSON.stringify(formData));
	return
}

// on escouade Updated;
app.on('escouadeUpdated', function () {
	
	//mettre une roue d'attente ?
	app.preloader.show();
	//TODO faire un test sur app.online.
	// Escouades
	app.request.post(app.params.server+'/societas2/sync.php', { action:'downSyncEscouades'}) // dans sync.php
	.then(function (res) {
		
		var reponse = JSON.parse(res.data);
		//console.log("//1272 app.js downSyncEscouades reponse.escouades ",reponse.escouades);
		if(reponse.ACK == "OK") {
			localforage.setItem('syncedEscouades', reponse.escouades).then(function (syncedEscouades) {
				console.log("//1401 app.js on escouadeUpdated syncedEscouades : ",syncedEscouades);
				app.preloader.hide();
				}).catch(function(err) {
						console.log(err);
				});
			}	
		});
		

});

app.on('utilisateurUpdated', function () {
	
	// Connected users
	app.request.post(app.params.server+'/societas2/sync.php', { action:'downSyncConnectedUsers' }) // dans sync.php // sans credentials
	.then(function (res) {
		//console.log("//1255 app.js downSyncConnectedUsers res ",res);
		var reponse = JSON.parse(res.data);
		if(reponse.ACK == "OK") {
			localforage.setItem('syncedConnectedUsers', reponse.users).then(function (syncedConnectedUsers) {
				console.log("//1260 app.js syncedConnectedUsers : ",syncedConnectedUsers);
				}).catch(function(err) {
						console.log(err);
				});
			}		
		});
});

app.on('displayPresentation',function (){
	console.log("//1147 app.js displayPresentation");
	var panel = app.panel.get('.left-calendrier');
	panel.close(true); //left-calendrier
	localStorage.presentationVue = true;
	var presentationPopup = app.popup.create({
		el: $('#presentation-popup'),
		animate : true
	});
		
		presentationPopup.open(true);
		var swiperPresentation = app.swiper.create('.presentation-swiper', {
				speed: 400,
				spaceBetween: 50,
				//autoHeight: true,
				 autoplay: {
				   delay: 7000,
				 },
				pagination: {
				el: '.swiper-pagination',
				type: 'bullets',
				clickable:true
			}
			});
		$('.popup-presentation-close').on('click',function(){
			console.log("//1169 .popup-presentation-close");
			//swiperPresentation.destroy();
			app.swiper.destroy('.presentation-swiper');
			presentationPopup.close(true);
			presentationPopup.destroy();
			app.tab.show('#view-profil', true);
		});
		
});

app.on('displayLeSaviezVous',function(){
	console.log("//1181 app.js displayLeSaviezVous");
	sessionStorage.leSaviezVous = true;
	var r = Math.floor(Math.random() * LSV.length);
	var iconeLSV;
	if(LSV[r].categorie == "liturgie") iconeLSV = '<i class="fas fa-bible"></i>';
	if(LSV[r].categorie == "ascese") iconeLSV = '<i class="fas fa-hiking"></i>';
	
	var contentLeSaviezVous =''
		+'<div class="block title LSV-header"><div class="iconeLSV">'+iconeLSV+'</div> <div class="titreLSV">'+LSV[r].titre+'</div></div>'
		+'<div class="contentJustify">'+LSV[r].content+'</div>';
	
	var notificationWithButton = app.notification.create({
        icon: iconeLSV,
        title: 'Le saviez-vous ?',
        titleRightText: 'information',
        subtitle: LSV[r].titre,
        text: LSV[r].content,
        closeButton: true,
        cssClass: 'leSaviezVous'
      });
	notificationClickToClose.open();
	
});

var LSV = [
  {
    categorie:'liturgie',
    titre:' Traductions',
    content : 'Participez à l\'élaboration des traductions liturgiques '
      +'sur le site web <a	class="link external" href="https://www.societaslaudis.org">www.societaslaudis.org</a>. '
      +'<br />Proposer une traduction des textes qui soit à la fois fidèle '
      +' et facilitant la compréhension du texte latin est un de nos objectifs. <br /> La beauté du patrimoine '
      +' de la liturgie romaine est en effet trop peu mise en valeur de nos jours.'
    },
  {
    categorie:'liturgie',
    titre : 'Latin',
    content : 'Les textes liturgiques, même s\'ils sont en latin, ne relèvent pas'
    +' de la forme extrardinaire du rite romain, mais bien de la forme ordinaire, celle de Vatican II. Nous ne sommes pas traditionnalistes, '
    +'mais nous avons attention particulière à souligner'
    +' la valeur universelle de ce patrimoine de la liturgie latine que consitue le rite romain.'
    
    },
  {
    categorie:'ascese',
    titre: 'Ferveur',
    content : 'Pour tous les sociétaires qui s\'engagent à partir en pélerinage virtuel vers Jérusalem, il s\'agit de rendre régulière '
    +'la vie spirituelle et ascétique. En consultant votre progression vous constaterez qu\'il y a un indicateur de "ferveur", qui est '
    +' là pour mesurer votre régularité sur le long terme. Votre but est de faire en sorte qu\'il ne tombe pas à zéro.'
    },
  {
    categorie:'ascese',
    titre : 'Communion fraternelle',
    content : 'Au sein de votre escouade en route vers Jérusalem, vous pouvez en cas de besoin céder des points de ferveur '
    +'à l\'un de vos frères en difficulté. Ce procédé cherche à rendre concrète la communion des saints et la fraternité au sein de l\'escouade.'
    },
  {
    categorie:'ascese',
    titre : 'Origines de l\'application',
    content : 'Cette application a été développée au sein de la cathédrale S. Joseph de Nouakchott '
    +'(République islamique de Mauritanie) à l\'occasion de l\'instauration de l\'année S. Joseph (2020-2021)'
    +', afin de fortifier l\'implication des hommes.'
    +' au sein de la paroisse.'
    },
  {
    categorie:'ascese',
    titre : 'Partir seul vers Jérusalem',
    content : 'Si vous ne trouvez pas d\'escouade proche de chez vous pour faire le pélerinage vers Jérusalem,'
    +' vous pouvez essayer de partir seul, mais ce sera plus difficile'
    },
  {
    categorie:'liturgie',
    titre : 'Textes hors ligne',
    content : 'Les textes mis à dipsosition sont synchronisés avec plusieurs jours d\'avance,'
    +' si bien que vous y avez accès même lorsque vous êtes hors réseau. Tout est paramétrable dans <b>l\'onglet Profil</b>.'
    },
  {
    categorie:'ascese',
    titre : 'Exercices physiques',
    content : 'Le pélerinage, même si il est virtuel, doit vous engager physiquement de façon concrète. '
    +'Si vous ne pouvez vraiment pas faire d\'exercices physiques, choisissez-donc autre mortification physique - modérée - en accord avec votre directeur spirituel.'
    },
  {
    categorie:'ascese',
    titre : 'Liturgie',
    content : 'Les textes utilisés pour la liturgie au sein du pélerinage sont en latin avec une traduction française.'
    +'Vous n\êtes évidemment pas obligé d\'utilsier ces derniers si vous célébrez ordinairement l\'office autrement, '
    +'ne perdez pas cette bonne habitude, mais validez bien chacun des offices concernés.'
    },
  {
    categorie:'ascese',
    titre : 'Méditations',
    content : 'Vous avez à votre disposition l\'ensemble des méditations même si vous ne vous engagez pas à partir '
    +'en pélerinage vers Jérusalem. '
    },
  {
    categorie:'ascese',
    titre : 'Engagement pour le pélerinage vers Jérusalem',
    content : 'C\'est après votre inscription et votre connexion, et après avoir renseigné votre profil et votre lieu de résidence sur l\'application que vous pouvez vous engager'
    +'à partir en pélerinage vers Jérusalem. Vous aurez le choix entre rejoindre une escouade, fonder une escouade ou partir seul. '
    },
  {
    categorie:'liturgie',
    titre : 'Partage des textes',
    content : 'Vous pouvez partager à tous un extrait des textes liturgiques, des prières usuelles ou des méditations.'
    +' Si une phrase, une antienne, un répons vous a marqué, vous aiderez les autres à fortifier leur vie de prière, et derez connaître '
    +' à chacun l\'extrait qui vous a marqué. Pour cela il suffit de faire un double clic sur la phrase concernée.'
    },
  {
    categorie:'liturgie',
    titre : 'Mises à jour',
    content : 'Dans a version de l\'application en test ouvert, de nombreuses mises à jour sont réalisées, parfois chaque jour.'
    +' Les utilisateurs de test font des retours sur les fonctionnalités à l\'équipe technique. '
    +'Nous vous remercions de bien penser à faire les les mises à jour dès qu\'elles sont disponibles, pour faciliter l\'amélioration '
    +' de l\'application de façon efficace.'
    }
]

app.on('displayInviterPopup',function(){
	console.log("//1354 app.js inviter-popup-link clicked");
	var inviterPopup = app.popup.create({
		el: $('#inviter-popup'),
		animate : true
	});
	$("#QRCodeInviter").html('<img src="static/QR_CODE_google_play.png" />');	
	inviterPopup.open(true);
});



