/** @jsx $jsx */
import { $jsx } from 'framework7';
import js_date from '../js/utils.js/date.js';
import js_mktime from '../js/utils.js/mktime.js';
import Chart from 'chart.js';

function framework7Component(props, _ref) {
  var $ = _ref.$,
      $on = _ref.$on,
      $f7 = _ref.$f7,
      $f7route = _ref.$f7route,
      $store = _ref.$store;
  var profil = $store.getters.profil; //let id = $f7route.params.id;

  var current = {};
  current.debutDeSemaineTS = Date.now() / 1000 - js_date("w") * 60 * 60 * 24;
  current.debutDeMoisTS = js_mktime(12, 0, 0, js_date("m"), 1, js_date("Y"));
  $on('pageBeforeIn', function () {
    console.log("//83 progression.f7 on pageBeforeIn");
    $f7.emit('calculStatistiques');
    displayStatistiquesSemaine();
    displayStatistiquesMois();
    $('#statsPrevSemaine').on('click', updateDatePrevSemaine);
    $('#statsNextSemaine').on('click', updateDateNextSemaine);
    $('#statsPrevMois').on('click', updateDatePrevMois);
    $('#statsNextMois').on('click', updateDateNextMois);
    $('.inviter-popup-link').on('click', function () {
      $f7.emit('displayInviterPopup');
    });
  });

  function updateDatePrevSemaine() {
    current.debutDeSemaineTS = current.debutDeSemaineTS - 60 * 60 * 24 * 7;
    displayStatistiquesSemaine();
  }

  function updateDateNextSemaine() {
    current.debutDeSemaineTS = current.debutDeSemaineTS + 60 * 60 * 24 * 7;
    displayStatistiquesSemaine();
  }

  function updateDatePrevMois() {
    current.debutDeMoisTS = js_mktime(12, 0, 0, Number(js_date("m", current.debutDeMoisTS)) - 1, 1, js_date("Y", current.debutDeMoisTS));
    displayStatistiquesMois();
  }

  function updateDateNextMois() {
    current.debutDeMoisTS = js_mktime(12, 0, 0, Number(js_date("m", current.debutDeMoisTS)) + 1, 1, js_date("Y", current.debutDeMoisTS));
    displayStatistiquesMois();
  }

  function initStatistiques() {
    /*
     * 
     * initStatistiques() avec les boutons semaine mois et navigation
     * valeur par défaut : semaine en cours du dimanche au samedi
     * 		getStatistiques(dateYmdDebut,dateYmdFin); Qui génère le barCharData en prenant 
     * 										le profil.value.stats dans LF (lui même généré 
     * 										par calculStatistiques dans app.js)
     * 				displayStats(barChartData); Qui active le ctx etc...
     * 
    */
    console.log("//93 progression.f7 initStatistiques()");
    var btnsViewStats = document.getElementsByClassName("btnViewStats");
    var periode;
    var dateTS = Date.now() / 1000; //période de calcul et changement des dates avec les boutons

    var dateYmd = js_date("Ymd");
    var dayNumberOfTheWeek = js_date("w", dateTS);
    console.log("//1334 Aujourd'hui : " + js_date("Ymd", dateTS));
    var dimancheSemaineEnCoursTS = dateTS - dayNumberOfTheWeek * 60 * 60 * 24; // on ajoute une classe avec la date pour les semaines / passée future
    // note ; on ne le fait pas pour le mois puisque c'est "semaine" (actuelle) qui est selectionnée par défaut 

    var dimancheSemainePasseeTS = dimancheSemaineEnCoursTS - 60 * 60 * 24 * 7;
    var dimancheSemaineFutureTS = dimancheSemaineEnCoursTS + 60 * 60 * 24 * 7;
    document.getElementById("statsPasse").value = js_date("Ymd", dimancheSemainePasseeTS);
    document.getElementById("statsFutur").value = js_date("Ymd", dimancheSemaineFutureTS);
    document.getElementById("statsSemaine").value = js_date("Ymd", dimancheSemaineEnCoursTS);
    var numMois = js_date("n", dateTS);
    var premierJourMoisEnCoursTS = js_mktime(12, 0, 0, numMois, 1, js_date("Y", dateTS));
    document.getElementById("statsMois").value = js_date("Ymd", premierJourMoisEnCoursTS); // Activation boutons pour les périodes
    // semaine

    document.getElementById("statsSemaine").addEventListener("click", function () {
      console.log("2133 changement : période = semaine"); // On retire le button-active pour statsMois

      document.getElementById("statsMois").classList.remove("button-active");
      this.classList.add("button-active");
      var thisDateYmd = document.getElementById("statsSemaine").value;
      var thisDateTS = dateYmd2dateTS(thisDateYmd);
      var dimancheSemainePasseeTS = thisDateTS - 60 * 60 * 24 * 7;
      var dimancheSemaineFutureTS = thisDateTS + 60 * 60 * 24 * 7; // On met à jour les datePassee et dateFuture

      document.getElementById("statsPasse").value = js_date("Ymd", dimancheSemainePasseeTS);
      document.getElementById("statsFutur").value = js_date("Ymd", dimancheSemaineFutureTS); // On met à jour le barGraph

      profilGetStatistiques(dateYmdDebut, dateYmdFin);
    }); // mois

    document.getElementById("statsMois").addEventListener("click", function () {
      console.log("1411 changement : période = mois"); // on retire le button-active pour les statsSemaine

      document.getElementById("statsSemaine").classList.remove("button-active");
      this.classList.add("button-active");
      var thisDateYmd = document.getElementById("statsMois").value;
      var numMois = js_date("n", thisDateTS);
      var dernierJourMoisEnCoursTS = js_mktime(12, 0, 0, numMois, js_date("t", dateTSDebut), js_date("Y", dateTSDebut));
      var dernierJourMoisEnCoursYmd = js_date("Ymd", dernierJourMoisEnCoursTS);
      var thisDateTS = dateYmd2dateTS(thisDateYmd);
      var numMois = Number(js_date("n", thisDateTS)); // mktime(hour, minute, second, month, day, year, is_dst)

      var premierJourMoisPasseTS = js_mktime(12, 0, 0, numMois - 1, 1, js_date("Y", thisDateTS));
      var premierJourMoisFuturTS = js_mktime(12, 0, 0, numMois + 1, 1, js_date("Y", thisDateTS));
      document.getElementById("statsFutur").value = js_date("Ymd", premierJourMoisFuturTS);
      document.getElementById("statsPasse").value = js_date("Ymd", premierJourMoisPasseTS); // On met à jour le barGraph

      profilGetStatistiques(thisDateYmd, dernierJourMoisEnCoursYmd);
    }); // Activation boutons navigation pour les dates
    //STATS PASSE

    document.getElementById("statsPasse").addEventListener("click", function () {
      //On met à jour la date sur le bouton semaine (ou mois)
      var newDateYmd;
      var newDatePasseeTS;
      var newDatePasseeYmd;
      var newDateFutureTS;
      var newDateFutureYmd;
      newDateYmd = document.getElementById("statsPasse").value;
      var newDateTS = dateYmd2dateTS(newDateYmd);
      var newDateYmdFin;

      if (document.getElementById("statsSemaine").classList[3] == "button-active" || document.getElementById("statsSemaine").classList[3] == "active-state") {
        newDateYmdFin = js_date("Ymd", newDateTS + 7 * 24 * 60 * 60);
        document.getElementById("statsSemaine").value = newDateYmd;
        newDatePasseeTS = newDateTS - 60 * 60 * 24 * 7;
        newDatePasseeYmd = js_date("Ymd", newDatePasseeTS);
        newDateFutureTS = newDateTS + 60 * 60 * 24 * 7;
        newDateFutureYmd = js_date("Ymd", newDateFutureTS);
      }

      if (document.getElementById("statsMois").classList[3] == "button-active" || document.getElementById("statsMois").classList[3] == "active-state") {
        var dernierJourMoisEnCoursTS = js_mktime(12, 0, 0, numMois, js_date("t", newDateTS), js_date("Y", newDateTS));
        newDateYmdFin = js_date("Ymd", dernierJourMoisEnCoursTS + 7 * 24 * 60 * 60); // TODO Mettre le dernier jour du mois

        document.getElementById("statsMois").value = newDateYmd;
        var numMois = Number(js_date("n", newDateTS));
        var premierJourMoisPasseTS = js_mktime(12, 0, 0, numMois - 1, 1, js_date("Y", newDateTS));
        newDatePasseeYmd = js_date("Ymd", premierJourMoisPasseTS);
        var premierJourMoisFuturTS = js_mktime(12, 0, 0, numMois + 1, 1, js_date("Y", newDateTS));
        newDateFutureYmd = js_date("Ymd", premierJourMoisFuturTS);
        newDateYmdFin = js_date("Ymt", newDateTS);
      } // et sur les boutons datePassee 


      document.getElementById("statsPasse").value = newDatePasseeYmd; // et dateFuture

      document.getElementById("statsFutur").value = newDateFutureYmd; // On met à jour le barGraph

      profilGetStatistiques(newDateYmd, newDateYmdFin);
    }); //STATS FUTUR

    document.getElementById("statsFutur").addEventListener("click", function () {
      //On met à jour la date sur le bouton semaine (ou mois)
      var newDateYmd;
      var newDatePasseeTS;
      var newDatePasseeYmd;
      var newDateFutureTS;
      var newDateFutureYmd;
      var newDateYmdFin;
      newDateYmd = document.getElementById("statsFutur").value;
      var newDateTS = dateYmd2dateTS(newDateYmd);
      document.getElementById("statsSemaine").value = newDateYmd;

      if (document.getElementById("statsSemaine").classList.item(3) == "button-active" || document.getElementById("statsSemaine").classList.item(3) == "active-state") {
        newDatePasseeTS = newDateTS - 60 * 60 * 24 * 7;
        newDatePasseeYmd = js_date("Ymd", newDatePasseeTS);
        newDateFutureTS = newDateTS + 60 * 60 * 24 * 7;
        newDateFutureYmd = js_date("Ymd", newDateFutureTS);
        newDateYmdFin = js_date("Ymd", newDateTS + 60 * 60 * 24 * 7);
      }

      if (document.getElementById("statsMois").classList.item(3) == "button-active" || document.getElementById("statsMois").classList.item(3) == "active-state") {
        document.getElementById("statsMois").value = newDateYmd;
        var numMois = Number(js_date("n", newDateTS));
        var premierJourMoisPasseTS = js_mktime(12, 0, 0, numMois - 1, 1, js_date("Y", newDateTS));
        newDatePasseeYmd = js_date("Ymd", premierJourMoisPasseTS);
        var premierJourMoisFuturTS = js_mktime(12, 0, 0, numMois + 1, 1, js_date("Y", newDateTS));
        newDateFutureYmd = js_date("Ymd", premierJourMoisFuturTS); //TODO trouver le dernier jour du mois ?

        newDateYmdFin = js_date("Ymt", newDateTS); //newDateYmdFin = js_date("Ymd",newDateTS+60*60*24*7);
      } // et sur les boutons datePassee 


      document.getElementById("statsPasse").value = newDatePasseeYmd; // et dateFuture

      document.getElementById("statsFutur").value = newDateFutureYmd; // On met à jour le barGraph

      profilGetStatistiques(newDateYmd, newDateYmdFin);
    });
    var btnStatsSemaine = document.getElementById("statsSemaine");
    var btnStatsMois = document.getElementById("statsMois");
    var dateYmdDebut, dateYmdFin, dateTSDebut, dateTSFin;

    if (btnStatsSemaine.classList[3] == "active-state" || btnStatsSemaine.classList[3] == "button-active") {
      periode = "semaine"; // On calcule le début et la fin de la semaine

      dateYmdDebut = btnStatsSemaine.value;
      dateTSDebut = dateYmd2dateTS(dateYmdDebut);
      var dimancheSemaineEnCoursTS = dateYmd2dateTS(dateYmdDebut);
      var samediSemaineEnCoursTS = dimancheSemaineEnCoursTS + 60 * 60 * 24 * 6;
      dateYmdFin = js_date("Ymd", samediSemaineEnCoursTS);
    }

    if (btnStatsMois.classList[3] == "active-state" || btnStatsMois.classList[3] == "button-active") {
      periode = "mois"; // On calcule le début et la fin du mois

      dateYmdDebut = document.getElementById("statsMois").value;
      dateTSDebut = dateYmd2dateTS(dateYmdDebut); // premier jour du mois
      // dernier jour du mois

      var numMois = js_date("n", thisDateTS);
      var dernierJourMoisEnCoursTS = js_mktime(12, 0, 0, numMois, js_date("t", dateTSDebut), js_date("Y", dateTSDebut));
      dateYmdFin = js_date("Ymd", dernierJourMoisEnCoursTS);
    }

    console.log("//1487 dateYmdDebut: " + dateYmdDebut + " dateYmdFin:" + dateYmdFin);
    getStatistiques(dateYmdDebut, dateYmdFin); // semaine en cours
  }

  function displayStatistiquesSemaine() {
    var statsLiturgie = [];
    var statsPiete = [];
    var statsAscese = [];
    var statsCaritas = [];
    var statsFerveur = [];
    var statsLabels = [];
    var dateTSDebut = current.debutDeSemaineTS;
    var dateYmdDebut = js_date('Ymd', dateTSDebut);
    var dateTSFin = current.debutDeSemaineTS + 7 * 60 * 60 * 24;
    var dateYmdFin = js_date('Ymd', dateTSFin);
    var stats = profil.value.stats;
    var statsDisponibles = stats.filter(function (d) {
      return d.dateYmd >= dateYmdDebut && d.dateYmd <= dateYmdFin;
    });
    if (!statsDisponibles) statsDisponibles = [];
    var statsToDisplaySemaine = [];

    for (var thisDateTS = dateTSDebut; thisDateTS < dateTSFin + 60 * 60 * 24; thisDateTS += 60 * 60 * 24) {
      var todayYmd = js_date("Ymd", thisDateTS);
      var statsToday = statsDisponibles.filter(function (d) {
        return d.dateYmd == js_date("Ymd", thisDateTS);
      });
      if (!statsToday[0]) statsToday[0] = {
        dateYmd: js_date("Ymd", thisDateTS),
        liturgie: 0,
        piete: 0,
        ascese: 0,
        caritas: 0,
        ferveur: 0
      };
      statsToday[0].dateTS = thisDateTS;
      statsToDisplaySemaine.push(statsToday[0]);
    }

    console.log("//307 progression.f7 statsToDisplaySemaine", statsToDisplaySemaine);
    var traductionJours = [];
    traductionJours["Sun"] = "Dominica";
    traductionJours["Mon"] = "F.II";
    traductionJours["Tue"] = "F.III";
    traductionJours["Wed"] = "F.IV";
    traductionJours["Thu"] = "F.V";
    traductionJours["Fri"] = "F.VI";
    traductionJours["Sat"] = "Sabbato";
    statsToDisplaySemaine.forEach(function (item, index) {
      statsLiturgie.push(item.liturgie);
      statsPiete.push(item.piete);
      statsAscese.push(item.ascese);
      statsCaritas.push(item.caritas);
      statsFerveur.push(item.ferveur);
      var dateLatin = traductionJours[js_date("D", item.dateTS)] + js_date(" j", item.dateTS);
      statsLabels.push(dateLatin); //console.log("//2628 ",item.dateYmd);
    }); //On ajoute à statsToDisplay en clair la période affichée 

    statsToDisplaySemaine['periode'] = "Du " + js_date("j/n/Y", dateTSDebut) + " au " + js_date("j/n/Y", dateTSFin); //console.log(statsLiturgie);

    var barChartDataSemaine = {
      periode: statsToDisplaySemaine['periode'],
      labels: statsLabels,
      datasets: [{
        label: 'Liturgie',
        backgroundColor: "#A3E4D7",
        data: statsLiturgie,
        yAxisID: 'left-y-axis' //data: [15,24,19,17,19]

      }, {
        label: 'Piété',
        backgroundColor: "#AED6F1",
        data: statsPiete,
        yAxisID: 'left-y-axis' //data: [8,8,7.5,6,10]

      }, {
        label: 'Ascèse',
        backgroundColor: "#BFC9CA",
        data: statsAscese,
        yAxisID: 'left-y-axis' //data: [8,8,8,10,8]

      }, {
        label: 'Caritas',
        backgroundColor: "#F1948A",
        data: statsCaritas,
        yAxisID: 'left-y-axis' //data: [10,9,10,7,11]

      }, {
        label: 'Ferveur',
        backgroundColor: "#F8F9F9",
        data: statsFerveur,
        //data: [80,83,81,77,72,60,40],
        type: 'line',
        yAxisID: 'right-y-axis'
      }]
    }; // On affiche le module stats valable pour semaine
    //displayStats(statsToDisplaySemaine); 

    var ctxSemaine = document.getElementById('chart-semaine').getContext('2d');
    var chartSemaine = new Chart(ctxSemaine, {
      type: 'bar',
      data: barChartDataSemaine,
      options: {
        events: ['click'],
        title: {
          display: true,
          text: barChartDataSemaine.periode
        },
        tooltips: {
          mode: 'index',
          intersect: false
        },
        responsive: true,
        scales: {
          xAxes: [{
            stacked: true
          }],
          yAxes: [{
            stacked: true,
            id: 'left-y-axis',
            type: 'linear',
            position: 'left'
          }, {
            id: 'right-y-axis',
            type: 'linear',
            position: 'right'
          }]
        }
      }
    });
  }

  function displayStatistiquesMois() {
    var statsLiturgie = [];
    var statsPiete = [];
    var statsAscese = [];
    var statsCaritas = [];
    var statsFerveur = [];
    var statsLabels = [];
    var dateTSDebut = current.debutDeMoisTS;
    var dateYmdDebut = js_date('Ymd', dateTSDebut);
    var dateTSFin = js_mktime(12, 0, 0, js_date("m", dateTSDebut), js_date("t", dateTSDebut), js_date("Y", dateTSDebut));
    var dateYmdFin = js_date('Ymd', dateTSFin);
    var stats = profil.value.stats;
    var statsDisponibles = stats.filter(function (d) {
      return d.dateYmd >= dateYmdDebut && d.dateYmd <= dateYmdFin;
    });
    if (!statsDisponibles) statsDisponibles = [];
    var statsToDisplayMois = [];

    for (var thisDateTS = dateTSDebut; thisDateTS < dateTSFin + 60 * 60 * 24; thisDateTS += 60 * 60 * 24) {
      var todayYmd = js_date("Ymd", thisDateTS);
      var statsToday = statsDisponibles.filter(function (d) {
        return d.dateYmd == js_date("Ymd", thisDateTS);
      });
      if (!statsToday[0]) statsToday[0] = {
        dateYmd: js_date("Ymd", thisDateTS),
        liturgie: 0,
        piete: 0,
        ascese: 0,
        caritas: 0,
        ferveur: 0
      };
      statsToday[0].dateTS = thisDateTS;
      statsToDisplayMois.push(statsToday[0]);
    }

    console.log("//429 progression.f7 statsToDisplaySemaine", statsToDisplayMois);
    var traductionJours = [];
    traductionJours["Sun"] = "Dominica";
    traductionJours["Mon"] = "F.II";
    traductionJours["Tue"] = "F.III";
    traductionJours["Wed"] = "F.IV";
    traductionJours["Thu"] = "F.V";
    traductionJours["Fri"] = "F.VI";
    traductionJours["Sat"] = "Sabbato";
    statsToDisplayMois.forEach(function (item, index) {
      statsLiturgie.push(item.liturgie);
      statsPiete.push(item.piete);
      statsAscese.push(item.ascese);
      statsCaritas.push(item.caritas);
      statsFerveur.push(item.ferveur);
      var dateLatin = traductionJours[js_date("D", item.dateTS)] + js_date(" j", item.dateTS);
      statsLabels.push(dateLatin); //console.log("//2628 ",item.dateYmd);
    }); //On ajoute à statsToDisplay en clair la période affichée 

    statsToDisplayMois['periode'] = "Du " + js_date("j/n/Y", dateTSDebut) + " au " + js_date("j/n/Y", dateTSFin); //console.log(statsLiturgie);

    var barChartDataMois = {
      periode: statsToDisplayMois['periode'],
      labels: statsLabels,
      datasets: [{
        label: 'Liturgie',
        backgroundColor: "#A3E4D7",
        data: statsLiturgie,
        yAxisID: 'left-y-axis' //data: [15,24,19,17,19]

      }, {
        label: 'Piété',
        backgroundColor: "#AED6F1",
        data: statsPiete,
        yAxisID: 'left-y-axis' //data: [8,8,7.5,6,10]

      }, {
        label: 'Ascèse',
        backgroundColor: "#BFC9CA",
        data: statsAscese,
        yAxisID: 'left-y-axis' //data: [8,8,8,10,8]

      }, {
        label: 'Caritas',
        backgroundColor: "#F1948A",
        data: statsCaritas,
        yAxisID: 'left-y-axis' //data: [10,9,10,7,11]

      }, {
        label: 'Ferveur',
        backgroundColor: "#F8F9F9",
        data: statsFerveur,
        yAxisID: 'right-y-axis',
        //data: [80,83,81,77,72,60,40],
        type: 'line'
      }]
    }; // On affiche le module stats valable pour mois

    var ctxMois = document.getElementById('chart-mois').getContext('2d');
    var chartMois = new Chart(ctxMois, {
      type: 'bar',
      data: barChartDataMois,
      options: {
        events: ['click'],
        title: {
          display: true,
          text: barChartDataMois.periode
        },
        tooltips: {
          mode: 'index',
          intersect: false
        },
        responsive: true,
        scales: {
          xAxes: [{
            stacked: true
          }],
          yAxes: [{
            stacked: true,
            id: 'left-y-axis',
            type: 'linear',
            position: 'left'
          }, {
            id: 'right-y-axis',
            type: 'linear',
            position: 'right'
          }]
        }
      }
    });
  }

  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
  <div class="page" data-name="progression">
    <div class="navbar">
			<div class="navbar-bg"></div>
				<div class="navbar-inner">
					<div class="left">
						<a href="#" class="link back">
							<i class="icon icon-back"></i>
							<span class="if-not-md">Retour</span>
						</a>
					</div>
					
					<div class="title">Progression</div>
					<div class="right">
				<a href="#inv" class="tab-link icon-only inviter-popup-link">
				<i class="fas fa-user-plus"></i> 
			</a>
				<a href="/messages/" class="tab-link icon-only">
					<i class="icon material-icons">mail_outline<span class="badge color-red newMessages" id="newMessages">3</span></i> 
				</a>
					</div>
				<div class="subnavbar">
					<div class="subnavbar-inner">
						<div class="segmented segmented-strong">
						<a class="button tab-link tab-link-active" href="#tab-semaine">Semaine</a>
						<a class="button tab-link" href="#tab-mois">Mois</a>
						<span class="segmented-highlight"></span>
						</div>
					</div>
					</div>
				</div>
    </div>
		<!-- navbar -->
		<div class="page-content">
			<div class="tabs">
				<div class="tab tab-active" id="tab-semaine">
					<canvas id="chart-semaine" width="400" height="400"></canvas>
					<div class="block">
						<div class="row">
							<button id="statsPrevSemaine" class="col button button-fill"><i class="fas fa-angle-double-left"></i></button>
							<button id="statsNextSemaine"  class="col button button-fill"><i class="fas fa-angle-double-right"></i></button>
						</div>
							<!-- row -->
					</div>
					<!-- block -->
				</div>
				<!-- tab-semaine -->
				
				<div class="tab" id="tab-mois">
					<canvas id="chart-mois" width="400" height="400"></canvas>
					<div class="block">
						<div class="row">
							<button id="statsPrevMois" class="col button button-fill"><i class="fas fa-angle-double-left"></i></button>
							<button id="statsNextMois"  class="col button button-fill"><i class="fas fa-angle-double-right"></i></button>
						</div>
							<!-- row -->
					</div>
					<!-- block -->
				</div>
				<!-- tab-mois -->
				
			</div>
			<!-- tabs -->
	</div> <!-- page content -->
	<!-- page-content -->	
  </div>
`
    }
    ;
}

framework7Component.id = '9b7f52a1e6';
export default framework7Component;