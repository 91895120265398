/** @jsx $jsx */
import { $jsx } from 'framework7';
import js_date from '../js/utils.js/date.js';
import js_mktime from '../js/utils.js/mktime.js';

function parseDate(date) {
  var parsed = Date.parse(date);

  if (!isNaN(parsed)) {
    return parsed;
  }

  return Date.parse(date.replace(/-/g, '/').replace(/[a-z]+/gi, ' '));
}

function framework7Component(props, _ref) {
  var $ = _ref.$,
      $on = _ref.$on,
      $f7 = _ref.$f7,
      $f7route = _ref.$f7route,
      $store = _ref.$store;
  var profil = $store.getters.profil;
  $on('pageBeforeIn', function (e, page) {
    console.log("//223 engagement.f7 $on('pageBeforeIn',"); //$on('#engagementFonderEscouadeBtn').
    //$on('#engagementPartirSeulBtn').

    displayEngagement();
    $('.inviter-popup-link').on('click', function () {
      $f7.emit('displayInviterPopup');
    });
  });

  function displayEngagement() {
    $('.tabPartirSeulLink').on('click', function () {
      console.log("//249 #tabPartirSeulLink clicked");
      var notificationWithButton = $f7.notification.create({
        icon: '<i class="fas fa-hiking"></i>',
        title: 'Vous voulez partir seul vers Jérusalem ?',
        //titleRightText: 'now',
        subtitle: '<div class="contentJustify notif-engagement">' + 'Nous vous félicitons pour la motivation que vous démontrez en désirant partir' + ' vers Jérusalem en pélerinage virtuel. Cependant, pour être mieux appuyé et guidé, ' + ' nous vous conseillons d\'essayer de trouver d\'autres personnes pour vous accompagner. Il y a peut être' + ' une escouade proche de votre lieu de résidence ; sinon, impliquez un prêtre dans l\'aventure, un religieux expérimenté  ou' + ' même un séminariste qui pourra devenir l\'enseigne d\'une nouvelle escouade à laquelle vous pourez vous joindre.' + ' Il est très difficile d\'être un chrétien seul. Il est encore plus difficile' + ' d\'entreprendre un chemin de conversion sans accompagnement et fraternité.' + ' Si vraiment cela vous semble impossible de trouver des compagnons de route, personne ne vous empêche de partir seul, cependant' + ' mais cela risque d\'être beaucoup plus dur d\'arriver au bout.' + ' Si vous voulez de l\'aide, des conseils, un appui, n\'hésitez pas à prendre contact avec nous ' + ' au travers du site web : www.societaslaudis.org.' + '</div>',
        cssClass: 'leSaviezVous',
        closeButton: true
      });
      notificationWithButton.open();
    });
    $('.tabFonderEscouadeLink').on('click', function () {
      console.log("//249 #tabPartirSeulLink clicked");
      var notificationWithButton = $f7.notification.create({
        icon: '<i class="fas fa-hiking"></i>',
        title: 'Vous voulez fonder une escouade ?',
        //titleRightText: 'now',
        subtitle: '<div class="contentJustify notif-engagement">' + 'Vous avez des amis avec lesquels vous êtes prêt à tenter le pélerinage vers Jérusalem ?' + ' Nous vous félicitons pour l\'implication que vous démontrez en désirant fonder une nouvelle escouade.' + ' Cependant nous attirons votre attention sur le fait que pour mener à bien cette initiative' + ' en tant qu\'enseigne, il faut bien connaître le parcours, avoir une certaine expérience de la vie spirituelle,' + ' et si possible être déjà au moins une fois être arrivé à Jérusalem au travers de l\'application.' + ' Si vous voulez de l\'aide, des conseils, un appui, n\'hésitez pas à prendre contact avec nous' + ' au travers du site web : www.societaslaudis.org.' + '</div>',
        //text: 'Click me to close',
        cssClass: 'leSaviezVous',
        closeButton: true
      });
      notificationWithButton.open();
    });
    $('.tabRejoindreEscouadeLink').on('click', function () {
      console.log("//249 #tabPartirSeulLink clicked");
      var notificationWithButton = $f7.notification.create({
        icon: '<i class="fas fa-hiking"></i>',
        title: 'Vous voulez rejoindre une escouade ?',
        //titleRightText: 'now',
        subtitle: '<div class="contentJustify notif-engagement">' + 'Selectionnez une escouade proche de chez vous, car les rencontres physiques entre' + ' pélerins sont importantes. Demandez ensuite votre adhésion.' + '</div>',
        //text: 'Click me to close',
        cssClass: 'leSaviezVous',
        closeButton: true
      });
      notificationWithButton.open();
    });
    var n = (Date.now() + 3 * 60 * 60 * 24 * 1000) / 1000; //console.log("//247 engagement.f7 displayEngagement() $('#fonderEscouadeDateDepart').prop('value')",$('#fonderEscouadeDateDepart').prop('value'));

    $('#fonderEscouadeDateDepart').prop('value', js_date('Y-m-d', n));
    $('#partirSeulDateDepart').prop('value', js_date('Y-m-d', n));
    $('#partirSeulResidence').prop('value', profil.value.userResidence);
    localforage.getItem('syncedEscouades', function (err, syncedEscouades) {
      var selectEscouadesEnPartance = syncedEscouades.filter(function (d) {
        return parseDate(d.dateDepartISO) > Date.now();
      });
      console.log("//254 engagement.f7 selectEscouadesEnPartance : ", selectEscouadesEnPartance);
      var messageNoEscouade = '<div class="block block-strong" style="background:#ff9a0354;">Il n\'y a malheureusement pas en ce moment d\'escouade en partance.' + '<br/>Vous pouvez tout de même fonder une escouade avec vos amis volontaires comme vous ou bien' + ' partir seul.</div>';
      if (selectEscouadesEnPartance.length == 0) $('#listeEscouadesEnPartance').html(messageNoEscouade);else {
        var listeEscouadesEnPartance = '' + '<div class="list media-list">' + '<ul>';

        for (var i = 0; i < selectEscouadesEnPartance.length; i++) {
          // formatage à la française de l'affichade de la date de départ
          var d = new Date(selectEscouadesEnPartance[i].dateDepartISO);
          var dateOptions = {
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric'
          };
          var dateFormatFR = d.toLocaleString("fr-FR", dateOptions);
          listeEscouadesEnPartance += '' + '<li>' + '<label class="item-radio item-radio-icon-start item-content">' + '<input type="radio" name="rejoindreEscouade-radio" value="' + selectEscouadesEnPartance[i].nom + '" />' + '<i class="icon icon-radio" ></i>' + '<div class="item-inner">' + '<div class="item-title-row">' + '<div class="item-title">' + selectEscouadesEnPartance[i].nom + '</div>' + '<div class="item-after">' + dateFormatFR + '</div>' + '</div>' + '<div class="item-subtitle"><i>' + selectEscouadesEnPartance[i].lieu + '</i> - 1 inscrit(s)</div>' + '<div class="item-subtitle">Enseigne : ' + selectEscouadesEnPartance[i].enseigne + '</div>' //+'<div sytle="display:none" id="enseigneHashUserEmail">'+jListeEscouades[i].enseigne+'</div>'
          + '<div class="item-text">' + selectEscouadesEnPartance[i].description + '</div>' + '</div>' + '</label>' + '</li>';
        }

        listeEscouadesEnPartance += '' + '</ul>' + '</div>' + '</div>' + '<button id="engagementRejoindreEscouadeBtn" class="col button button-fill color-gray">Demandez votre adhésion</button>';
        $('#listeEscouadesEnPartance').html(listeEscouadesEnPartance);
        $('#engagementRejoindreEscouadeBtn').on('click', function () {
          engagementRejoindreEscouade();
        });
      }
    }); //LF get syncedEscouades		
  }

  function engagementFonderEscouade() {
    console.log("//357 engagement.f7 engagementFonderEscouadeBtn clicked");
    console.log("//358 engagement.f7 $('#fonderEscouadeDateDepart').prop('value')", $('#fonderEscouadeDateDepart').prop('value'));
    var credentials = {
      hashEmail: profil.value.hashEmail,
      hashPassword: profil.value.hashPassword,
      userPseudonyme: profil.value.userPseudonyme,
      GDH: js_date('Y-m-d H:i'),
      escouadeNom: $('#fonderEscouadeNom').prop('value'),
      escouadeDateDepart: $('#fonderEscouadeDateDepart').prop('value'),
      escouadeDateDepartISO: $('#fonderEscouadeDateDepart').prop('value'),
      escouadeLieu: $('#fonderEscouadeLieu').prop('value'),
      escouadeDescription: $('#fonderEscouadeDescription').prop('value')
    };
    $f7.dialog.confirm('Vous allez fonder l\'escouade <b>' + credentials.escouadeNom + '</b> et vous engager à partir avec elle vers Jérusalem le <b>' + credentials.escouadeDateDepartISO + '</b>. Cet engagement est déinitif. Etes vous sûr ?', 'Engagement définitif !', function () {
      if (credentials.escouadeNom == "" || credentials.escouadeDateDepart == "" || credentials.escouadeLieu == "" || credentials.escouadeDescription == "") {
        console.log("//373 engagement.f7 Veuillez remplir tous les champs.");
        var notificationFull = $f7.notification.create({
          icon: '<i class="f7-icons">person</i>',
          title: 'Votre escouade',
          titleRightText: 'Maintenant',
          subtitle: 'Echec !',
          text: 'Veuillez renseigner tout le formulaire pour fonder votre escouade. Merci.',
          closeTimeout: 3000
        });
        notificationFull.open();
        return;
      }

      console.log("//385 engagement.f7 engagementFonderEscouade() credentials : ", credentials);
      $f7.request.post($f7.params.server + '/societas2/sync.php', {
        action: 'fonderEscouade',
        credentials: JSON.stringify(credentials)
      }).then(function (res) {
        var reponse = JSON.parse(res.data);
        reponse = JSON.parse(res.data);
        console.log("//395 profil.f7 engagementFonderEscouade reponse :", reponse);

        if (reponse.ACK == "OK") {
          //user deconnecté
          //DownSyncEscouades
          $f7.emit('escouadeUpdated'); //Mise à jour statut utilisateur

          profil.value.statut[2] = js_date('Y-m-d H:i');
          profil.value.statut[3] = js_date('Y-m-d H:i');
          profil.value.dateDepartISO = credentials.escouadeDateDepartISO;
          profil.value.candidatures = [];
          profil.value.escouade = {};
          profil.value.escouade.nom = reponse.escouade.nom;
          profil.value.escouade.clef = reponse.clef;
          console.log("//406 engagement.f7 profil.value : ", profil.value);
          $store.dispatch('updateProfil', profil.value); // event profil updated

          $f7.emit('profilUpdated'); // downSyncSyncedUtilisateurs via un event

          $f7.emit('utilisateurUpdated'); //les notifications à mettre en relation avec fonderEscouade()

          var notificationFull = $f7.notification.create({
            icon: '<i class="f7-icons">person</i>',
            title: 'Votre escouade',
            titleRightText: 'Maintenant',
            subtitle: 'Vous venez de fonder l\'escouade ' + credentials.escouadeNom + ' !',
            text: 'Félicitations ! ' + 'Vous vous êtes engagé à partir vers Jérusalem le ' + credentials.escouadeDateDepart + ' ; votre statut a été mis à jour.' + ' Invitez maintenant vos amis à vous rejoindre',
            closeTimeout: 6000
          });
          notificationFull.open(); //TODO message Pseudonyme (résidence) vient de fonder une escouade sur LP

          displayEngagement;
        }

        if (reponse.ACK == "NOK") {
          $f7.preloader.hide(); //les notifications à mettre en relation avec fonderEscouade()

          var notificationFull = $f7.notification.create({
            icon: '<i class="f7-icons">person</i>',
            title: 'Echec',
            titleRightText: 'Maintenant',
            subtitle: 'Il y a eu un problème !',
            text: 'Votre esouade n\'est pas créée. Veuillez réessayer. ' + reponse.message,
            closeTimeout: 3000
          });
          notificationFull.open();
        }
      }); // request.post
    });
  }

  function engagementPartirSeul() {
    console.log("//378 engagement.f7  engagementPartirSeulBtn clicked"); // dialog avec "êtes vous sûr / engagement définitif"

    $f7.dialog.confirm('Vous allez partir seul vers Jérusalem. Etes vous sûr ?', 'Engagement définitif !', function () {
      // maj du profil
      profil.value.statut[2] = js_date('Y-m-d H:i');
      profil.value.statut[3] = js_date('Y-m-d H:i');
      profil.value.dateDepartISO = $('#partirSeulDateDepart').prop('value');
      profil.value.candidatures = [];
      profil.value.departSeul = true;
      console.log("//391 engagement.f7 partirSeul profil.value : ", profil.value); //$store.dispatch('updateProfil', profil.value);
      //TODO message Pseudonyme (résidence) s'est engagé à partir seul vers Jérusalem sur LP
      // event profil updated

      $f7.emit('profilUpdated');
      $f7.emit('utilisateurUpdated');
      var options = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      };
      var dateDepartSeulFr = new Date(profil.value.dateDepartISO).toLocaleDateString("fr-FR", options);
      var notificationFull = $f7.notification.create({
        icon: '<i class="f7-icons">person</i>',
        title: 'Engagement',
        titleRightText: 'Maintenant',
        subtitle: 'Votre profil a été mis à jour.',
        text: 'Vous vous êtes engagé à partir seul vers Jérusalem le ' + dateDepartSeulFr + '. Commmencez vos préparatifs en ' + 'relisant le directoire et en planifiant vos journées pour réussir cette aventure.',
        closeTimeout: 9000
      });
      notificationFull.open();
    });
  }

  function engagementRejoindreEscouade() {
    console.log("//406 engagementRejoindreEscouade() click");
    var xhr = new XMLHttpRequest(); //var profil = JSON.parse(localStorage.profil);

    var choixEscouades = document.getElementsByName("rejoindreEscouade-radio");
    var choixEscouade;
    var cetEnseigne;

    for (var i = 0; i < choixEscouades.length; i++) {
      if (choixEscouades[i].checked == true) {
        choixEscouade = choixEscouades[i].value;
        console.log("//410 engagement.f7 choixEscouades", choixEscouades[i].value);
      }
    }

    if (choixEscouade == undefined) {
      var notificationFull = $f7.notification.create({
        icon: '<i class="f7-icons">info_circle</i>',
        title: 'Engagement',
        titleRightText: 'Maintenant',
        subtitle: '',
        text: "Veuillez selectionner l'escouade à laquelle vous voulez adhérer.",
        closeTimeout: 5000
      });
      notificationFull.open();
      return;
    }

    var credentials = {
      hashEmail: profil.value.hashEmail,
      hashPassword: profil.value.hashPassword,
      GDH: js_date('Y-m-d H:i:s'),
      userPseudonyme: profil.value.userPseudonyme,
      choixEscouade: choixEscouade
    };
    console.log("//444 engagement.f7 choixEscouades credentials", credentials);
    console.log("//444 engagement.f7 choixEscouades societas2/sync.php?action=rejoindreEscouade");
    $f7.request.post($f7.params.server + '/societas2/sync.php', {
      action: 'rejoindreEscouade',
      credentials: JSON.stringify(credentials)
    }).then(function (res) {
      console.log("//448 app.js rejoindreEscouade res.data : ", res.data);
      var reponse = JSON.parse(res.data);

      if (reponse.ACK == "NOK") {
        var notificationFull = $f7.notification.create({
          icon: '<i class="f7-icons">info_circle</i>',
          title: 'Engagement',
          titleRightText: 'Maintenant',
          subtitle: 'Adhésion à une escouade',
          text: reponse.message,
          closeTimeout: 3000
        });
        notificationFull.open();
      }

      if (reponse.ACK == "OK") {
        console.log("//466 app.js rejoindreEscouade reponse.message : ", reponse.message);
        var notificationCallbackOnClose = $f7.notification.create({
          icon: '<i class="f7-icons">info_circle</i>',
          title: 'Rejoindre une escouade',
          titleRightText: 'maintenant',
          //subtitle: 'Vous partez maintenant depuis Vézelay vers Jérusalem.',
          text: reponse.message,
          cssClass: 'leSaviezVous',
          closeButton: true,
          on: {
            close: function close() {
              $f7.tab.show('#view-ascese', true);
            }
          }
        });
        notificationCallbackOnClose.open(); //TODO mise à jour du profil

        profil.value.candidatures.push(credentials.choixEscouade);
        console.log("//476 app.js rejoindreEscouade profil.value.candidatures", profil.value.candidatures);
        profil.value.statut[2] = js_date("Y-m-d H:i");
        $f7.emit('profilUpdated', true); //mute = true

        $f7.emit('utilisateurUpdated');
        console.log("//474 app.js rejoindreEscouade profil.value : ", profil.value);
      }
    }); // request rejoindreEscouade
  }

  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
  <div class="page" data-name="enagement">
    <div class="navbar">
			<div class="navbar-bg"></div>
				<div class="navbar-inner">
					<div class="left">
						<a href="#" class="link back">
							<i class="icon icon-back"></i>
							<span class="if-not-md">Retour</span>
						</a>
					</div>
					
					<div class="title">Engagement</div>
					<div class="right">
				<a href="#inv" class="tab-link icon-only inviter-popup-link">
				<i class="fas fa-user-plus"></i> 
			</a>
				<a href="/messages/" class="tab-link icon-only">
					<i class="icon material-icons">mail_outline<span class="badge color-red newMessages" id="newMessages">3</span></i> 
				</a>
					</div>
				
				</div>
    </div>
		<!-- navbar -->
		<div class="page-content">
			<div class="block"> 
			<span class="contentJustify">J'ai compris l'esprit du pélerinage virtuel à Jérusalem.
			Je souhaite m'engager sur ce chemin d'exercices spirituels et ascétiques.
			Pour cela, je peux :</span>
			
			<div class="tabs-animated-wrap">
				<div class="list">
					<ul>
					  <li>
						<a href="#tabRejoindreEscouade" id="linkRejoindreEscouade" class="item-link tab-link item-content tabRejoindreEscouadeLink">
						  <div class="item-media"><i class="fas fa-users"></i></div>
						  <div class="item-inner">
								<div class="item-title">Rejoindre une escouade</div>
								<div class="item-after">Liste</div>
						  </div>
						</a>
					  </li>
					  <!-- /li -->
					  <li>
						<a href="#tabFonderEscouade" class="item-link tab-link item-content tabFonderEscouadeLink">
						  <div class="item-media"><i class="fas fa-users-cog"></i> </div>
							<div class="item-inner">
								<div class="item-title">Fonder une escouade</div>
							</div>
						</a>
					  </li>
					  <!-- /li -->
					  <li>
						<a href="#tabPartirSeul" class="item-link tab-link item-content tabPartirSeulLink">
						  <div class="item-media"><i class="fas fa-user-shield"></i></div>
						  <div class="item-inner">
							<div class="item-title">Partir seul</div>
						  </div>
						</a>
					  </li>
						<!-- /li -->
					</ul>
					<!-- /ul -->	
				</div>
				<!-- list -->	
				
				<div class="tabs">
					
					 <!-- Tab 0, active by default -->
					 <div id="tabO" class="tab tab-active"> 
					 </div>
					 
					<!-- Tab 1, active by default -->
					<div id="tabRejoindreEscouade" class="tab">
						<p><hr /></p>
						<div id="listeEscouadesEnPartance"></div>
					</div>
					<!-- /tabRejoindreEscouade -->
					
					<!-- Tab 2 -->
					<div id="tabFonderEscouade" class="tab">	  	
						<p><hr /></p>
						<div class="list no-hairlines-md">
						<ul>
							<li class="item-content item-input">
								<div class="item-media">
									<i class="fas fa-file-signature"></i>
								</div>
								<div class="item-inner">
									<div class="item-title item-label">Nom de l'escouade :</div>
									<div class="item-input-wrap">
									<input id="fonderEscouadeNom"  type="text" placeholder="Choisissez un nom pour l'escouade" />
									<span class="input-clear-button"></span>
									</div>
								</div>
							</li>
							<!-- /li -->
							
							<li class="item-content item-input">
								<div class="item-media">
									<i class="fas fa-hiking"></i>
								</div>
								<!-- item-media -->
								<div class="item-inner">
									<div class="item-title item-label">Date prévue de départ vers Jérusalem :</div>
									<div class="item-input-wrap">
									<input id="fonderEscouadeDateDepart" type="date" value="2021-01-15" placeholder="Choisir ..." />
									</div>
								</div>
								<!-- item-inner -->
							</li>
							<!-- /li -->
							
							<li class="item-content item-input">
								<div class="item-media">
									<i class="fas fa-map-marked-alt"></i>
								</div>
								<!-- item-media -->
								<div class="item-inner">
									<div class="item-title item-label">Lieu :</div>
									<div class="item-input-wrap">
									<textarea id="fonderEscouadeLieu" class="resizable" placeholder="Pays, région, paroisse ..."></textarea>
									</div>
								</div>
								<!-- item-inner -->
							</li>
							<!-- /li -->
							
							<li class="item-content item-input">
								<div class="item-media">
									<i class="far fa-hand-point-right"></i>
								</div>
								<!-- item-media -->
								<div class="item-inner">
									<div class="item-title item-label">Description :</div>
									<div class="item-input-wrap">
										<textarea id="fonderEscouadeDescription" placeholder="Quelques mots sur l'escouade."></textarea>
									</div>
								</div>
								<!-- item-inner -->
							</li>
							<!-- /li -->
							
							<button id="engagementFonderEscouadeBtn" @click=${engagementFonderEscouade} class="col button button-fill color-gray">Fondez une escouade</button>
							
						</ul>
						<!-- /ul -->
					</div>
						<!-- list no-hairlines-md -->
				</div>
					<!-- /tabFonderEscouade -->
					
					<!-- Tab 3 -->
					<div id="tabPartirSeul" class="tab">
						<p><hr /></p>
						<div class="contentJustify">
						
					 </div>
						 
					<div class="list no-hairlines-md">
						<ul>
							<li class="item-content item-input">
								<div class="item-media">
									<i class="fas fa-hiking"></i>
								</div>
								<!-- item-media -->
								<div class="item-inner">
									<div class="item-title item-label">Date prévue de départ vers Jérusalem :</div>
									<div class="item-input-wrap">
										<input id="partirSeulDateDepart" type="date" value="2021-01-15" placeholder="Choisir ..." />
									</div>
								</div>
								<!-- item-inner -->
							</li>
							<!-- li -->	
							<li class="item-content item-input">
								<div class="item-media">
									<i class="fas fa-map-marked-alt"></i>
								</div>
								<div class="item-inner">
									<div class="item-title item-label">Résidence :</div>
									<div class="item-input-wrap">
										<textarea id="partirSeulResidence" class="resizable" placeholder="Pays, localité, paroisse ..."></textarea>
									</div>
								</div>
								<!-- item-inner -->
							</li>
							<!-- li -->
							<button id="engagementPartirSeulBtn" @click=${engagementPartirSeul} class="col button button-fill color-gray">Partez seul</button>
						</ul>
						<!-- ul -->
					</div>
					<!-- list no-hairlines-md -->
					
					</div>
					<!-- /tabPartirSeul -->
					
				
				</div>
				<!-- tabs -->
				
				
				
			</div>
			<!-- tabs-animated-wrap -->
			
			
			
			</div>
			<!-- block -->
		</div> <!-- page content -->
		<!-- page-content -->	
  </div>
`
    }
    ;
}

framework7Component.id = '51490f3266';
export default framework7Component;