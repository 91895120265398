/** @jsx $jsx */
import { $jsx } from 'framework7'; // Md5

import { Md5 } from '../js/ts-md5/dist/md5';
import js_date from '../js/utils.js/date.js';

function parseDate(date) {
  var parsed = Date.parse(date);

  if (!isNaN(parsed)) {
    return parsed;
  }

  return Date.parse(date.replace(/-/g, '/').replace(/[a-z]+/gi, ' '));
}

function framework7Component(props, _ref) {
  var $ = _ref.$,
      $on = _ref.$on,
      $f7 = _ref.$f7,
      $f7router = _ref.$f7router,
      $store = _ref.$store;
  var options = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  };
  var today = new Date().toLocaleDateString("fr-FR", options);
  var profil = $store.getters.profil; // affichage du bon tab en fonction de l'avancée de l'inscription
  //console.log("//966 profil.value.email :",profil.value.email);
  //if(profil.value.email) $f7.tab.show("#tab-connexion", true);
  //console.log("//968 profil.value.connected :",profil.value.connected);
  //if(profil.value.connected) $f7.tab.show("#tab-profil");
  //console.log("//972 profil.value.connected :",profil.value.connected);

  var current = {};
  current.password = profil.value.password;

  function genererPassword() {
    var formData = $f7.form.convertToData('#connexion-form');
    console.log("//1005 profil.f7 formData ", formData);
    $f7.dialog.confirm('Générer votre mot de passe pour ' + formData.email + ' et le recevoir ?', 'Nouveau mot de passe', function () {
      nouveauPassword(formData.email);
    });
  }

  function nouveauPassword(adresseEmail) {
    var credentials = {
      email: adresseEmail
    };
    console.log("1021 profil.f7 credentials", credentials);
    profil.value.email = adresseEmail;

    if ($f7.params.isOnLine == true) {
      // l'application est en ligne
      $f7.preloader.show();
      $f7.request.post($f7.params.server + '/societas2/sync.php', {
        action: 'validationEmail',
        credentials: JSON.stringify(credentials)
      }).then(function (res) {
        $f7.preloader.hide();
        console.log("//1031 validationEmail : ", res.data);
        var cetteReponse = JSON.parse(res.data);
        console.log("//1033 validationEmail res.data : ", cetteReponse);

        if (cetteReponse.ACK == "NOK") {
          var notificationFull = $f7.notification.create({
            icon: '<i class="f7-icons">person</i>',
            title: 'inscription',
            titleRightText: 'Maintenant',
            subtitle: 'Erreur !',
            text: 'Erreur serveur. Nous sommes désolés, veuillez réessayer plus tard.',
            closeTimeout: 3000
          });
          notificationFull.open();
          return;
        }

        var notificationFull = $f7.notification.create({
          icon: '<i class="f7-icons">person</i>',
          title: 'Inscription',
          titleRightText: 'Maintenant',
          subtitle: 'Utilisateur inscrit !',
          text: 'Un email vous a été envoyé sur l\'adresse ' + credentials.email + ', contenant un mot de passe. Utilisez-le pour vous connecter.',
          closeTimeout: 3000
        });
        notificationFull.open();
      }); // request.post
    } // fin Online


    if ($f7.params.isOnLine == false) {
      // l'application est hors ligne
      var notificationFull = $f7.notification.create({
        icon: '<i class="f7-icons">person</i>',
        title: 'Inscription',
        titleRightText: 'Maintenant',
        subtitle: 'Application hors ligne !',
        text: 'Votre terminal n\'est actuellement pas connecté à internet. Veuillez rééssayer plus tard.',
        closeTimeout: 3000
      });
      notificationFull.open();
    } // fin offLine

  }

  function userDeconnect() {
    //console.log("//1071 adresseEmail,userPassword : ",adresseEmail+","+userPassword);
    if (!profil.value.email) {
      var notificationFull = $f7.notification.create({
        icon: '<i class="f7-icons">person</i>',
        title: 'Deconnexion',
        titleRightText: 'Maintenant',
        subtitle: 'Echec !',
        text: 'Vous êtes déjà deconnecté.',
        closeTimeout: 3000
      });
      notificationFull.open();
      $f7.preloader.hide();
      return;
    }

    var credentials = {
      hashEmail: Md5.hashStr(profil.value.email),
      hashPassword: Md5.hashStr(profil.value.password),
      profil: profil.value,
      GDH: js_date('Y-m-d H:i')
    };
    console.log("//1080 profil.f7.html userDeconnect credentials :", credentials); /////////////////

    if ($f7.params.isOnLine == true) {
      // l'application est en ligne
      //alert(JSON.stringify(formData));
      $f7.preloader.show(); // faire userSync() avant via un event ?

      $f7.request.post($f7.params.server + '/societas2/sync.php', {
        action: 'userDeconnect',
        credentials: JSON.stringify(credentials)
      }).then(function (res) {
        console.log("//1089 profil userDeconnect : ", res.data);
        console.log("//1090 profil userDeconnect : ", JSON.parse(res.data));
        var reponse = JSON.parse(res.data);
        reponse = JSON.parse(res.data);
        console.log("//1091 profil.f7 userDeconnect reponse :", reponse);

        if (reponse.ACK == "OK") {
          //user deconnecté
          $f7.preloader.hide();
          var notificationFull = $f7.notification.create({
            icon: '<i class="f7-icons">person</i>',
            title: 'Deconnexion',
            titleRightText: 'Maintenant',
            subtitle: 'Vous êtes deconnecté !',
            text: 'Vos données personnelles ont été effacées de ce terminal. Vous les récupérerez lors de votre prochaine connexion.',
            closeTimeout: 3000
          });
          notificationFull.open(); // on update le profil via un event ? ou pas ?
          //$f7.emit('profilUpdated', true); //mute = true

          profil.value = {}; //$f7.emit('profilUpdated', true); //mute = true
          // on efface le profil dans LF

          localforage.setItem('syncedProfil', {}).then(function (syncedProfil) {
            // Do other things once the value has been saved.
            console.log("//1119 profilf7 userDeconnect syncedProfil effacé : ", syncedProfil);
          }).catch(function (err) {
            // This code runs if there were any errors
            console.log(err);
          }); // on efface les messages dans LF ???
        }

        if (reponse.ACK == "NOK") {
          $f7.preloader.hide();
          var notificationFull = $f7.notification.create({
            icon: '<i class="f7-icons">person</i>',
            title: 'Deconnexion',
            titleRightText: 'Maintenant',
            subtitle: 'Echec !',
            text: 'Vous n\'avez pas pu être deconnecté.',
            closeTimeout: 3000
          });
          notificationFull.open();
        }
      }); // request.post
    } // fin Online


    if ($f7.params.isOnLine == false) {
      // l'application est hors ligne
      var notificationFull = $f7.notification.create({
        icon: '<i class="f7-icons">person</i>',
        title: 'Deconnexion',
        titleRightText: 'Maintenant',
        subtitle: 'Application hors ligne !',
        text: 'Votre terminal n\'est actuellement pas connecté à internet. Veuillez rééssayer plus tard.',
        closeTimeout: 3000
      });
      notificationFull.open();
    } // fin offLine

  }

  function connexion() {
    var formData = $f7.form.convertToData('#connexion-form'); // profil.hashUserEmail = Md5.hashStr(document.getElementById("userEmail").value);

    console.log("//1161 profil", profil);
    console.log("//1162 profil.f7 formData", formData);
    var credentials = {
      hashEmail: Md5.hashStr(formData.email),
      hashPassword: Md5.hashStr(formData.password),
      statut: profil.value.statut,
      mute: false,
      GDH: js_date('Y-m-d H:i')
    };
    console.log("//1183 profil.f7 credentials : ", credentials); /////////////////

    if ($f7.params.isOnLine == true) {
      // l'application est en ligne
      $f7.preloader.show();
      $f7.request.post($f7.params.server + '/societas2/sync.php', {
        action: 'connexion',
        credentials: JSON.stringify(credentials)
      }).then(function (res) {
        //console.log("//994 connexion : ",res.data);
        $f7.preloader.hide();
        var cetteReponse = JSON.parse(res.data);
        console.log("//1034 connexion reponse : ", cetteReponse); // if ACK == NOK 

        if (cetteReponse.ACK == "NOK") {
          profil.value.connected = false;
          var notificationFull = $f7.notification.create({
            icon: '<i class="f7-icons">person</i>',
            title: 'Connexion',
            titleRightText: 'Maintenant',
            subtitle: 'Vous n\'êtes pas connecté.',
            text: cetteReponse.message,
            closeTimeout: 3000
          });
          notificationFull.open();
          return;
        }

        if (cetteReponse.ACK == "OK") {
          // if ACK == OK 
          //tester si la connexion est réussie
          //	mettre à jour le store.profil avec 
          //		connected = 1; 
          //		emailChecked = 1; 
          //		statut.push(js_date("Y-m-d H:i"));
          //on stocke l'email, le mot de passe, le hashEmail et le hashPassword dans $store.profil;
          //profil.value.connected = true;
          profil.value.emailChecked = 1;
          profil.value.email = formData.email;
          profil.value.password = formData.password;
          profil.value.hashEmail = Md5.hashStr(formData.email);
          profil.value.hashPassword = Md5.hashStr(formData.password);
          profil.value.lastConnexion = js_date("Y-m-d H:i");

          if (!profil.value.statut) {
            profil.value.statut = [];
            profil.value.statut[0] = js_date("Y-m-d H:i");
            profil.value.statut[1] = js_date("Y-m-d H:i");
          }

          if (!profil.value.statut[1]) profil.value.statut[1] = js_date("Y-m-d H:i");
          var remoteProfil = JSON.parse(cetteReponse.profil);
          console.log("//1234 profil.f7 remoteProfil: ", remoteProfil);
          var remoteUdpated = parseDate(remoteProfil.updated);
          console.log("//1235 profil.f7 Date.parse(remoteProfil.updated) : ", parseDate(remoteProfil.updated));
          var localUpdated = parseDate(profil.value.updated);
          console.log("//1237 profil.f7 Date.parse(remoteProfil.updated) : " + parseDate(remoteProfil.updated) + " ?? localUpdated : " + localUpdated);
          $store.dispatch('updateProfil', remoteProfil);
          profil.value.email = formData.email;
          profil.value.password = formData.password;
          profil.value.hashEmail = Md5.hashStr(formData.email);
          profil.value.hashPassword = Md5.hashStr(formData.password);
          $store.dispatch('updateProfil', profil.value);
          localforage.setItem('syncedProfil', profil.value).then(function (syncedProfil) {
            console.log(syncedProfil);
          }).catch(function (err) {
            console.log(err);
          });
          /*
          if(localUpdated < remoteUdpated){
          	Object.assign(profil.value,remoteProfil);
          	//profil.value.email = formData.email;
          	//profil.value.password = formData.password;
          	//profil.value.hashEmail = Md5.hashStr(formData.email);
          	//profil.value.hashPassword = Md5.hashStr(formData.password);
          	$store.dispatch('updateProfil', profil.value);
          	
          	
          }
          */

          var notificationFull = $f7.notification.create({
            icon: '<i class="f7-icons">person</i>',
            title: 'Connexion',
            titleRightText: 'Maintenant',
            subtitle: '(Re)connexion réussie !',
            text: cetteReponse.message,
            closeTimeout: 3000
          });
          notificationFull.open(); // endif
        }
      }); // request.post
    } // fin Online


    if ($f7.params.isOnLine == false) {
      // l'application est hors ligne
      var notificationFull = $f7.notification.create({
        icon: '<i class="f7-icons">person</i>',
        title: 'inscription',
        titleRightText: 'Maintenant',
        subtitle: 'Application hors ligne !',
        text: 'Votre terminal n\'est actuellement pas connecté à internet. Veuillez rééssayer de vous reconnecter plus tard.',
        closeTimeout: 3000
      });
      notificationFull.open();
    } // fin offLine
    ////////////////////////


    console.log("//1108 profil connexion-form formData", JSON.stringify(formData));
  }

  ;

  function deconnexion() {
    // app.dialog.confirm(text, title, callbackOk, callbackCancel)- create Confirm Dialog and open it
    $f7.dialog.confirm('Vous allez vous déconnecter votre compte ' + profil.value.email + ' et effacer toutes vos données personnelles de ce terminal. Vous récupérerez ces données en cas de reconnexion.', function () {
      userDeconnect();
    });
  }

  function changementPassword() {
    var formData = $f7.form.convertToData('#chgtPWD-form');
    console.log("//1287 profil.f7 formData :", formData); // le nouveau password n'est pas renseigné

    if (formData.new_password == "" || formData.new_password2 == "" || formData.password == "") {
      var notificationFull = $f7.notification.create({
        icon: '<i class="f7-icons">person</i>',
        title: 'Changement de mot de passe',
        titleRightText: 'Maintenant',
        subtitle: 'Attention !',
        text: 'Veuillez renseigner tous les champs.',
        closeTimeout: 3000
      });
      notificationFull.open();
      return;
    } //les 2 nouveaux mots de passe saisis ne sont pas identiques


    if (formData.new_password != formData.new_password2) {
      var notificationFull = $f7.notification.create({
        icon: '<i class="f7-icons">person</i>',
        title: 'Changement de mot de passe',
        titleRightText: 'Maintenant',
        subtitle: 'Attention !',
        text: 'Vos deux mots de passe doivent être identiques.',
        closeTimeout: 3000
      });
      notificationFull.open();
      return;
    }

    var credentials = {
      hashEmail: Md5.hashStr(profil.value.email),
      hashPassword: Md5.hashStr(formData.password),
      hashNewPassword: Md5.hashStr(formData.new_password),
      GDH: js_date('Y-m-d H:i')
    };
    console.log("//1326 profil.f7.html credentials :", credentials); /////////////////

    if ($f7.params.isOnLine == true) {
      // l'application est en ligne
      //alert(JSON.stringify(formData));
      $f7.preloader.show();
      $f7.request.post($f7.params.server + '/societas2/sync.php', {
        action: 'changementPassword',
        credentials: JSON.stringify(credentials)
      }).then(function (res) {
        console.log("//1336 profil changement mot de passe : ", res.data);
        console.log("//1337 profil changement mot de passe : ", JSON.parse(res.data));
        var reponse = JSON.parse(res.data);

        if (reponse.ACK == "OK") {
          //password changé
          profil.value.password = formData.new_password;
          profil.value.passwordChanged = true;
          $f7.preloader.hide();
          var notificationFull = $f7.notification.create({
            icon: '<i class="f7-icons">person</i>',
            title: 'Mot de passe',
            titleRightText: 'Maintenant',
            subtitle: 'Réussi !',
            text: 'Vous avez changé votre mot de passe  !',
            closeTimeout: 3000
          });
          notificationFull.open(); // on update le profil via un event.

          $f7.emit('profilUpdated', true); //mute = true
        }

        if (reponse.ACK == "NOK") {
          $f7.preloader.hide();
          var notificationFull = $f7.notification.create({
            icon: '<i class="f7-icons">person</i>',
            title: 'Mot de passe',
            titleRightText: 'Maintenant',
            subtitle: 'Echec !',
            text: 'Votre mot de passe n\'a pas pu être changé.',
            closeTimeout: 3000
          });
          notificationFull.open();
        }
      }); // request.post
    } // fin Online


    if ($f7.params.isOnLine == false) {
      // l'application est hors ligne
      var notificationFull = $f7.notification.create({
        icon: '<i class="f7-icons">person</i>',
        title: 'inscription',
        titleRightText: 'Maintenant',
        subtitle: 'Application hors ligne !',
        text: 'Votre terminal n\'est actuellement pas connecté à internet. Veuillez rééssayer plus tard.',
        closeTimeout: 3000
      });
      notificationFull.open();
    } // fin offLine


    $f7.popup.close("#popup-change-password", true); ////////////////////////
  } // bouton Changement mot de passe		


  function updateProfil() {
    if (!profil.value.email) {
      console.log("//1125 il faut être connecté pour pouvoir synchroniser son profil. profil.value.connected = ", profil.value.connected);
      var notificationFull = $f7.notification.create({
        icon: '<i class="f7-icons">person</i>',
        title: 'Profil',
        titleRightText: 'Maintenant',
        subtitle: 'Synchronisation du profil.',
        text: 'Vous devez être inscrit et connecté sur l\'application pour pouvoir synchroniser votre profil.',
        closeTimeout: 3000
      });
      notificationFull.open();
      return;
    }

    var formData = $f7.form.convertToData('#profil-form');

    if (formData.userPseudonyme == "") {
      console.log("//1142 veuillez renseigner votre pseudonyme");
      var notificationFull = $f7.notification.create({
        icon: '<i class="f7-icons">person</i>',
        title: 'Profil',
        titleRightText: 'Maintenant',
        subtitle: 'Synchronisation du profil.',
        text: 'Vous devez renseigner le champ "Pseudonyme".',
        closeTimeout: 3000
      });
      notificationFull.open(); //return;
    }

    var credentials = {
      hashEmail: profil.value.hashEmail,
      hashPassword: profil.value.hashEmail,
      GDH: js_date('Y-m-d H:i'),
      formData: formData
    }; /////////////////
    //TODO vérifier que le pseudo n'est pas dejà pris sur le serveur et le cas echeant faire une notif
    //$f7.preloader.show();

    Object.assign(profil.value, formData);
    $f7.emit('profilUpdated', false); //mute = false
    ////////////////////////
    //alert(JSON.stringify(formData));
  }

  function updateSyncParams() {
    var formData = $f7.form.convertToData('#sync-form');
    localStorage.syncParams = JSON.stringify(formData);

    if ($f7.params.isOnLine == false) {
      var notificationFull = $f7.notification.create({
        icon: '<i class="f7-icons">arrow_2_circlepath_circle</i>',
        title: 'Préférences de synchronisation',
        titleRightText: 'Maintenant',
        subtitle: 'Synchronisation impossible !',
        text: 'Vous n\'êtes actuellemenet pas connecté à internet. La synchronisation de vos données est donc impossible. Veuillez rééssayer plus tard.',
        closeTimeout: 3000
      });
      notificationFull.open();
      return;
    } // {"duree_sync":"8","automaticSync":["oui"]}


    profil.value.syncParams = formData;
    console.log("//1146 profil.value", profil.value);
    $f7.emit('reSync'); //alert(JSON.stringify(formData));

    var notificationFull = $f7.notification.create({
      icon: '<i class="f7-icons">arrow_2_circlepath_circle</i>',
      title: 'Préférences de synchronisation',
      titleRightText: 'Maintenant',
      subtitle: 'Synchronisation en cours !',
      text: 'Vos préférences de synchronisation sont enregistrées. Les données seront resynchronisées.',
      closeTimeout: 3000
    });
    notificationFull.open();
  }

  function deleteSyncedData() {
    if ($f7.params.isOnLine == false) {
      var notificationFull = $f7.notification.create({
        icon: '<i class="f7-icons">arrow_2_circlepath_circle</i>',
        title: 'Préférences de synchronisation',
        titleRightText: 'Maintenant',
        subtitle: 'Application non connectée',
        text: 'Vous n\'êtes pas connecté à internet. Veuillez vous connecter pour pouvoir effacer et synchroniser les données de votre terminal.',
        closeTimeout: 3000
      });
      notificationFull.open();
      return;
    }

    ; //alert("Effacement des données synchronisées");

    var notificationFull = $f7.notification.create({
      icon: '<i class="f7-icons">arrow_2_circlepath_circle</i>',
      title: 'Préférences de synchronisation',
      titleRightText: 'Maintenant',
      subtitle: 'Synchronisation en cours !',
      text: 'Les données stockées seront effacées et resynchronisées selon vos préférences.',
      closeTimeout: 3000
    });
    notificationFull.open();
    localforage.removeItem('syncedLiturgie').then(function () {
      console.log('syncedLiturgie cleared!');
    }).catch(function (err) {
      console.log(err);
    });
    localforage.removeItem('syncedOrdo').then(function () {
      console.log('syncedOrdo cleared!');
    }).catch(function (err) {
      console.log(err);
    });
    localforage.removeItem('syncedCal').then(function () {
      console.log('syncedCal cleared!');
    }).catch(function (err) {
      console.log(err);
    });
    $f7.emit('reSync');
  }

  function updateLiturgieParams() {
    var formData = $f7.form.convertToData('#params-form'); //alert(JSON.stringify(formData));

    var notificationFull = $f7.notification.create({
      icon: '<i class="f7-icons">arrow_2_circlepath_circle</i>',
      title: 'Préférences d\'affichage des textes liturgiques',
      titleRightText: 'Maintenant',
      subtitle: 'Mise à jour.',
      text: 'Vos préférences ont bien été enregistrées.',
      closeTimeout: 3000
    });
    notificationFull.open();
  } // $(document).on('page:init','.page[data-name="prieres"]', function (e) {


  $(document).on('page:tabshow', '.page[data-name="profil"]', function (e) {
    console.log("//1547 profil tabshow"); // on remplit les formulaires

    $f7.form.fillFromData('#connexion-form', profil.value);
    $f7.form.fillFromData('#chgtPWD-form', profil.value); // Alerts générer password

    $('.open-generer-password').on('click', genererPassword); // bouton connexion

    $('.connexion-form-to-data').on('click', connexion); // bouton Changement mot de passe

    $('.chgtPWD-form-to-data').on('click', changementPassword);
    $('.deconnexion-button').on('click', deconnexion);
    $('.inviter-popup-link').on('click', function () {
      $f7.emit('displayInviterPopup');
    });
  });
  $(document).on('page:tabhide', '.page[data-name="profil"]', function (e) {
    console.log("//1561 profil page:tabhide");
    $('.open-generer-password').off('click', genererPassword);
    $('.connexion-form-to-data').off('click', connexion);
    $('.chgtPWD-form-to-data').off('click', changementPassword);
    $('.deconnexion-button').off('click', deconnexion);
  }); // tab-connexion

  $(document).on('tab:show', '#tab-connexion', function (e) {
    console.log("//1571 profil.f7 tab-connexion show : ", e);
    console.log("//1572 profil.f7.html profil.value.statut : ", profil.value.statut); // on remplit les formulaires

    $f7.form.fillFromData('#connexion-form', profil.value);
    $f7.form.fillFromData('#chgtPWD-form', profil.value); // Alerts générer password

    $('.open-generer-password').on('click', genererPassword); // bouton Changement mot de passe

    $('.chgtPWD-form-to-data').on('click', changementPassword); // bouton connexion

    $('.connexion-form-to-data').on('click', connexion); // bouton deconnexion

    $('.deconnexion-button').on('click', deconnexion);
  });
  $(document).on('tab:hide', '#tab-connexion', function (e) {
    console.log("//1590 profil.f7 tab-connexion hide : ", e);
    $('.open-generer-password').off('click', genererPassword);
    $('.connexion-form-to-data').off('click', connexion);
    $('.chgtPWD-form-to-data').off('click', changementPassword);
    $('.deconnexion-button').off('click', deconnexion);
  }); // tab-profil

  $(document).on('tab:show', '#tab-profil', function (e) {
    $f7.form.fillFromData('#profil-form', profil.value);
    $('.profil-form-to-data').on('click', updateProfil);
  });
  $(document).on('tab:hide', '#tab-profil', function (e) {
    $('.profil-form-to-data').off('click', updateProfil);
  }); // tab-parametres

  $(document).on('tab:show', '#tab-parametres', function (e) {
    $('.sync-form-to-data').on('click', updateSyncParams);
    $('.delete-synced-data').on('click', deleteSyncedData);
    $('.params-form-to-data').on('click', updateLiturgieParams);
  });
  $(document).on('tab:hide', '#tab-parametres', function (e) {
    $('.sync-form-to-data').off('click', updateSyncParams);
    $('.delete-synced-data').off('click', deleteSyncedData);
    $('.params-form-to-data').off('click', updateLiturgieParams);
  }); // Social sharing

  /*
  // this is the complete list of currently supported params you can pass to the plugin (all optional)
  var socialShareOptions = {
    message: 'Je viens de fonder une escouade sur l\'application Societas laudis : . Rejoignez-moi !', // not supported on some apps (Facebook, Instagram)
    subject: 'the subject', // fi. for email
    /:files: ['', ''], // an array of filenames either locally or remotely
    //url: 'https://www.website.com/foo/#bar?a=b',
    chooserTitle: 'Pick an app', // Android only, you can override the default share sheet title
    //appPackageName: 'com.apple.social.facebook', // Android only, you can provide id of the App you want to share with
    //iPadCoordinates: '0,0,0,0' //IOS only iPadCoordinates for where the popover should be point.  Format with x,y,width,height
  };
  	var onSuccess = function(result) {
    console.log("Share completed? " + result.completed); // On Android apps mostly return false even while it's true
    console.log("Shared to app: " + result.app); // On Android result.app since plugin version 5.4.0 this is no longer empty. On iOS it's empty when sharing is cancelled (result.completed=false)
  };
  	var onError = function(msg) {
    console.log("Sharing failed with message: " + msg);
  };
  	window.plugins.socialsharing.shareWithOptions(socialShareOptions, onSuccess, onError);
  */

  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
  <div class="page" data-name="profil">
    <div class="navbar">
      <div class="navbar-bg"></div>
      <div class="navbar-inner sliding">
         
        <div class="title">Votre profil</div>
        <div class="right">
			<a href="#inv" class="tab-link icon-only inviter-popup-link">
				<i class="fas fa-user-plus"></i> 
			</a>	
          <a href="/messages/" class="tab-link icon-only">
            <i class="icon material-icons">mail_outline<span class="badge color-red newMessages" id="newMessages">3</span></i> 
          </a>
        </div>
      </div>
    </div>
    
    <!-- toolbar -->
    <div class="toolbar tabbar toolbar-top">
      <div class="toolbar-inner">
        <a href="#tab-connexion" class="tab-link tab-link-active"><i class="fas fa-user-lock"></i></a>
        <a href="#tab-profil" class="tab-link"><i class="fas fa-user-cog"></i></a>
        <a href="#tab-parametres" class="tab-link"><i class="fas fa-cog"></i></a>
      </div>  
    </div>
    <!-- toolbar -->
    
    <!-- .popup-change-password -->
		<div class="popup" id="popup-change-password" >
		  <div class="view">
			<div class="page">
			  <div class="navbar">
				<div class="navbar-bg"></div>
				<div class="navbar-inner">
				  <div class="title">Mot de passe</div>
				  <div class="right">
					<a href="#" class="link popup-close"><i class="far fa-times-circle"></i></a>
				  </div>
				</div>
			  </div>
			  <!-- navbar -->
			  
			  
			  <div class="page-content" style="background: #ececec">
				<div class="block">
					<div class="block block-title">Changement de mot de passe</div>
				  <form class="list no-hairlines-md" id="chgtPWD-form">
						<ul>
							
						<li class="item-content item-input">
							<div class="item-media">
								<i class="fas fa-lock"></i>
							</div>
							<div class="item-inner">
								<div class="item-title item-label">Ancien mot de passe</div>
								<div class="item-input-wrap">
									<input type="password" name="password" required value="${current.password}" placeholder="Votre ancien mot de passe" />
									<span class="input-clear-button"></span>
								</div>
							</div>
						</li>
						
						<li class="item-content item-input">
							<div class="item-media">
								<i class="fas fa-lock"></i>
							</div>
							<div class="item-inner">
								<div class="item-title item-label">Nouveau mot de passe</div>
								<div class="item-input-wrap">
									<input type="password" name="new_password" required placeholder="Votre nouveau mot de passe" />
									<span class="input-clear-button"></span>
								</div>
								
								<div class="item-title item-label">Répétez votre nouveau mot de passe</div>
								<div class="item-input-wrap">
									<input type="password" name="new_password2" required placeholder="Votre nouveau mot de passe" />
									<span class="input-clear-button"></span>
								</div>
							
							</div>
						</li>
						<li>
							<div class="col">
							<a class="col button button-raised button-fill color-green chgtPWD-form-to-data" href="#">Changement du mot de passe</a>
							</div>
						</li>
						</ul>
					 
						
					</form>
					<!-- form -->  
				</div>
				<!-- block -->
			  </div>
				<!-- page-content -->
			</div>
		  <!-- page -->
		  </div>
			<!-- view -->
		</div> 
		<!-- popup-popup-change-password -->
    
    
    <div class="panel panel-left left-profil panel-reveal panel-resizable panel-init">
      <div class="page">
            <div class="navbar">
							<div class="navbar-bg"></div>
							<div class="navbar-inner">
								<div class="title">Profil</div>
							</div>
						</div>
						<div class="page-content">
							<div class="block">
								<a href="/contenu/Objectif/ascese_objectifs/" class="col button button-raised button-fill panel-close">
									Objectif
								</a>
								<p></p>
								
							</div>
						</div>
          </div>
    </div>
    
    
    <div class="tabs">
			
		    
		<!-- tab-2 connexion - deconnexion - cght mot de passe -->
      <div id="tab-connexion" class="page-content tab tab-active" data-name="connexion">
				
        <div class="block">
			<div class="block">Votre identifiant et votre mot de passe.</div>
			    
          <form class="list" id="connexion-form">
			<ul>
			  <li>
				<div class="item-content item-input">
					<div class="item-media">
							<i class="fas fa-at"></i>
					</div>
				  <div class="item-inner">
					<div class="item-title item-label">Votre identifiant - adresse e-mail</div>
					<div class="item-input-wrap">
					  <input type="email" name="email" required value="${profil.value.email}" placeholder="E-mail" />
					  <span class="input-clear-button"></span>
					</div>
				  </div>
				</div>
			  </li>
			  <li>
				<div class="item-content item-input">
					<div class="item-media">
							<i class="fas fa-lock"></i>
					</div>
				  <div class="item-inner">
					<div class="item-title item-label">Votre mot de passe</div>
					<div class="item-input-wrap">
					  <input type="password" name="password" required value="${profil.value.password}" placeholder="Mot de passe" />
					  <span class="input-clear-button"></span>
					</div>
				  
				 <!-- </div> -->
					<div class="item-text">
						
						<div class="chip color-blue">
							<div class="chip-label open-generer-password">Générer</div>
						</div>
						<a href="#" class="chip color-orange popup-open" data-popup="#popup-change-password">
							<div class="chip-label">Changer</div>
						</a>
						
					</div> 
					<!-- item-text -->
						
					</div>
				</div>
				<!-- item-content -->
				
				
			  </li>			  
			</ul>
		  </form>
		  <div class="block block-strong">	
			<div class="col ${current.disconnected}"><a class="button button-raised button-fill color-green connexion-form-to-data" href="#">Connexion</a></div>
			<br/>
			<div class="col ${current.connected}"><a class="button button-raised button-fill color-red deconnexion-button" href="#">Deconnexion</a></div>
		  </div>
        </div>
        
       
      </div>
       
    <!-- tab-3 profil -->
      <div id="tab-profil" class="page-content tab" data-name="profil">
				 
        <div class="block">
          <div class="block">Renseignez ou mettez à jour vos informations personnelles</div>
          <form class="list" id="profil-form">
				  <ul>
					<li class="item-content item-input item-input-with-info">
						<div class="item-media">
							<i class="far fa-address-card"></i>
						  </div>
						  <div class="item-inner">
							<div class="item-title item-label">Pseudonyme</div>
							<div class="item-input-wrap">
							  <input name="userPseudonyme" type="text" placeholder="Anonyme" required validate />
							  <span class="input-clear-button"></span>
							  <div class="item-input-info">Obligatoire</div>
							</div>
						  </div>
					</li>
					<li class="item-content item-input item-input-with-info">
						<div class="item-media">
							<i class="fas fa-signature"></i>
						  </div>
						  <div class="item-inner">
							<div class="item-title item-label">Votre prénom</div>
							<div class="item-input-wrap">
							  <input name="userPrenom" type="text" placeholder="Prénom" />
							  <span class="input-clear-button"></span>
							  <div class="item-input-info">Facultatif</div>
							</div>
						  </div>
					</li>
					<li class="item-content item-input item-input-with-info">
						<div class="item-media">
							<i class="fas fa-user-check"></i>
						  </div>
						  <div class="item-inner">
							<div class="item-title item-label">Votre patronyme</div>
							<div class="item-input-wrap">
							  <input name="userPatronyme" type="text" placeholder="Nom" />
							  <span class="input-clear-button"></span>
							  <div class="item-input-info">Facultatif</div>
							</div>
						  </div>
					</li>
					<li class="item-content item-input">
					  <div class="item-media">
						<i class="fas fa-venus-mars"></i>
					  </div>
					  <div class="item-inner">
						<div class="item-title item-label">Sexe</div>
						<div class="item-input-wrap input-dropdown-wrap">
						  <select name="sexe">
							<option selected="selected" value="homme">Homme</option>
							<option value="femme">Femme</option>
						  </select>
						</div>
					  </div>
					</li>
					<li class="item-content item-input item-input-with-info">
					  <div class="item-media">
						<i class="fas fa-house-user"></i>
					  </div>
					  <div class="item-inner">
						<div class="item-title item-label">Résidence</div>
						<div class="item-input-wrap">
						  <input name="userResidence" type="text" placeholder="Votre lieu de résidence" />
						  <span class="input-clear-button"></span>
						  <div class="item-input-info">Nécessaire pour partir vers Jérusalem.</div>
						</div>
					  </div>
					</li>
					<li class="item-content item-input">
					  <div class="item-media">
						<i class="far fa-hand-pointer"></i>
					  </div>
					  <div class="item-inner">
						<div class="item-title item-label">Commentaire</div>
						<div class="item-input-wrap">
						  <textarea name="comment" class="resizable" placeholder="Quelques mots sur vous..."></textarea>
						</div>
						<div class="item-input-info">Facultatif</div>
					  </div>
					</li>
				</ul>
				<div class="block  row">
					 <a id="btnSyncProfil"  class="profil-form-to-data col button button-raised button-fill color-green" href="#">Synchroniser</a>
				</div>
		  </form>
          
        </div>
      </div>
     
		<!-- tab-4 paramètres -->
		<div id="tab-parametres" class="page-content tab" data-name="parametres">
			
        <div class="block">
          <div class="block">Personalisez les paramètres de l'application</div>
			<h4>Version de l'application : 1.0.0.11</h4>
			<h3>Disponibilité des textes hors-réseau :</h3>
			  Vous pouvez consulter quelles sont les données qui sont disponibles hors-réseau dans l'onglet "Date". 
			  Si le jour du mois est en caractères gras, cela signifie que vous avez accès aux textes sans connexion réseau.
			  Vous pouvez paramétrer la synchronisation afin de mettre à jour les textes avec le serveur 
			  pour y accéder hors ligne (en l'absence de réseau) à partir d'aujourd'hui (${today}) et pour les prochains :	  
        </div>
      
		<form class="list form-store-data media-list" id="sync-form">
		  <ul>
			<li>
				<label class="item-radio item-radio-icon-start item-content">
				<input type="radio" name="duree_sync" value="3"  />
				<i class="icon icon-radio"></i>
				<div class="item-inner">
				  <div class="item-title">3 jours.</div>
				</div>
				</label>
			</li>
				
			<li>
				<label class="item-radio item-radio-icon-start item-content">
				<input type="radio" checked name="duree_sync" value="7"  />
				<i class="icon icon-radio"></i>
				<div class="item-inner">
				  <div class="item-title">7 jours (une semaine).</div>
				</div>
				</label>
			</li>
			<li>
				<label class="item-radio item-radio-icon-start item-content">
				<input type="radio" name="duree_sync" value="14"  />
				<i class="icon icon-radio"></i>
				<div class="item-inner">
				  <div class="item-title">14 jours (deux semaines).</div>
				</div>
				</label>
			</li>
				
			<li>
				
				
			  <label class="item-checkbox item-content">
				<input type="checkbox" checked name="automaticSync" />
				<i class="icon icon-checkbox"></i>
				<div class="item-inner">
				  <div class="item-title">Automatiser la synchronisation</div>
				  <div class="item-subtitle">au démarrage de l'application.</div>
				  <div class="item-text"> Conseillé, mais soyez attentif à votre consommation de données mobiles.</div>
				</div>
			  </label>
			</li>
		  
		   </ul>
		</form> <!-- sync-form -->
	
		<div class="block">
			<button class="sync-form-to-data col button button-large button-raised button-fill color-green">
				Synchroniser</button>
		  
			  <p>Vous pouvez également effacer toutes les données stockées hors ligne
			   pour sauvegarder de l'espace dans la mémoire de votre terminal et resynchroniser :</p>
			 <button class="delete-synced-data col button button-large button-raised button-fill color-red">
				  Effacer</button>
		</div>
		 <!--------    ----->
	<form id="params-form">
		 <div class="block-title">Langue</div>
    <div class="list media-list" > 
      <ul>
        <li>
          <label class="item-radio item-radio-icon-start item-content">
            <input type="radio" name="langue" value="fr" checked />
            <i class="icon icon-radio" checked></i>
            <div class="item-inner">
              <div class="item-title-row">
                <div class="item-title">Français</div>
                <div class="item-after text-color-green">Disponible</div>
              </div>
              
              <div class="item-subtitle">Aidez-nous à améliorer les traductions.</div>
              <div class="item-text"> sur https://www.societaslaudis.org</div>
            </div>
           
          </label>
        </li>
        <li>
            <a href="https://www.societaslaudis.org/" class=" item-content link external">
              <div class="item-media"><i class="fas fa-link"></i></div>
              <div class="item-inner">
                <div class="item-title-row">
                  <div class="item-title">English</div>
					<div class="item-after text-color-deeporange">In progress</div>
                </div>
					<div class="item-subtitle">Aidez-nous à améliorer les traductions.</div>
					<div class="item-text"> sur https://www.societaslaudis.org</div>
              </div>
            </a>
        </li>
        <li>
            <a href="https://www.societaslaudis.org/" class=" item-content link external">
              <div class="item-media"><i class="fas fa-link"></i></div>
              <div class="item-inner">
                <div class="item-title-row">
					<div class="item-title">Arabe عربي</div>
					<div class="item-after text-color-red">في تقدم</div>
                </div>
					<div class="item-subtitle">Aidez-nous à améliorer les traductions.</div>
					<div class="item-text"> sur https://www.societaslaudis.org</div>
              </div>
            </a>
        </li>
      </ul>
    </div>
    <!-- list media-list / Langue -->
    <div class="block-title">Ordo liturgique</div>
    <div class="list media-list">
      <ul>
        <li>
          <label class="item-radio item-radio-icon-start item-content">
            <input type="radio" name="ordo" value="SL" checked />
            <i class="icon icon-radio" checked></i>
            <div class="item-inner">
              <div class="item-title-row">
                <div class="item-title">SL (Societas Laudis)</div>
                <div class="item-after text-color-green">Disponible</div>
              </div>
              
              <div class="item-subtitle">Un ordo générique adapté à la France.</div>
              <!-- <div class="item-text"> sur https://www.societaslaudis.org</div> -->
            </div>
           
          </label>
        </li>
        <li>
          <label class="item-radio item-radio-icon-start item-content">
            <input type="radio" name="ordo" value="KSA" />
            <i class="icon icon-radio"></i>
            <div class="item-inner">
              <div class="item-title-row">
                <div class="item-title">KSA</div>
                <div class="item-after text-color-deeporange">Bientôt disponible</div>
              </div>
              
              <div class="item-subtitle">Ordo de l'abbaye Ste Anne de Kergonan.</div>
              <div class="item-text">Monastère bénédictin en Bretagne (Conrégation de Solesmes)</div>
            </div>
           
          </label>
        </li>
        <li>
          <label class="item-radio item-radio-icon-start item-content">
            <input type="radio" name="ordo" value="NKC" />
            <i class="icon icon-radio"></i>
            <div class="item-inner">
              <div class="item-title-row">
                <div class="item-title">NKC</div>
                <div class="item-after text-color-deeporange">Bientôt disponible</div>
              </div>
              
              <div class="item-subtitle">Diocèse de Nouakchott (Mauritanie).</div>
              <div class="item-text">Ordo adapté à l'Afrique du Nord-Ouest.</div>
            </div>
           
          </label>
        </li>
        <li>
            <a href="https://www.societaslaudis.org/" class=" item-content link external">
              <div class="item-media"><i class="fas fa-link"></i></div>
              <div class="item-inner">
                <div class="item-title-row">
					<div class="item-title">Ordo particulier</div>
					<div class="item-after text-color-deeppurple">A construire</div>
                </div>
					<div class="item-subtitle">Intégrer votre propre ordo liturgique.</div>
					<div class="item-text"> Contact sur https://www.societaslaudis.org</div>
              </div>
            </a>
        </li>
      </ul>
    </div>
	<!-- list media-list / Ordo liturgique -->
    <div class="block-title">Office divin</div>
    <div class="list media-list">
      <ul>
        <li>
          <label class="item-radio item-radio-icon-start item-content">
            <input type="radio" name="cursus" value="LH" checked />
            <i class="icon icon-radio" checked></i>
            <div class="item-inner">
              <div class="item-title-row">
                <div class="item-title">Cursus séculier</div>
                <div class="item-after text-color-green">Disponible</div>
              </div>
              
              <div class="item-subtitle"><i>Liturgia Horarum</i></div>
              <div class="item-text"> Liturgie lue</div>
            </div>
           
          </label>
        </li>
        <li>
          <label class="item-radio item-radio-icon-start item-content">
            <input type="radio" name="cursus" disabled value="OCO" />
            <i class="icon icon-radio" checked></i>
            <div class="item-inner">
              <div class="item-title-row">
                <div class="item-title">Cursus séculier</div>
                <div class="item-after text-color-red">En construction</div>
              </div>
              
              <div class="item-subtitle"><i>Ordo cantus officii</i></div>
              <div class="item-text">Liturgie chantée</div>
            </div>
           
          </label>
        </li>
        <li>
          <label class="item-radio item-radio-icon-start item-content">
            <input type="radio" name="cursus" disabled value="AR" />
            <i class="icon icon-radio" checked></i>
            <div class="item-inner">
              <div class="item-title-row">
                <div class="item-title">Cursus séculier</div>
                <div class="item-after text-color-red">En construction</div>
              </div>
              
              <div class="item-subtitle"><i>Antiphonale romanum</i> 2009</div>
              <div class="item-text">Liturgie chantée</div>
            </div>
           
          </label>
        </li>
        <li>
          <label class="item-radio item-radio-icon-start item-content">
            <input type="radio" name="cursus" disabled value="HG" />
            <i class="icon icon-radio" checked></i>
            <div class="item-inner">
              <div class="item-title-row">
                <div class="item-title">Cursus séculier</div>
                <div class="item-after text-color-red">En construction</div>
              </div>
              
              <div class="item-subtitle"><i>Les Heures Grégoriennes</i> 2008</div>
              <div class="item-text">Liturgie chantée</div>
            </div>
           
          </label>
        </li>
        <li>
          <label class="item-radio item-radio-icon-start item-content">
            <input type="radio" name="cursus" disabled value="SA" />
            <i class="icon icon-radio" checked></i>
            <div class="item-inner">
              <div class="item-title-row">
                <div class="item-title">Cursus monastique</div>
                <div class="item-after text-color-deeporange">Bientôt disponible</div>
              </div>
              
              <div class="item-subtitle"><i>Schéma A</i></div>
              <div class="item-text">Décrit dans la Règle de S. Benoît</div>
            </div>
           
          </label>
        </li>
        <li>
          <label class="item-radio item-radio-icon-start item-content">
            <input type="radio" name="cursus" disabled value="SA1" />
            <i class="icon icon-radio" checked></i>
            <div class="item-inner">
              <div class="item-title-row">
                <div class="item-title">Cursus monastique</div>
                <div class="item-after text-color-red">En construction</div>
              </div>
              
              <div class="item-subtitle"><i>Schéma A1</i></div>
              <div class="item-text">Psaumes de Prime aux petites heures</div>
            </div>
           
          </label>
        </li>
        <li>
          <label class="item-radio item-radio-icon-start item-content">
            <input type="radio" name="cursus" disabled value="SA2" />
            <i class="icon icon-radio" checked></i>
            <div class="item-inner">
              <div class="item-title-row">
                <div class="item-title">Cursus monastique</div>
                <div class="item-after text-color-red">En construction</div>
              </div>
              
              <div class="item-subtitle"><i>Schéma A2</i></div>
              <div class="item-text">Psaumes de Prime aux Vigiles</div>
            </div>
           
          </label>
        </li>
        
      </ul>
      </div>  <!-- list media-list / Office divin -->
     <!-- list media-list / Office divin -->
      <div class="block-title">Cursus monastique / latin des psaumes diurnes</div>
		<div class="list media-list">
      <ul>
      
      <li>
          <label class="item-radio item-radio-icon-start item-content">
            <input type="radio" name="psaumes" checked value="PM1985" />
            <i class="icon icon-radio" checked></i>
            <div class="item-inner">
              <div class="item-title-row">
                <div class="item-title">PM 1985 </div>
                <div class="item-after text-color-green">Disponible</div>
              </div>
              
              <div class="item-subtitle"><i>Psalterium monasticum</i> 1985</div>
              <div class="item-text">Versets imprécatoires entre crochets</div>
            </div>
           
          </label>
        </li>
        
        <li>
          <label class="item-radio item-radio-icon-start item-content">
            <input type="radio" name="psaumes" disabled value="AM2005" />
            <i class="icon icon-radio" disabled></i>
            <div class="item-inner">
              <div class="item-title-row">
                <div class="item-title">AM 2005</div>
                <div class="item-after text-color-deeporange">Bientôt disponible</div>
              </div>
              
              <div class="item-subtitle"><i>Antiphonale monsaticum</i> 1934</div>
              <div class="item-text">Versets imprécatoires entre crochets</div>
            </div>
           
          </label>
        </li>
            
        <li>
          <label class="item-radio item-radio-icon-start item-content">
            <input type="radio" name="psaumes" disabled value="AM1934" />
            <i class="icon icon-radio" disabled></i>
            <div class="item-inner">
              <div class="item-title-row">
                <div class="item-title">AM 1934 </div>
                <div class="item-after text-color-red">En construction</div>
              </div>
              
              <div class="item-subtitle"><i>Antiphonale monsaticum</i> 1934</div>
              <!-- <div class="item-text">Et versets imprécatoires entre crochets</div> -->
            </div>
           
          </label>
        </li>
      </ul>
    </div>    
		<!-- list media-list / Office divin Cursus monastique / latin des psaumes diurnes -->
    <div class="block-title">Cursus monastique / place de l'hymne</div>
		<div class="list media-list">
      <ul>
      <li>
          <label class="item-radio item-radio-icon-start item-content">
            <input type="radio" name="hy" checked value="PM1985" />
            <i class="icon icon-radio" checked></i>
            <div class="item-inner">
              <div class="item-title-row">
                <div class="item-title">Avant la psalmodie</div>
                <div class="item-after text-color-green">Disponible</div>
              </div>
              
              <div class="item-subtitle">Conforme à l'<i>Antiphonale monasticum</i> 2005</div>
              <!-- <div class="item-text">Et versets imprécatoires entre crochets</div> -->
            </div>
           
          </label>
        </li>
      <li>
          <label class="item-radio item-radio-icon-start item-content">
            <input type="radio" name="hy" disabled value="AM2009" />
            <i class="icon icon-radio" ></i>
            <div class="item-inner">
              <div class="item-title-row">
                <div class="item-title">Après la psalmodie </div>
                <div class="item-after text-color-deeporange">Bientôt disponible</div>
              </div>
              
              <div class="item-subtitle">Usage de nombreux endroits</div>
              <!-- <div class="item-text">Et versets imprécatoires entre crochets</div> -->
            </div>
           
          </label>
        </li>
      </ul>
    </div>  
		<!-- list media-list / Office divin Cursus monastique / place de l'hymne -->
    <div class="block-title">Cursus monastique / Prières litaniques à la fin des laudes et vêpres</div>
		<div class="list media-list">
      <ul>
      <li>
          <label class="item-radio item-radio-icon-start item-content">
            <input type="radio" name="preces" disabled value="no" />
            <i class="icon icon-radio"></i>
            <div class="item-inner">
              <div class="item-title-row">
                <div class="item-title">Aucune</div>
                <div class="item-after text-color-deeporange">Bientôt disponible</div>
              </div>
              
              <div class="item-subtitle">Kyrie Eleison</div>
              <!-- <div class="item-text">Et versets imprécatoires entre crochets</div> -->
            </div>
           
          </label>
        </li>
      <li>
          <label class="item-radio item-radio-icon-start item-content">
            <input type="radio" name="preces" checked value="LH" />
            <i class="icon icon-radio"></i>
            <div class="item-inner">
              <div class="item-title-row">
                <div class="item-title">Preces de LH</div>
                <div class="item-after text-color-green">Disponible</div>
              </div>
              
              <div class="item-subtitle">Conforme à <i>Liturgia Horarum</i></div>
              <!-- <div class="item-text">Et versets imprécatoires entre crochets</div> -->
            </div>
           
          </label>
        </li>
        <li>
          <label class="item-radio item-radio-icon-start item-content">
            <input type="radio" name="preces" disabled value="LH" />
            <i class="icon icon-radio"></i>
            <div class="item-inner">
              <div class="item-title-row">
                <div class="item-title"><i>Preces</i> de LH </div>
                <div class="item-after text-color-deeporange">Bientôt disponible</div>
              </div>
              
              <div class="item-subtitle">Usage de certains endroits</div>
              <div class="item-text"><i>Preces</i> de LH aux solennités</div>
            </div>
           
          </label>
        </li>
        <li>
          <label class="item-radio item-radio-icon-start item-content">
            <input type="radio" name="preces" disabled value="PM 1985" />
            <i class="icon icon-radio"></i>
            <div class="item-inner">
              <div class="item-title-row">
                <div class="item-title"><i>Litania</i> de PM 1985</div>
                <div class="item-after text-color-deeporange">Bientôt disponible</div>
              </div>
              
              <div class="item-subtitle">Litanies du <i>Psalterium monasticum</i> de 1985</div>
              <div class="item-text">Usage de certains endroits.</div>
            </div>
           
          </label>
        </li>
      </ul>
    </div>   
		<!-- list media-list / Office divin Cursus monastique / Prières litaniques à la fin des laudes et vêpres -->
    <div class="block-title">Messe / Office intégré</div>
		<div class="list media-list">
      <ul>
      <li>
          <label class="item-radio item-radio-icon-start item-content">
            <input type="radio" name="messe-integration" disabled value="tierce" />
            <i class="icon icon-radio"></i>
            <div class="item-inner">
              <div class="item-title-row">
                <div class="item-title">Tierce</div>
                <div class="item-after text-color-deeporange">Bientôt disponible</div>
              </div>
              
              <div class="item-subtitle">Office de Tierce intégré à la Messe</div>
              <div class="item-text">Après l'Introït et avant le Kyrie.</div>
            </div>
           
          </label>
        </li>
      <li>
          <label class="item-radio item-radio-icon-start item-content">
            <input type="radio" name="messe-integration" disabled value="sexte" />
            <i class="icon icon-radio"></i>
            <div class="item-inner">
              <div class="item-title-row">
                <div class="item-title">Sexte</div>
                <div class="item-after text-color-deeporange">Bientôt disponible</div>
              </div>
              
              <div class="item-subtitle">Office de Sexte intégré à la Messe</div>
              <div class="item-text">Après l'Introït et avant le Kyrie.</div>
            </div>
           
          </label>
        </li>
        <li>
          <label class="item-radio item-radio-icon-start item-content">
            <input type="radio" name="messe-integration" checked value="no" />
            <i class="icon icon-radio"></i>
            <div class="item-inner">
              <div class="item-title-row">
                <div class="item-title">Aucun</div>
                <div class="item-after text-color-green">Disponible</div>
              </div>
              
              <!-- <div class="item-subtitle">Office de Sexte</div>
              <div class="item-text">Après l'Introït et avant le Kyrie.</div> -->
            </div>
           
          </label>
        </li>
      </ul>
    </div> 
		<!-- list media-list / Messe / Office intégré -->
        <div class="block-title">Messe / Affichage des textes</div>
		<div class="list media-list">
      <ul>
      <li>
          <label class="item-radio item-radio-icon-start item-content">
            <input type="radio" name="messe-affichage" disabled value="chants_seuls" />
            <i class="icon icon-radio"></i>
            <div class="item-inner">
              <div class="item-title-row">
                <div class="item-title">Chants seuls</div>
                <div class="item-after text-color-deeporange">Bientôt disponible</div>
              </div>
              
              <div class="item-subtitle">Propre des chants de la Messe</div>
              <div class="item-text">Sans lectures et oraisons.</div>
            </div>
           
          </label>
        </li>
      <li>
          <label class="item-radio item-radio-icon-start item-content">
            <input type="radio" name="messe-affichage" disabled value="chants_oraisons_lectures" />
            <i class="icon icon-radio"></i>
            <div class="item-inner">
              <div class="item-title-row">
                <div class="item-title">Chants, oraisons et lectures</div>
                <div class="item-after text-color-deeporange">Bientôt disponible</div>
              </div>
              
              <div class="item-subtitle">Propre de la Messe chantée</div>
              <div class="item-text">Avec les lectures bilingues et les oraisons.</div>
            </div>
           
          </label>
        </li>
        <li>
          <label class="item-radio item-radio-icon-start item-content">
            <input type="radio" name="messe-affichage" checked value="propres_chantes_et_lus" />
            <i class="icon icon-radio"></i>
            <div class="item-inner">
              <div class="item-title-row">
                <div class="item-title">Propres chantés et lus</div>
                <div class="item-after text-color-green">Disponible</div>
              </div>
              
              <div class="item-subtitle">Propre de la Messe chantée</div>
              <div class="item-text">Modifications aux messes lues, avec lectures bilingues et oraisons.</div>
            </div>
           
          </label>
        </li>
        <li>
          <label class="item-radio item-radio-icon-start item-content">
            <input type="radio" name="messe-affichage" disabled value="integral" />
            <i class="icon icon-radio"></i>
            <div class="item-inner">
              <div class="item-title-row">
                <div class="item-title"><i>Ordo missae</i> intégral</div>
                <div class="item-after text-color-red">En construction</div>
              </div>
              
              <div class="item-subtitle">Avec propres insérés</div>
              <div class="item-text">Ordo de la messe et prière eucharistique.</div>
            </div>
           
          </label>
        </li>
      </ul>
    </div>
		<!-- list media-list / Messe / Affichage des textes -->
     <div class="block-title">Messe / Lectures du sanctoral</div>
		<div class="list media-list">
      <ul>
      <li>
          <label class="item-radio item-radio-icon-start item-content">
            <input type="radio" name="messe-sanctoral" checked value="du_sanctoral" />
            <i class="icon icon-radio"></i>
            <div class="item-inner">
              <div class="item-title-row">
                <div class="item-title">Sanctoral</div>
                <div class="item-after text-color-green">Disponible</div>
              </div>
              
              <div class="item-subtitle">Lectures du commun des saints</div>
              <div class="item-text">Aux mémoires.</div>
            </div>
           
          </label>
        </li>
      <li>
          <label class="item-radio item-radio-icon-start item-content">
            <input type="radio" name="messe-sanctoral" disabled value="du_temporal" />
            <i class="icon icon-radio"></i>
            <div class="item-inner">
              <div class="item-title-row">
                <div class="item-title">Temporal</div>
                <div class="item-after text-color-deeporange">Bientôt disponible</div>
              </div>
              
              <div class="item-subtitle">Lectures du temporal</div>
              <div class="item-text">Aux mémoires.</div>
            </div>
           
          </label>
        </li>
      </ul>
    </div>
		<!-- list media-list / Messe / Lectures du sanctoral -->
    <div class="block-title">Messes de Vigile</div>
		<div class="list media-list">
      <ul>
      <li>
          <label class="item-radio item-radio-icon-start item-content">
            <input type="radio" name="messe-vigile" checked value="oui" />
            <i class="icon icon-radio"></i>
            <div class="item-inner">
              <div class="item-title-row">
                <div class="item-title">Afficher</div>
                <div class="item-after text-color-green">Disponible</div>
              </div>
              
              <div class="item-subtitle">Affichage des Messes de Vigile</div>
              <div class="item-text">Noël, Pentecôte, S. Pierre et S. Paul, Assomption, ...</div>
            </div>
           
          </label>
        </li>
        <li>
          <label class="item-radio item-radio-icon-start item-content">
            <input type="radio" name="messe-vigile" disabled value="non" />
            <i class="icon icon-radio"></i>
            <div class="item-inner">
              <div class="item-title-row">
                <div class="item-title">Ne pas afficher</div>
                <div class="item-after text-color-deeporange">Bientôt disponible</div>
              </div>
              
              <div class="item-subtitle">Pas de messes de Vigile</div>
              <div class="item-text">Sauf à la Vigile pascale.</div>
            </div>
           
          </label>
        </li>
      
      </ul>
    </div>
    </form>
		<!-- list media-list / Messe / Messes de Vigile -->
	<div class="block">	
	<button class="params-form-to-data col button button-large button-raised button-fill color-deeppurple" id="modifierPreferences">
	  Modifier mes préférences</button>
	</div>
		<!---------    ------>
		</div> <!-- tab-4 -->	
		
		
    </div> <!-- tabs -->






  </div>
`
    }
    ;
}

framework7Component.id = 'd78f997845';
export default framework7Component;