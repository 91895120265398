/** @jsx $jsx */
import { $jsx } from 'framework7';
import js_date from '../js/utils.js/date.js';

function parseDate(date) {
  var parsed = Date.parse(date);

  if (!isNaN(parsed)) {
    return parsed;
  }

  return Date.parse(date.replace(/-/g, '/').replace(/[a-z]+/gi, ' '));
}

function framework7Component(props, _ref) {
  var $ = _ref.$,
      $on = _ref.$on,
      $f7 = _ref.$f7,
      $f7route = _ref.$f7route,
      $store = _ref.$store;
  var statuts = $store.getters.statuts;
  var profil;
  var escouades = $store.getters.escouades;
  var VezelayToJerusalem = $store.getters.VezelayToJerusalem;
  var current = {}; // ajouter un $on ?

  $on('pageInit', function () {
    console.log("//245 ascese.f7 pageInit"); //$f7.preloader.show();

    $('.inviterDesAmis').on('click', function (e) {
      e.preventDefault();
      console.log("//621 ascese.f7 #inviterDesAmis clicked");
      var contenu = 'J\'ai installé l\'app mobile Societas laudis. Rejoignez-moi !';
      var url;
      if ($f7.device.android) url = 'https://play.google.com/store/apps/details?id=org.societaslaudis.app';
      var socialShareOptions = {
        message: contenu,
        // not supported on some apps (Facebook, Instagram)
        subject: 'Societas laudis',
        // fi. for email
        //:files: ['', ''], // an array of filenames either locally or remotely
        url: url,
        chooserTitle: 'Choisissez : ' // Android only, you can override the default share sheet title
        //appPackageName: 'com.apple.social.facebook', // Android only, you can provide id of the App you want to share with
        //iPadCoordinates: '0,0,0,0' //IOS only iPadCoordinates for where the popover should be point.  Format with x,y,width,height

      };

      var onSuccess = function onSuccess(result) {
        console.log("Share completed? " + result.completed); // On Android apps mostly return false even while it's true

        console.log("Shared to app: " + result.app); // On Android result.app since plugin version 5.4.0 this is no longer empty. On iOS it's empty when sharing is cancelled (result.completed=false)
      };

      var onError = function onError(msg) {
        console.log("Sharing failed with message: " + msg);
      };

      window.plugins.socialsharing.shareWithOptions(socialShareOptions, onSuccess, onError);
    });
    setTimeout(function () {
      displayAscese();
      $('#page-ascese').removeClass('no-display');
    }, 2000);
  });
  $on('pageBeforeIn', function () {
    console.log("//255 ascese.f7 pageBeforeIn");
    displayAscese();
    $('.inviter-popup-link').on('click', function () {
      $f7.emit('displayInviterPopup');
    }); //console.log("//259 ascese.f7",$f7.popup.get('.paypal-popup'));
    //console.log("//260 ascese.f7 paypalPopup",paypalPopup);
  });
  $on('pageTabShow', function () {
    console.log("//256 ascese.f7 pageTabShow");
    displayAscese();
  });

  function displayAscese() {
    localforage.getItem('syncedContents', function (err, contents) {
      var contents_messages_societe_de_louange = contents.filter(function (d) {
        return d.id == "messages_societe_de_louange";
      });
      $('#messages_societe_de_louange').html(contents_messages_societe_de_louange[0].contenu);
    });
    profil = $store.getters.profil;
    var options = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric'
    }; // Inscrivez-vous

    if (profil.value.statut.length == 1) {
      $('#inscrivez-vous').show();
      $('#renseignez-votre-profil').hide();
    }

    if (profil.value.statut.length > 1) {
      $('#inscrivez-vous').hide();

      if (profil.value.userPseudonyme == undefined) {
        $('#renseignez-votre-profil').show();
        $('#partez-vers-jerusalem').hide();
      } else {
        $('#renseignez-votre-profil').hide();
        $('#partez-vers-jerusalem').show();
      }
    } // Partez vers Jérusalem


    if (profil.value.statut.length < 2) $('#partez-vers-jerusalem').hide(); //if(profil.value.statut.length == 2) $("#partez-vers-jerusalem").show();

    if (profil.value.statut.length > 3) $('#partez-vers-jerusalem').hide();
    var dateLastMeditationValidee = profil.value.meditationsValidees[profil.value.meditationsValidees.length - 1];
    console.log("//295 ascese.f7 dateLastMeditationValidee : ", dateLastMeditationValidee); // Statut

    console.log("//295 ascese.f7 profil.value.statut : ", profil.value.statut);
    current.eltsStatut = statuts.value[profil.value.statut.length - 1]; //console.log("//300 ascese.f7 current.eltsStatut : ",current.eltsStatut);

    var dateMs = current.frDateDernierStatut = new Date(parseDate(profil.value.statut[profil.value.statut.length - 1])).toLocaleDateString("fr-FR", options);
    console.log("//299 ascese.f7 current.frDateDernierStatut : ", current.frDateDernierStatut);

    if (new Date(profil.value.dateDepartISO) <= Date.now() && profil.value.statut.length == 4) {
      console.log("//304 ascese.f7 dateDepartISO / message", "Vous vous êtes engagé à partir le " + profil.value.dateDepartISO);
      var dialogDepart = $f7.dialog.create({
        animate: true,
        title: 'Départ vers Jérusalem !',
        text: 'Vous vous êtes engagé à partir pour Jérusalem le ' + new Date(profil.value.dateDepartISO).toLocaleDateString("fr-FR", options),
        buttons: [{
          text: '<i class="text-color-orange">J\'attends</i>'
        }, {
          text: '<span class="text-color-green">Je pars</span>',
          bold: true,
          onClick: function onClick() {
            confirmeDepart();
          }
        }]
      });
      dialogDepart.open();
    }

    function confirmeDepart() {
      console.log("//335 ascese.f7 DEPART de " + profil.value.userPseudonyme); // TODO On met à jour le profil de l'utilisateur et on emet un event

      profil.value.statut[4] = js_date("Y-m-d H:i:s"); //	Mise à 0 des km et donc de la localisation + Mise à 100 de la ferveur
      // VOIR avec le calcul ses sats
      // Mise à 0 des méditations validées

      profil.value.meditationsValidees = [];
      $store.dispatch('updateProfil', profil.value);
      $f7.emit('profilUpdated'); // Qui met à jour l'affichage de Ascese

      var notificationCallbackOnClose = $f7.notification.create({
        icon: '<i class="fas fa-hiking"></i>',
        title: 'Départ vers Jérusalem',
        titleRightText: 'maintenant',
        subtitle: 'Vous partez maintenant depuis Vézelay vers Jérusalem.',
        text: ' Les méditations seront reprises ' + 'à partir de la première. Votre ferveur est remise à 100 et votre kilométrage à 0. Il y a 4500 km à parcourir. Votre statut a été mis à jour.',
        cssClass: 'leSaviezVous',
        closeButton: true,
        on: {
          close: function close() {
            displayAscese();
          }
        }
      });
      notificationCallbackOnClose.open(); // TODO on envoie un message à l'escouade concernée signé par l'admin en mentionnant le départ de l'utilisateur
    }

    var dateDepart = "";
    if (profil.value.dateDepartISO) dateDepart = new Date(profil.value.dateDepartISO).toLocaleDateString("fr-FR", options);
    var statut = '' + '<a href="/statut/" class=" item-link item-content tab-link">' + '<div id="icone_statut" class="item-media" style="color:' + current.eltsStatut.couleur + '"><i class="fas fa-certificate"></i></div>' + '<div class="item-inner">' + '<div class="item-title">' + '<div class="item-header">Votre statut depuis le ' + current.frDateDernierStatut + '</div>' + current.eltsStatut.appellation;
    if (profil.value.statut.length == 4) statut += '<div class="item-footer text-color-orange">le ' + dateDepart + '</div>';
    if (profil.value.statut.length == 5) statut += '<div class="item-footer text-color-green">Depuis le ' + profil.value.statut[4] + '</div>';
    statut += '<div class="item-footer">' + current.eltsStatut.refBiblique + '</div>' + '</div>' + '<div class="item-after"></div>' + '</div>' + '</a>';
    $('#statut').html(statut); // Meditation

    current.meditation_statut = 'A faire';
    current.meditation_numero = 1;

    if (js_date('Y-m-d') == profil.value.meditationsValidees[profil.value.meditationsValidees.length - 1]) {
      current.meditation_numero = profil.value.meditationsValidees.length; // les meditation_numero commencent à 1 pas à 0
      //current.meditation_statut = 'Faite à '+profil.value.meditationsValidees[profil.value.meditationsValidees.length -1];
      //TODO rechercher l'heure dans validatedActivities

      var r = profil.value.validatedActivities.filter(function (d) {
        return d.type == "meditation" && d.activite == current.meditation_numero;
      });
      current.heure_validation_meditation = js_date("h:i", parseDate(r[0].dateYmdHi) / 1000);
      console.log("//373 ascese.f7 heure_validation_meditation", current.heure_validation_meditation);
      current.meditation_statut_color = 'text-color-green'; // 

      console.log("//376 ascese.f7 current.meditation_numero", current.meditation_numero);
      console.log("//378 ascese.f7 current.meditation_statut", current.meditation_statut);
      console.log("//379 ascese.f7 current.meditation_statut_color", current.meditation_statut_color);
      current.meditation_statut = "Faite à " + current.heure_validation_meditation; //$('#meditation_statut').html(current.meditation_statut);

      $('#meditation_statut').addClass(current.meditation_statut_color);
    } else {
      current.meditation_numero = profil.value.meditationsValidees.length + 1;
      current.meditation_statut = 'A faire pour aujourd\'hui.';
      current.meditation_statut_color = 'text-color-red';
      $('#meditation_statut').addClass(current.meditation_statut_color);
    }

    $('#meditation_numero').html(current.meditation_numero);
    $('#meditation_statut').html(current.meditation_statut);
    console.log("//413 ascese.f7 current.meditation_statut", current.meditation_statut);
    console.log("//414 ascese.f7 $('#meditation_statut')", $('#meditation_statut'));
    console.log("//415 ascese.f7 current.url_meditation ", $('#url_meditation'));
    $('#url_meditation').attr('href', '/meditation/' + current.meditation_numero + '/'); // Bilan

    current.bilan_statut = 'A faire pour aujourd\'hui';
    $('#bilan_todo').html(current.bilan_statut);
    $('#bilan_statut').html(current.bilan_statut);
    if (profil.value.bilans) var todayBilan = profil.value.bilans.filter(function (d) {
      return d.dateISO == js_date("Y-m-d");
    })[0];

    if (todayBilan) {
      current.bilan_statut = 'Mis à jour à ' + js_date('H:i', todayBilan.dateTS);
      $('#bilan_todo').html("Fait");
      $('#bilan_todo').removeClass('text-color-red');
      $('#bilan_todo').addClass('text-color-green'); //TODO ici mettre "à mettre à jour" si les validatedActivites sont plus récentes que le bilan
    }

    $('#bilan_statut').html(current.bilan_statut); // Candidatures

    if (profil.value.statut.length < 2) $('#adhesion').hide();
    if (profil.value.candidatures.length == 0) $('#adhesion').hide(); //if(profil.value.statut.length == 2) $('#adhesion').show();

    console.log("//426 ascese.f7 profil.value.statut.length profil.value.candidatures.length", profil.value.statut.length + " " + profil.value.candidatures.length);

    if (profil.value.statut.length == 3 && profil.value.candidatures.length > 0) {
      $('#adhesion').show();
      var adhesion = '';
      console.log("//318 ascese.f7 profil.value.candidatures :", profil.value.candidatures);
      localforage.getItem('syncedEscouades', function (err, syncedEscouades) {
        for (var i = 0; i < profil.value.candidatures.length; i++) {
          var checkValidated = function checkValidated(value) {
            return value == profil.value.userPseudonyme;
          };

          var myFunction = function myFunction() {
            document.getElementById("demo").innerHTML = ages.find(checkAdult);
          };

          var cetteEscouade = syncedEscouades.filter(function (d) {
            return d.nom == profil.value.candidatures[i];
          })[0];
          console.log("//385 ascese.f7 candidatures cetteEscouade : ", cetteEscouade);
          var validated = 'En attente';
          var couleurValidated = 'text-color-red';

          if (cetteEscouade.candidatsValidated.find(checkValidated)) {
            var validated = 'Approuvée';
            var couleurValidated = 'text-color-orange';
          }

          console.log("//326 ascese.f7 candidatures validated : ", validated);
          adhesion += '' + '<a href="/escouade/' + profil.value.candidatures[i] + '/" class=" item-link item-content tab-link">' + '<div class="item-media"><i class="fas fa-file-contract"></i></div>' + '<div class="item-inner">' + '<div class="item-title">' + '<div class="item-header"> le ' + new Date(profil.value.statut[2]).toLocaleDateString("fr-FR", options) + '</div>Demande d\'adhésion<div class="item-footer">Escouade ' + '<span id="escouade_nom">' + profil.value.candidatures[i] + '</span></div>' + '</div>' + '<div class="item-after ' + couleurValidated + '">' + validated + '</div>' + '</div>' + '</a>';
        }

        $('#adhesion').html(adhesion);
        $('#adhesion').show();
      }); // LF getItem('syncedEscouades'
    }

    if (profil.value.statut.length > 3) $('#adhesion').hide(); //if (profil.value.statut[2]) current.frDateDemandeAdhesion = new Date(profil.value.statut[2]).toLocaleDateString("fr-FR", options);
    // Escouade

    console.log("//421 ascese.f7 profil.value.escouade : ", profil.value.escouade);

    if (profil.value.escouade) {
      console.log("//423 ascese.f7 profil.value.escouade : ", profil.value.escouade);
      current.frDateAcceptationEscouade = new Date(profil.value.statut[3]).toLocaleDateString("fr-FR", options);
      localforage.getItem('syncedEscouades', function (err, syncedEscouades) {
        var cetteEscouade = syncedEscouades.filter(function (d) {
          return d.nom == profil.value.escouade.nom;
        })[0];
        $f7.store.dispatch('updateMonEscouade', cetteEscouade);
        console.log("//428 ascese.f7 candidatures cetteEscouade : ", cetteEscouade);
        current.escouadeDateDepartISO = cetteEscouade.dateDepartISO;
        current.escouade = cetteEscouade;
        current.escouade_id = cetteEscouade.id;
        var optionsSansHoraire = options;
        delete optionsSansHoraire.hour;
        delete optionsSansHoraire.minute;
        current.escouadeDateDepartISO = new Date(current.escouadeDateDepartISO).toLocaleDateString("fr-FR", optionsSansHoraire);
        var escouade = '' + '<li>' + '<a href="/escouade/' + current.escouade.nom + '/" class="item-link item-content tab-link bg-color-lime ">' + '<div class="item-media "><i class="fas fa-users"></i></div>' + '<div class="item-inner">' + '<div class="item-title ">' + '<div class="item-header">Adhésion le ' + current.frDateAcceptationEscouade + '</div>' + 'Escouade ' + current.escouade.nom + '</span>' + '<div class="item-footer">Départ le ' + current.escouadeDateDepartISO + '</div>' + '</div>' + '<div class="item-after"></div>' + '</div>' + '</a>' + '</li>';
        $('#escouade').html(escouade);
        $('#escouade').show();
      }); // LF getItem('syncedEscouades'
    } else $('#escouade').hide(); //$('#escouadeDateDepart').html(current.escouadeDateDepart);
    //$('#escouade').html(current.escouade);
    //$('#frDateAcceptationEscouade').html(current.frDateAcceptationEscouade);
    // Pélerinage seul


    if (profil.value.statut.length < 4) $('#pelerinage-seul').hide();
    if (profil.value.statut.length >= 4) $('#pelerinage-seul').show();
    if (profil.value.escouade) $('#pelerinage-seul').hide();
    $('#date_depart_seul').html(new Date(profil.value.dateDepartISO).toLocaleDateString("fr-FR", options)); // Km

    current.todayKm = 0;
    current.totalKm = 0;
    current.moyenneKm = 0;
    var todayYmd = js_date("Ymd");

    for (var i = 0; i < profil.value.stats.length; i++) {
      if (profil.value.stats[i].km) current.totalKm += Number(profil.value.stats[i].km);

      if (profil.value.stats[i].dateYmd == todayYmd) {
        if (!profil.value.stats[i].km) current.todayKm = 0;else current.todayKm = profil.value.stats[i].km;
        current.moyenneKm = (current.totalKm / (i + 1)).toFixed(1);
        break;
      }
    }

    $('#totalKm').html(current.totalKm);
    $('#todayKm').html(current.todayKm);
    $('#moyenneKm').html(current.moyenneKm);
    current.jusqueJerusalem = 4500 - current.totalKm;
    current.jusqueJerusalem = 'Il vous reste ' + current.jusqueJerusalem + ' km jusqu\'à Jérusalem.';
    $('#jusqueJerusalem').html(current.jusqueJerusalem); // Position

    localforage.getItem('syncedJSONContents').then(function (syncedJSONContents) {
      var VezelayToJerusalem = JSON.parse(syncedJSONContents.filter(function (d) {
        return d.id == "Vezelay-Jerusalem";
      })[0].contenu).contents;
      console.log("//603 ascese.f7 VezelayToJerusalem : ", VezelayToJerusalem);
      var positionPrecedente = VezelayToJerusalem.filter(function (d) {
        return d.km < current.totalKm;
      });
      positionPrecedente = positionPrecedente[positionPrecedente.length - 1];
      var positionExacte = VezelayToJerusalem.filter(function (d) {
        return d.km == current.totalKm;
      })[0];
      var positionSuivante = VezelayToJerusalem.filter(function (d) {
        return d.km > current.totalKm;
      })[0];
      console.log("//510 ascese.f7 positionPrecedente : ", positionPrecedente);
      console.log("//511 ascese.f7 positionExacte : ", positionExacte);
      console.log("//512 ascese.f7 positionSuivante : ", positionSuivante);
      if (positionExacte) current.position = positionExacte.lieu;else current.position = positionPrecedente.lieu;
      $('#position').html(current.position); //current.positionLocaliteSuivante = positionSuivante.lieu;

      $('#positionLocaliteSuivante').html(positionSuivante.lieu);

      if (positionPrecedente) {
        current.aKmDeLocalitePrecedente = (current.totalKm - positionPrecedente.km).toFixed(1);
        if (positionExacte) current.aKmDeLocalitePrecedente = "A :";else current.aKmDeLocalitePrecedente = current.aKmDeLocalitePrecedente + " km depuis :";
        $('#aKmDeLocalitePrecedente').html(current.aKmDeLocalitePrecedente);
      }

      current.kmToLocaliteSuivante = (positionSuivante.km - current.totalKm).toFixed(1);
      $('#kmToLocaliteSuivante').html(current.kmToLocaliteSuivante);
      var maspUrl = current.position.replace(/, /gi, "+");
      current.maspUrl = maspUrl;
      console.log("//362 ascese.f7 map href", $('#map').prop('href'));
      $('#map').prop('href', 'https://www.google.fr/maps/place/' + current.position);
    }).catch(function (err) {
      // This code runs if there were any errors
      console.log(err);
    });
  } // fin displayAscese


  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
  <div class="page" data-name="ascese" data-reload-current="true">
    <!-- Top Navbar -->
    <div class="navbar navbar-large">
      <div class="navbar-bg"></div>
      <div class="navbar-inner">
        <div class="left">
          <a href="#" class="link icon-only panel-open" data-panel=".left-ascese">
            <i class="fas fa-bars"></i>
          </a>
        </div>
        <div class="title ">Ierusalem !</div>
        <div class="right">
			<a href="#inv" class="tab-link icon-only inviter-popup-link">
				<i class="fas fa-user-plus"></i> 
			</a>
			<a href="/messages/" class="tab-link icon-only">
				<i class="icon material-icons">mail_outline<span class="badge color-red newMessages" id="newMessages">3</span></i> 
			</a>
        </div>
        <div class="title-large">
          <div class="title-large-text">Societas laudis</div>
        </div>
      </div>
    </div>


	<div class="panel panel-left left-ascese panel-reveal panel-resizable panel-init">
      <div class="page">
		<div class="navbar">
			<div class="navbar-bg"></div>
			<div class="navbar-inner">
				<div class="title">Ierusalem !</div>
			</div>
		</div>
		<div class="page-content">
			<div class="block">
				<a href="/contenu/Objectif/ascese_objectifs/" class="col button button-raised button-fill panel-close">
					Objectif
				</a>
				<p></p>
				<a href="/contenu/Directoire/ascese_directoire/" class="col button button-raised button-fill panel-close">
					Directoire
				</a>
				<p></p>
				<a href="/contenu/Pratiques/ascese_pratiques/" class="col button button-raised button-fill panel-close">
					Pratiques
				</a>
				<p></p>
				<a href="/contenu/FAQ/ascese_FAQ/" class="col button button-raised button-fill panel-close">
					Questions fréquentes
				</a>
				<p></p>
				<a href="/contenu/Liturgie/liturgie_liturgie/" class="col button button-raised button-fill panel-close">
					Liturgie ?
				</a>
				<p></p>
				<a href="/contenu/Aidez-nous/messages_contributions/" class="col button button-raised button-fill panel-close">
					Coup de main !
				</a>
				<p></p>
				<a href="/contenu/Confidentialité/politique_de_confidentialite/" class="col button button-raised button-fill panel-close">
					Confidentialité
				</a>
				
			</div>
		</div>
          </div>
    </div>
    
    
    <!-- Scrollable page content-->
    <div id="page-ascese" class="page-content no-display">
		
			<!-- Inscrivez vous -->
			<div id="inscrivez-vous" class="block">
			Pour commencer votre pélerinage vers Jérusalem, il est nécessaire d'être connecté à l'application et de renseigner votre profil.
			<a href="#view-profil" class=" col button button-raised button-fill item-link item-content tab-link profil tab-link-active">
			Inscrivez vous !</a>
			</div>
			
			<!-- Renseignez votre profil -->
			<div id="renseignez-votre-profil" class="block">
			Pour commencer votre pélerinage vers Jérusalem, il est nécessaire de compléter votre profil avec votre pseudonyme.
			<a href="#view-profil" class=" col button button-raised button-fill item-link item-content tab-link profil tab-link-active">
			Renseignez votre profil !</a>
			</div>
			
			<!-- partez vers Jérusalem -->
			<div id="partez-vers-jerusalem" class="block">
				Vous pouvez maintenant vous inscrire pour partir en pélerinage vers Jérusalem.
			<a href="/engagement/" class=" col button button-raised button-fill item-link item-content tab-link engagement tab-link-active">
			Partez vers Jérusalem !</a>
			</div>
			
			<div class="list">
				<ul>
					<!-- Méditation -->
					<li>
					<a href="/meditation/2/" id="url_meditation" class=" item-link item-content tab-link">
						<div class="item-media"><i class="fas fa-praying-hands"></i></div>
						<div class="item-inner">
						<div class="item-title">
							<div class="item-header">Méditation quotidienne</div>
								Méditation n°<span id="meditation_numero">[meditation_numero]</span>
								<div id="meditation_statut" class="item-footer">[meditation_statut]</div>
						</div>
						<div class="item-after">Voir</div>
						</div>
					</a>
					</li>
					<!-- Bilan quotidien -->
					<li>
						<a href="/bilan/" class=" item-link item-content tab-link">
							<div class="item-media"><i class="fas fa-tasks"></i></div>
							<div class="item-inner">
							<div class="item-title">
								<div class="item-header">Bilan quotidien</div><span id="bilan_statut">[bilan_statut]</span>
								<div class="item-footer"><span class="text-color-red" id="bilan_todo">[bilan_todo]</span></div>
							</div>
							<div class="item-after">Voir</div>
							</div>
						</a>
					</li>
					<!-- statut -->
					<li id="statut">
						<a href="/statut/"  class="item-link item-content tab-link">
							<div id="icone_statut" class="item-media" style="color:"><i class="fas fa-certificate"></i></div>
							<div class="item-inner">
								<div class="item-title">
									<div class="item-header">Votre statut depuis le <span id="frDateDernierStatut"></span></div>
									<span id="asceseStatut">[STATUT]</span>
									<div class="item-footer"></div>
								</div>
								<div class="item-after"></div>
							</div>
						</a>
					</li>
					<!-- demande d'adhésion -->
					<li id="adhesion">
						<a href="#view-escouade" class=" item-link item-content tab-link">
							<div class="item-media"><i class="fas fa-file-contract"></i></div>
							<div class="item-inner">
							<div class="item-title">
								<div class="item-header"> le <span id="frDateDemandeAdhesion">[frDateDemandeAdhesion]</span></div>Demande d'adhésion<div class="item-footer">Escouade <span id="escouade_nom">[escouade_nom]</span></div>
							</div>
							<div class="item-after"></div>
							</div>
						</a>
					</li>
					<!-- escouade -->
					<li id="escouade">
						<a href="/escouade/${current.escouade_id}/" class="item-link item-content tab-link bg-color-lime ">
							
							<div class="item-media "><i class="fas fa-users"></i></div>
							<div class="item-inner">
							<div class="item-title ">
								<div class="item-header">Adhésion acceptée le <span id="frDateAcceptationEscouade">[frDateAcceptationEscouade]</span></div>
								Escouade <span id="escouade_nom">[escouade_nom]</span>
								<div class="item-footer">Départ le <span id="escouadeDateDepart">[escouadeDateDepart]</span></div>
							</div>
							<div class="item-after"></div>
							</div>
						</a>
					</li>
					<!-- Pélerinage seul. -->
					<li id="pelerinage-seul">
						<div class="item-content bg-color-lime">
							<div class="item-media "><i class="fas fa-user-shield"></i></div>
							<div class="item-inner">
							<div class="item-title ">
								<div class="item-header"></div>Pélerinage seul.<div class="item-footer">Départ le <span id="date_depart_seul">[date_depart_seul]</span></div>
							</div>
							<div class="item-after"></div>
							</div>
						</div>
					</li>
					<!-- Distance parcourue aujourd'hui -->
					<li>
					<a href="/progression/" class=" item-link item-content tab-link">
						<div class="item-media"><i class="fas fa-route"></i></div>
						<div class="item-inner">
						<div class="item-title">
							<div class="item-header">Distance parcourue aujourd'hui</div> <span id="todayKm">[todayKm]</span> km
								<div class="item-footer">Moyenne quotidienne : <span id="moyenneKm">[moyenneKm]</span> km.</div>
						</div>
						<div class="item-after"></div>
						</div>
					</a>
					</li>
					<!-- Distance totale parcourue -->
					<li>
					<a href="/progression/" class=" item-link item-content tab-link">
						<div class="item-media"><i class="fas fa-road"></i></div>
						<div class="item-inner">
						<div class="item-title">
							<div class="item-header">Distance totale parcourue</div><span id="totalKm">[totalKm]</span> km.
							<div class="item-footer"><span id="jusqueJerusalem">[jusqueJerusalem]</span></div>
						</div>
						<div class="item-after"></div>
						</div>
					</a>
					</li>
					<!-- Position -->
					<li>
						<a id="map" href="https://www.google.fr/maps/place/${current.maspUrl}/" class="item-link item-content link external">
							<div class="item-media"><i class="fas fa-map-marked-alt"></i></div>
							<div class="item-inner">
							<div class="item-title">
								<div class="item-header">Position actuelle : <span id="aKmDeLocalitePrecedente"></span> </div> <span id="position">[position]</span>
								<div class="item-footer">Encore <span id="kmToLocaliteSuivante">[kmToLocaliteSuivante]</span> km pour 
									<span id="positionLocaliteSuivante">[positionLocaliteSuivante]</span></div>
							</div>
							<div class="item-after"></div>
							</div>
						</a>
					</li>
					
				</ul>
			</div> <!-- list -->
    </div> <!-- page-content -->
  </div>
`
    }
    ;
}

framework7Component.id = '73d8581d61';
export default framework7Component;