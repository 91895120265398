/** @jsx $jsx */
import { $jsx } from 'framework7';
import js_date from '../js/utils.js/date.js';
import js_mktime from '../js/utils.js/mktime.js';

function parseDate(date) {
  var parsed = Date.parse(date);

  if (!isNaN(parsed)) {
    return parsed;
  }

  return Date.parse(date.replace(/-/g, '/').replace(/[a-z]+/gi, ' '));
}

function framework7Component(props, _ref) {
  var $ = _ref.$,
      $on = _ref.$on,
      $f7route = _ref.$f7route,
      $store = _ref.$store;
  var profil = $store.getters.profil;
  $on('pageAfterin', function (e, page) {
    console.log("//137 statut.f7 $on('pageAfterin',");
    displayStatut();
    $('.inviter-popup-link').on('click', function () {
      $f7.emit('displayInviterPopup');
    });
  });

  function displayStatut() {
    var descriptionStatuts = [{
      id: 'invite',
      couleur: '#2b170a',
      titre: 'Inscrivez-vous et connectez-vous sur l\'application pour pouvoir partir vers Jérusalem.',
      texte: 'Le royaume des cieux est semblable à un roi qui fit faire les noces de son fils.' + ' Et il envoya ses serviteurs appeler ceux qui étaient invités aux noces.' + ' Dites aux invités : J\’ai préparé mon festin, mes boeufs et mes animaux engraissés sont tués ;' + ' tout est prêt, venez aux noces.',
      refBiblique: '<q>Evangile de S. Matthieu</q>, 22,2.4'
    }, {
      id: 'visiteur',
      couleur: '#555555',
      titre: 'Vous vous êtes inscrit et connecté, mais vous n\'êtes pas encore volontaire pour partir vers Jérusalem.',
      texte: 'Tobie allait visiter presque tous les jours tous ceux de sa parenté, les consolait,' + ' et distribuait de son bien à chacun d’eux selon son pouvoir.',
      refBiblique: '<q>Livre de Tobie</q>, 1,19'
    }, {
      id: 'etranger',
      couleur: '#999999',
      titre: 'Vous vous êtes porté volontaire pour partir vers Jérusalem.',
      texte: 'Tous ceux qui sont morts dans la foi, sans avoir reçu les choses promises, mais les ont vues vues de loin,' + ' crues, et embrassées, ont fait profession d\'être étrangers et de passage sur la terre.' + ' Car ceux qui parlent ainsi, montrent clairement qu\'ils cherchent une patrie.' + ' En effet, s\'ils se fussent souvenus de celle d\'où ils étaient sortis, ils auraient eu le temps d\'y retourner;' + ' Mais maintenant ils en désirent une meilleure, c\'est-à-dire une céleste.',
      refBiblique: '<q>Lettre aux Hébreux</q>, 11,13-16'
    }, {
      id: 'enPartance',
      couleur: '#933d04',
      titre: 'Vous êtes sur le point de partir vers Jérusalem.',
      refBiblique: '<q>Livre du Siracide</q>, 8,15',
      texte: 'Ne te mets pas en route avec un aventurier, de peur qu\'il ne s\'impose à toi' + ' car il n\'en fait qu\'à sa tête et sa folie te perdra avec lui.'
    }, {
      id: 'enChemin',
      couleur: '#360944',
      titre: 'Vous êtes en chemin vers Jérusalem.',
      texte: 'Nous partîmes donc du bord du fleuve Ahava, le douzième jour du premier mois, pour aller à Jérusalem ;' + ' et la main de notre Dieu fut sur nous, et Il nous délivra des mains de l’ennemi,' + ' et de toute embûche pendant le voyage.',
      refBiblique: '<q>Livre d\'Esdras</q> 8,31'
    }, {
      id: 'arrive',
      couleur: '#c07106',
      titre: 'Faites votre retraite de 5 jours pour obtenir le statut de garde.',
      texte: 'Maintenant notre marche prend fin devant tes portes, Jérusalem !' + ' Jérusalem, te voici dans tes murs : ville où tout ensemble ne fait qu\'un!' + ' C\'est là que montent les tribus, les tribus du Seigneur, là qu\'Israël doit rendre grâce au nom du Seigneur.' + ' C\'est là le siège du droit,  le siège de la maison de David.' + ' Appelez le bonheur sur Jérusalem : « Paix à ceux qui t\'aiment !' + ' Que la paix règne dans tes murs, le bonheur dans tes palais ! »' + ' A cause de mes frères et de mes proches, je dirai : « Paix sur toi ! »' + ' A cause de la maison du Seigneur notre Dieu, je désire ton bien.',
      refBiblique: '<q>Livre des psaumes</q>, 121'
    }, {
      id: 'garde',
      couleur: '#da9405',
      titre: 'Vous êtes maintenant Garde ! Pensez à fonder une escouade pour emmener des compagnons avec vous vers Jérusalem.',
      texte: 'Mais Joseph, Son époux, étant un homme juste, et ne voulant pas la diffamer,' + ' résolut de La renvoyer secrètement. Et comme il y pensait, voici qu’un Ange du Seigneur' + ' lui apparut en songe, disant : Joseph, fils de David, ne crains pas de garder Marie,' + ' ton épouse ; car ce qui est né en Elle vient du Saint-Esprit. Elle enfantera un fils,' + ' et tu Lui donneras le nom de Jésus ; car Il sauvera Son peuple de ses péchés.' + ' Or tout cela arriva pour que s’accomplît ce que le Seigneur avait dit par le prophète ' + ' en ces termes : Voici, la Vierge concevra, et Elle enfantera un Fils, et on Lui donnera le nom d’Emmanuel ;' + ' ce qui signifie : Dieu avec nous. Joseph, réveillé de son sommeil, fit ce que l’Ange du Seigneur ' + ' lui avait ordonné, et prit son épouse avec lui. Et il ne l’avait point connue quand elle enfanta ' + ' Son Fils premier-né, auquel il donna le nom de Jésus.',
      refBiblique: '<q>Evangile de S. Matthieu</q>, 1,19-25'
    }];
    var ceStatut = descriptionStatuts[profil.value.statut.length - 1];
    console.log("//209 statut.f7 ceStatut : ", ceStatut);
    var displayStatut = '' + '<div class="card card-outline" ">' + '<div class="card-header" style="background:' + ceStatut.couleur + '; color:white;">' + ceStatut.titre + '</div>' + '<div class="card-content card-content-padding contentJustify">' + ceStatut.texte + '</div>' + '<div class="card-footer">' + ceStatut.refBiblique + '</div>' + '</div>'; //console.log("//229 statut.f7 date ",js_date('j/n/Y H:i',Date.parse(profil.value.statut[0])/1000));

    $('#descriptionStatut').html(displayStatut); //console.log("//244 statut.f7 parseDate(profil.value.statut[0]) : ",parseDate(profil.value.statut[0]));

    $('#dateStatut0').html(js_date('j/n/Y H:i', parseDate(profil.value.statut[0]) / 1000));
    console.log("//243 statut.f7 profil.value.statut : ", profil.value.statut);

    if (profil.value.statut[1]) {
      $('#dateStatut1').html(js_date('j/n/Y H:i', parseDate(profil.value.statut[1]) / 1000));
      $('#dateStatut1').removeClass('text-color-red');
      $('#dateStatut1').addClass('text-color-green');
      document.getElementById("dateStatut1").scrollIntoView();
    }

    if (profil.value.statut[2]) {
      $('#dateStatut2').html(js_date('j/n/Y H:i', parseDate(profil.value.statut[2]) / 1000));
      $('#dateStatut2').removeClass('text-color-red');
      $('#dateStatut2').addClass('text-color-green');
      document.getElementById("dateStatut2").scrollIntoView();
    }

    if (profil.value.statut[3]) {
      $('#dateStatut3').html(js_date('j/n/Y H:i', parseDate(profil.value.statut[3]) / 1000));
      $('#dateStatut3').removeClass('text-color-red');
      $('#dateStatut3').addClass('text-color-green');
      document.getElementById("dateStatut3").scrollIntoView();
    }

    if (profil.value.statut[4]) {
      $('#dateStatut4').html(js_date('j/n/Y H:i', parseDate(profil.value.statut[4]) / 1000));
      $('#dateStatut4').removeClass('text-color-red');
      $('#dateStatut4').addClass('text-color-green');
      document.getElementById("dateStatut4").scrollIntoView();
    }

    if (profil.value.statut[5]) {
      $('#dateStatut5').html(js_date('j/n/Y H:i', parseDate(profil.value.statut[5]) / 1000));
      $('#dateStatut5').removeClass('text-color-red');
      $('#dateStatut5').addClass('text-color-green');
      document.getElementById("dateStatut5").scrollIntoView();
    }

    if (profil.value.statut[6]) {
      $('#dateStatut6').html(js_date('j/n/Y H:i', parseDate(profil.value.statut[6]) / 1000));
      $('#dateStatut6').removeClass('text-color-red');
      $('#dateStatut6').addClass('text-color-green');
      document.getElementById("dateStatut6").scrollIntoView();
    }
  }

  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
  <div class="page" data-name="statut">
    <div class="navbar">
			<div class="navbar-bg"></div>
				<div class="navbar-inner">
					<div class="left">
						<a href="#" class="link back">
							<i class="icon icon-back"></i>
							<span class="if-not-md">Retour</span>
						</a>
					</div>
					
					<div class="title">Votre statut</div>
					<div class="right">
				<a href="#inv" class="tab-link icon-only inviter-popup-link">
				<i class="fas fa-user-plus"></i> 
			</a>
				<a href="/messages/" class="tab-link icon-only">
					<i class="icon material-icons">mail_outline<span class="badge color-red newMessages" id="newMessages">3</span></i> 
				</a>
					</div>
				
				</div>
    </div>
		<!-- navbar -->
		<div class="page-content">
			<div id="descriptionStatut">
			
			</div>
			<div class="block">
				<div class="list media-list" style="overflow-y: scroll; height:250px;">
					<ul>
						<li style=" max-height: max-content;">
							<div class="item-content">
							<div class="item-inner">
								<div class="item-title-row">
									<div class="item-title">Invité</div>
									<div id="dateStatut0" class="item-after text-color-green">Prochainement</div>
								</div>
									<div class="item-subtitle">Vous êtes présent</div>
									<div class="item-text"> mais vous n'êtes pas encore inscrit ou connecté.</div>
							</div>
							</div>
						</li>
						<li style=" max-height: max-content;">
							<div class="item-content">
							<div class="item-inner">
								<div class="item-title-row">
									<div class="item-title">Visiteur</div>
									<div id="dateStatut1" class="item-after text-color-red">Prochainement</div>
								</div>
									<div class="item-subtitle">Vous êtes inscrit</div>
									<div class="item-text"> mais vous n'êtes pas encore volontaire pour partir vers Jérusalem.</div>
							</div>
							</div>
						</li>
						<li style=" max-height: max-content;">
							<div class=" item-content">
							<div class="item-inner">
								<div class="item-title-row">
									<div class="item-title">Etranger et de passage</div>
									<div id="dateStatut2" class="item-after text-color-red">Prochainement</div>
								</div>
									<div class="item-subtitle">Vous vous êtes porté volontaire</div>
									<div class="item-text"> pour partir et rejoindre une escouade, 
									mais votre demande d'adhésion n'a pas encore été approuvée.</div>
							</div>
							</div>	
						</li style=" max-height: max-content;">
						<li style=" max-height: max-content;">
							<div class=" item-content">
							<div class="item-inner">
								<div class="item-title-row">
									<div class="item-title">En partance</div>
									<div id="dateStatut3" class="item-after text-color-red">Prochainement</div>
								</div>
									<div class="item-subtitle">Vous avez été accepté dans une escouade,</div>
									<div class="item-text" > ou alors vous vous
									êtes inscrit pour partir seul.</div>
							</div>
							</div>	
						</li>
						<li style=" max-height: max-content;">
							<div class=" item-content">
							<div class="item-inner">
								<div class="item-title-row">
									<div class="item-title">En chemin (aspirant-garde)</div>
									<div id="dateStatut4" class="item-after text-color-red">Prochainement</div>
								</div>
									<div class="item-subtitle">Vous êtes parti vers Jérusalem</div>
									<div class="item-text">et vous poursuivez votre chemin.</div>
							</div>
							</div>	
						</li>
						<li style=" max-height: max-content;">
							<div class=" item-content">
							<div class="item-inner">
								<div class="item-title-row">
									<div class="item-title">Arrivé (aspirant-garde)</div>
									<div id="dateStatut5" class="item-after text-color-red">Prochainement</div>
								</div>
									<div class="item-subtitle">Vous avez achevé votre pélerinage</div>
									<div class="item-text"> et atteint la ville sainte.</div>
							</div>
							</div>	
						</li>
						<li style=" max-height: max-content;">
							<div class=" item-content">
							<div class="item-inner">
								<div class="item-title-row">
									<div class="item-title">Garde</div>
									<div id="dateStatut6" class="item-after text-color-red">Prochainement</div>
								</div>
									<div class="item-subtitle">Vous avez suivi votre retraite,</div>
									<div class="item-text"> et terminé votre pélerinage.</div>
							</div>
							</div>	
						</li>
					</ul>
				</div>
				<!-- list media-list -->
			</div>
			<!-- block -->
	</div> <!-- page content -->
	<!-- page-content -->	
  </div>
`
    }
    ;
}

framework7Component.id = '874f68b4e1';
export default framework7Component;