/** @jsx $jsx */
import { $jsx } from 'framework7';
import js_date from '../js/utils.js/date.js';

function ferveurLevel(ferveur) {
  var ferveurLevel = 6;
  if (ferveur < 125) ferveurLevel = 5;
  if (ferveur < 100) ferveurLevel = 4;
  if (ferveur < 75) ferveurLevel = 3;
  if (ferveur < 50) ferveurLevel = 2;
  if (ferveur < 25) ferveurLevel = 1;
  if (ferveur == 0) ferveurLevel = 0;
  var stars = "";

  for (var i = 0; i < ferveurLevel; i++) {
    stars += ' &#9734; ';
  } //return stars;


  return decodeHTML(stars);
}

var decodeHTML = function decodeHTML(html) {
  var txt = document.createElement('textarea');
  txt.innerHTML = html;
  return txt.value;
};

function framework7Component(props, _ref) {
  var $ = _ref.$,
      $on = _ref.$on,
      $store = _ref.$store;
  var contents;
  var current = {};
  var utilisateurs = $store.getters.utilisateurs;
  var cesUtilisateurs = utilisateurs.value;
  var profil = $store.getters.profil;
  var statuts = $store.getters.statuts;
  var escouades = $store.getters.escouades;
  var cetteEscouade; // pageAfterin

  $on('pageAfterin', function (e, page) {
    console.log("//159 messages.f7 $on('pageAfterin',");
    display();
  }); // $on('pageBeforein'

  function display() {
    var content = [];
    content["invitatoire"] = "Invitatoire.", content["laudes"] = "Office des Laudes du matin.";
    content["tierce"] = "Office de Tierce.";
    content["messe"] = "Messe.";
    content["sexte"] = "Office de Sexte.";
    content["none"] = "Office de None.";
    content["vepres"] = "Office de Vêpres.";
    content["complies"] = "Office des Complies.";
    content["martyrologe"] = "Martyrologe du jour.";
    content["commentaire"] = "Commentaire de l'Evangile.";
    content["regula"] = "Extrait de la Règle de S. Benoît du jour et son commentaire.";
    content["compendium"] = "Extrait du Compendium du CEC du jour.";
    content["Anima_Christi"] = "<i>Anima Christi</i> - Ame du Christ.";
    content["signum_crucis"] = "<i>Signum Crucis</i> - Signe de Croix.";
    content["pater"] = "<i>Pater noster</i> - Notre Père";
    content["ave_maria"] = "<i>Ave Maria</i> - Je vous salue Marie";
    content["gloria_patri"] = "Doxologie";
    content["angelus"] = "Prière de l'Angelus";
    content["benedictio_mensae"] = "<i>Benedicite</i> - Bénédiction de la table";
    content["rosario"] = "Rosaire médité";
    content["rosaire"] = "Rosaire médité";
    content["VENI_Sancte_Spiritus"] = "<i>Veni Sancte Spiritus.</i> - Viens Esprit Saint; avant toute occasion importante.";
    content["salve_regina"] = "<i>Salve Regina</i> - Salut ô Reine";
    content["alma_redemptoris_mater"] = "<i>Alma Redemptoris Mater</i> - Mère du Rédempteur";
    content["Ave_Regina_Caelorum_et_vers"] = "<i>Ave Regina Caelorum</i> - Salut; Reine des Cieux";
    content["Regina_Caeli_et_vers"] = "<i>Regina caeli</i> - Reine du ciel";
    content["Sub_tuum"] = "<i>Sub tuum</i> - Sous l'abri";
    content["memorare"] = "<i>Memorare</i> - Souvenez-vous";
    content["Oratio_ad_Sanctum_Michael"] = "Prière à S. Michel";
    content["angele_dei"] = "Prière à l'ange gardien";
    content["Priere_S_Joseph_S_Francois_de_Sales"] = "Prière à S. Joseph de S. François de Sales";
    content["Oratio_pro_summo_Pontifice"] = "Prière pour le souverain Pontife";
    content["Oratio_S_Thomae_Aquinatis_ante_studium"] = "Prière de S. Thomas avant l'étude.";
    content["Oratio_ante_colligationem"] = "Prière avant de se connecter à Internet.";
    content["Actus_Contritionis"] = "Acte de Contrition"; // Mise à zéro du badge nouveaux messages

    localStorage.nouveauxMessages = 0;
    $('.newMessages').html(localStorage.nouveauxMessages);
    $('.newMessages').hide();
    localforage.getItem('syncedJSONMessages', function (err, syncedJSONMessages) {
      console.log("//208 messages.f7 profil.value.escouade :", profil.value.escouade); // affichage des messages "Laus perennis"
      // dernier message Laus perennis 

      if (syncedJSONMessages) {
        var ceMessage = syncedJSONMessages[syncedJSONMessages.length - 1];

        if (ceMessage.isTitle == true) {
          ceMessage = syncedJSONMessages[syncedJSONMessages.length - 2]; // c'est une date

          lastLPMessage = " ";
        }

        if (ceMessage) {
          if (ceMessage.type == "meditation") ceMessage.text = "Méditation n°" + ceMessage.text;
          if (content[ceMessage.text]) ceMessage.text = content[ceMessage.text];
          var lp = syncedJSONMessages.filter(function (d) {
            return d.to == undefined;
          });
          var ceLp = lp[lp.length - 1]; //if (ceLp.auteur == undefined)	ceLp = lp[lp.length -2];

          if (content[ceLp.text]) ceLp.text = content[ceLp.text];
          var lastLPMessage = js_date('H:i', ceLp.TS) + ' <b>' + ceLp.auteur + '</b> ' + ceLp.text;
          if (lastLPMessage == undefined) lastLPMessage = " ";
        }

        var lausPerennisToDisplay = '' + '<div class="item-link item-content">' + '<a href="#" data-popup="#popup-lausPerennis" class="item-media popup-open"> <i class="fas fa-globe"></i></a>' + '<a href="/messagesContents/lausPerennis/lausPerennis/" class="item-inner item-link">' + '<div class="item-title-row">' + '<div class="item-title">Laus Perennis</div>' + '<div class="item-after"> </div>' + '</div>' + '<div class="item-text"><i>Le monde attend que l\'Eglise redevienne une société de louange.</i></div>' + '<div class="item-text latestMessage">' + lastLPMessage + '</div>' + '</a>' + '</div>';
        $('#lausPerennisToDisplay').html(lausPerennisToDisplay);
      } // affichage des messages "escouade"


      if (profil.value.escouade) {
        localforage.getItem('syncedEscouades', function (err, syncedEscouades) {
          var cetteEscouade = syncedEscouades.filter(function (d) {
            return d.nom == profil.value.escouade.nom;
          });
          var t = syncedJSONMessages.filter(function (d) {
            return cetteEscouade[0].inscrits.includes(d.auteur) && (d.to == undefined || d.to == cetteEscouade[0].nom) || d.isTitle != undefined;
          });
          var ceT = t[t.length - 1];
          if (t[t.length - 1].type == "meditation") t[t.length - 1].text = "Méditation n°" + t[t.length - 1].text;
          if (ceT.auteur == undefined) ceT = t[t.length - 2]; //if(content[ceT.text]) t[ceT.text] = content[ceT.text];

          if (content[ceT.text]) ceT.text = content[ceT.text];
          var escouadeToDisplay = '' + '<div class="item-link item-content">' + '<a href="/escouade/' + profil.value.escouade.nom + '/" class="item-media" style="color: ' + cetteEscouade[0].couleur + '"> <i class="fas fa-users"></i></a>' + '<a href="/messagesContents/escouade/' + profil.value.escouade.nom + '/" class="item-inner item-link">' + '<div class="item-title-row">' + '<div class="item-title">Escouade ' + profil.value.escouade.nom + '</div>' + '<div class="item-after"></div>' + '</div>' + '<div class="item-text"><i>' + cetteEscouade[0].lieu + ' - ' + cetteEscouade[0].description + '</i></div>' + '<div class="item-text latestMessage">' + js_date('H:i', ceT.TS) + ' <b>' + ceT.auteur + '</b> ' + ceT.text + '</div>' + '</a> ' + '</div> ';
          $('#escouadeToDisplay').html(escouadeToDisplay);
        }); //LF getItem('syncedEscouades'
      } // fin affichage des messages "escouade"
      // Administrateur


      var a = syncedJSONMessages.filter(function (d) {
        return d.auteur == 'Administrateur' || d.to == 'Administrateur';
      });
      console.log("//271 messages.f7 a : ", a);

      if (a.length > 0) {
        var administrateurToDisplay = '' + '<li>' + '<div  class="item-link item-content">' + '<a href="/utilisateur/Administrateur/" class="item-media"> <i style="color:blue !important;" class="fas fa-info-circle"></i></a>' + '<a href="/messagesContents/personnel/Administrateur/" class="item-inner item-link">' + '<div class="item-title-row">' + '<div class="item-title">Administrateur </div>' + '<div class="item-after"> </div>' + '</div>' + '<div class="item-text"><i>Gendarmerie nationale, vos papiers, s\'il vous plaît.</i></div>' + '<div class="item-text latestMessage">' + js_date('H:i', a[a.length - 1].TS) + ' ' + a[a.length - 1].text + '</div>' + '</a>' + '</div>' + '</li>';
        $('#administrateurToDisplay').html(administrateurToDisplay);
      } // utilisateurs


      var utilisateursToDisplay = '';
      console.log("//307 messages.f7 profil.value.userPseudonyme : ", profil.value.userPseudonyme);

      if (profil.value.userPseudonyme) {
        // l'utilisateur est inscrit, on peut afficher les autres inscrits
        localforage.getItem('syncedConnectedUsers', function (err, syncedConnectedUsers) {
          console.log("//293 messages.f7 syncedConnectedUsers", syncedConnectedUsers);

          for (var i = 0; i < syncedConnectedUsers.length; i++) {
            if (syncedConnectedUsers[i].userPseudonyme == profil.value.userPseudonyme) {// on n'affiche pas les messages de l'utilisateur connecté
            } else {
              // affichage des messages des autres utilisateurs
              var statuts = $store.getters.statuts;
              console.log("//317 messages ", statuts.value[syncedConnectedUsers[i].statut.length - 1].couleur);
              syncedConnectedUsers[i].couleur_statut = statuts.value[syncedConnectedUsers[i].statut.length - 1].couleur;
              syncedConnectedUsers[i].niveauFerveur = ferveurLevel(syncedConnectedUsers[i].todayFerveur);
              var u = syncedJSONMessages.filter(function (d) {
                return d.auteur == syncedConnectedUsers[i].userPseudonyme || d.to == syncedConnectedUsers[i].userPseudonyme;
              });

              if (u && u.length > 0) {
                if (u[u.length - 1].type == "meditation") u[u.length - 1].text = "Méditation n°" + u[u.length - 1].text; //if(u[u.length -1].type == "meditation") u[u.length -1].text = "Méditation n°"+u[u.length -1].text;

                if (content[u[u.length - 1].text]) u[u.length - 1].text = content[u[u.length - 1].text];
                if (syncedConnectedUsers[i].userPseudonyme) utilisateursToDisplay += '' + '<li>' + '<div  class="item-link item-content">' + '<a href="/utilisateur/' + syncedConnectedUsers[i].userPseudonyme + '/" class="item-media"> <i style="color:' + syncedConnectedUsers[i].couleur_statut + ' !important;" class="fas fa-hiking"></i></a>' + '<a href="/messagesContents/personnel/' + syncedConnectedUsers[i].userPseudonyme + '/" class="item-inner item-link">' + '<div class="item-title-row">' + '<div class="item-title">' + syncedConnectedUsers[i].userPseudonyme + ' <span style="font-size:0.7em;">' + syncedConnectedUsers[i].niveauFerveur + '</span></div>' + '<div class="item-after"> </div>' + '</div>' + '<div class="item-text"><i>' + syncedConnectedUsers[i].comment + '</i></div>' + '<div class="item-text latestMessage">' + js_date('H:i', u[u.length - 1].TS) + ' <b>' + u[u.length - 1].auteur + '</b> ' + u[u.length - 1].text + '</div>' + '</a>' + '</div>' + '</li>';
              }
            }
          } // fin de la boucle utilsateurs


          $('#utilisateursToDisplay').html(utilisateursToDisplay); // affichage des utilisateurs
        }); //LF syncedConnectedUsers

        console.log("//315 messages.f7 dernier syncedJSONMessages", syncedJSONMessages[syncedJSONMessages.length - 1]);
      }
    }); // LF getItem('syncedJSONMessages'
  } // fin display()


  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
  <div class="page" data-name="messages">
	  
    <!-- Top Navbar -->
    <div class="navbar">
      <div class="navbar-bg"></div>
      <div class="navbar-inner">
        <div class="left">
          <a href="#" class="link back">
            <i class="icon icon-back"></i>
            <span class="if-not-md">Retour</span>
          </a>
        </div>
        <div class="title">Messages</div>
        <div class="right">
					
          <a href="#" class="link icon-only">
            <i class="far fa-question-circle"></i>
          </a>
        </div>
        
      </div>
    </div>
    
    
        <!-- Nested panel -->
        <div class="panel panel-left panel-cover panel-init" id="panel-messages" data-container-el="#panel-page">
          <div class="page">
            <div class="navbar">
							<div class="navbar-bg"></div>
							<div class="navbar-inner">
								<div class="title">Messages</div>
							</div>
						</div>
						<div class="page-content">
							
						</div>
          </div>
        </div> 
				<!-- nested panel -->
    
				
				
    <div class="page-content">
      <div class="list media-list">
        <ul>
						<li id="lausPerennisToDisplay">
							<!--
						<div class="item-link item-content">
								<a href="#" data-popup="#popup-lausPerennis" class="item-media popup-open"> <i class="fas fa-globe"></i></a>
								<a href="/messagesContents/lausPerennis/lausPerennis/" class="item-inner item-link">
								<div class="item-title-row">
									<div class="item-title">Laus Perennis</div>
									<div class="item-after"> </div>
								</div>
								<div class="item-text"><i>Le monde attend que l'Eglise redevienne une société de louange.</i></div>
								<div id="lastLPMessage" class="item-text latestMessage">[latestMessage]</div>
						  </a>
						</div> 
						-->
						</li>
						<li id="administrateurToDisplay">
						
						</li>
						<li id="escouadeToDisplay">
						
						</li>
						<span id="utilisateursToDisplay">
						
						</span>
					
        </ul>
      </div>
		<!-- .popup-lausPerennis -->
		<div class="popup" id="popup-lausPerennis" >
		  <div class="view">
			<div class="page">
			  <div class="navbar">
				<div class="navbar-bg"></div>
				<div class="navbar-inner">
				  <div class="title">Laus Perennis</div>
				  <div class="right">
					  
						<a href="#" class="link popup-close">
							<i class="far fa-times-circle"></i>
						</a>
				  </div>
				</div>
			  </div>
			  <div class="page-content" style="background: #ececec">
				<div class="block">
				  <p>Au temps de S. Sigismond, qui fut roi des Burgondes de 516 à 526, et qui est vénéré 
				  aujourd'hui comme le protecteur des hommes en armes, les moines de Cluny 
				  instituèrent sous le nom de <i>Laus perennis</i>, la louange perpétuelle, 
				  la prière ininterrompue, assurée par trois équipes de moines 
				  qui se succédaient en tournant chaque jour.</p>
				  <p>Au XXIème siècle, grâce à internet et la disponibilité mondiale
				  du réseau des réseaux, cette <i>Laus perennis</i> devient accessible aux non moines,
				   par la participation dans une même <i>société</i> de tous les utilisateurs connectés 
				   et rendre ainsi concrète l'intention de dom Guéranger :</p>
				  <div id="messages_societe_de_louange" class="block block-strong">
									
				  </div>
				  
				</div>
			  </div>
			</div>
		  </div>
		</div> 
		<!-- popup-lausPerennis -->      
    </div>
    <!-- page-content -->
	  </div> 
  <!-- page -->  
`
    }
    ;
}

framework7Component.id = '4eada6fa79';
export default framework7Component;