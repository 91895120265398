/** @jsx $jsx */
import { $jsx } from 'framework7';
import js_date from '../js/utils.js/date.js'; // Tocca.js
//import tocca from 'tocca';

import tocca from '../js/Tocca.js';

function framework7Component(props, _ref) {
  var $ = _ref.$,
      $on = _ref.$on,
      $f7 = _ref.$f7,
      $f7route = _ref.$f7route,
      $store = _ref.$store;
  var id = $f7route.params.id;
  var current = {};
  $on('pageTabHide', function () {
    $('.udpatePriere').off('click', updatePriere);
    $('#boutonCheckPriere').off('click', checkPriere);
  });
  $on('pageTabShow', function () {
    //afficherPriere();
    $('.updatePriere').on('click', updatePriere);
    $('#boutonCheckPriere').on('click', checkPriere);
  });
  $on('pageBeforeIn', function () {
    console.log("//180 prieres.f7 pageBeforeIn");
    afficherPriere();
    $('.updatePriere').on('click', updatePriere);
    $('#boutonCheckPriere').on('click', checkPriere);
    $('.inviter-popup-link').on('click', function () {
      $f7.emit('displayInviterPopup');
    });
  });

  function addActivity(type, activite) {
    var profil = $store.getters.profil;
    console.log("//172 prieres.f7 profil.value.validatedActivities", profil.value.validatedActivities);
    var newActivity = {
      type: type,
      activite: activite,
      dateTS: Date.now() / 1000,
      dateYmdHi: js_date("Y-m-d H:i")
    };
    profil.value.validatedActivities.push(newActivity);
    profil.value.updated = js_date("Y-m-d H:i");
    $store.dispatch('updateProfil', profil.value);
    $f7.emit('profilUpdated', true); //mute = true

    $f7.emit('utilisateurUpdated');
    var nouveauMessage = {
      auteur: profil.value.userPseudonyme,
      text: activite,
      type: "priere",
      TS: Date.now() / 1000
    };

    if ($f7.params.isOnLine == true) {
      // on notifie d'envoi du message sur Laus Perennis
      var notificationFull = $f7.notification.create({
        icon: '<i class="far fa-envelope"></i>',
        title: 'Laus perennis',
        titleRightText: 'Maintenant',
        subtitle: 'Notification',
        text: 'Vous avez contribué à la louange pérenne.',
        closeTimeout: 3000
      });
      notificationFull.open();
    } else {
      var notificationFull = $f7.notification.create({
        icon: '<i class="far fa-envelope"></i>',
        title: 'Laus perennis',
        titleRightText: 'Maintenant',
        subtitle: 'Notification',
        text: 'Vous avez contribué à la louange pérenne. La notification apparaîtra lorque votre terminal sera relié à internet.',
        closeTimeout: 3000
      });
      notificationFull.open();
    }

    $f7.emit('addNouveauMessage', nouveauMessage);
  }

  function checkPriere() {
    console.log("//172 prieres.f7 Check de " + current.priere);
    addActivity('priere', current.priere);
  }

  function updatePriere(e) {
    console.log("//246 prieres.f7 .udpatePriere clicked e.path[0].classList[5] :", e.target.classList[5]);
    sessionStorage.currentPriere = e.target.classList[5];
    current.priere = e.target.classList[5]; //$('.priere').html("<p></p><p></p> Changement prière : "+sessionStorage.currentPriere);

    afficherPriere();
  }

  function afficherPriere() {
    $f7.panel.close('.left-prieres', true);
    current.priere = sessionStorage.currentPriere;
    if (!current.priere) current.priere = "VENI_Sancte_Spiritus";
    localforage.getItem('syncedOrationes', function (err, syncedOrationes) {
      if (current.priere == "rosaire") {
        console.log("//259 prieres.f7 rosaire");
        var contentRosaire = '' + '<div id="contentRosaire" class="page-content contenu rosaire">' + '<div id="chapeletDebut"  class="centered">' + '<i class="fas fa-cross"></i>' + '<span class="dot bigDot"></span>' + '<span class="dot"></span>' + '<span class="dot"></span>' + '<span class="dot"></span>' + '</div>' + '<div id="rosaireDizaine" class="centered" style="display: none">' + '<span class="dot bigDot"></span>' + '<span class="dot"></span>' + '<span class="dot"></span>' + '<span class="dot"></span>' + '<span class="dot"></span>' + '<span class="dot"></span>' + '<span class="dot"></span>' + '<span class="dot"></span>' + '<span class="dot"></span>' + '<span class="dot"></span>' + '<span class="dot"></span>' + '</div>' + '<table>' + '<tr>' + '<td class="gauche centre_italique rosaire-texte-la">In feria quinta : Mysteria Luminosa</td>' + '<td class="droite centre_italique rosaire-texte-fr">Le jeudi : Mystères lumineux</td>' + '</tr>' + '</table>' + '<div id="rosaireSwiper" class="rosaireSwiper-container">' + '<div class="swiper-wrapper">' + '</div>' + '</div>' + '</div>';
        $('.priere').html(contentRosaire);
        genereChapelet();
      } else {
        //$('.priere').scrollTop(); //TODO revoir ça : ça ne fonctionne pas.
        if (!current.priere) current.priere = "VENI_Sancte_Spiritus";
        console.log("//194 prieres.f7 afficherPriere() current.priere : " + current.priere);
        var cettePriere = syncedOrationes.filter(function (d) {
          return d.id == current.priere;
        })[0];
        $('.priere').html(cettePriere.contenu);
      }

      var allTr = document.getElementsByTagName('tr');

      for (var i = 0; i < allTr.length; i++) {
        //console.log("//300 prieres.f7 allTr : ",allTr[i]);
        allTr[i].addEventListener('dbltap', function () {
          this.classList.toggle('elmtSelected');
          if ($('.elmtSelected').length > 0) $('.icon-fab').removeClass("no-display");else $('.icon-fab').addClass("no-display");
          $('.share').on('click', function () {
            //console.log("//305 prieres.f7 .share-priere' clicked");
            //console.log("//306 prieres.f7 $('.elmtSelected')",$('.elmtSelected'));
            share($('.elmtSelected'));
          });
          $('.shareRS').on('click', function () {
            shareRS($('.elmtSelected'));
          });
        });
      }
    });
  }

  function genereChapelet() {
    var douloureux = {
      texte: {
        la: "In feria tertia et feria sexta: Mysteria dolorosa",
        fr: "Le mardi et le vendredi : Mystères douloureux"
      },
      mysteres: [{
        texte: {
          la: "I. - Qui pro nobis sanguinem sudavit.",
          fr: "I. - L'Agonie au jardin des oliviers."
        },
        ref: ["EV_Lc22,39-46"]
      }, {
        texte: {
          la: "II. - Qui pro nobis flagellatus est.",
          fr: "II. - La Flagellation. "
        },
        ref: ["EV_Mt27,26", "EV_Mc15,6-15"]
      }, {
        texte: {
          la: "III. - Qui pro nobis spinis coronatus est.",
          fr: "III. - Le Couronnement d'épines."
        },
        ref: ["EV_Jn19,1-8"]
      }, {
        texte: {
          la: "IV. - Qui pro nobis crucem baiulavit.",
          fr: "IV. - Le Portement de Croix."
        },
        ref: ["EV_Jn19,16-22"]
      }, {
        texte: {
          la: "V. - Qui pro nobis crucifixus est.",
          fr: "V. - La Crucifixion."
        },
        ref: ["EV_Jn19,25-30"]
      }]
    };
    var glorieux = {
      texte: {
        la: "In feria quarta et Dominica: Mysteria gloriosa",
        fr: "Le mercredi et le dimanche : Mystères glorieux"
      },
      mysteres: [{
        texte: {
          la: "I. - Qui resurrexit a mortuis.",
          fr: "I. - La Résurrection."
        },
        ref: ["EV_Mc16,1-7"]
      }, {
        texte: {
          la: "II. - Qui in caelum ascendit.",
          fr: "II. - L'Ascension."
        },
        ref: ["EV_Lc24,46-53"]
      }, {
        texte: {
          la: "III. - Qui Spiritum Sanctum misit.",
          fr: "III. - L'Effusion de l'Esprit Saint à la Pentecôte."
        },
        ref: ["LEC_Ac2,1-11"]
      }, {
        texte: {
          la: "IV. - Qui te assumpsit.",
          fr: "IV. - L'Assomption de la Bienheureuse Vierge Marie."
        },
        ref: ["Ps131,8"]
      }, {
        texte: {
          la: "V. - Qui te in caelis coronavit.",
          fr: "V. - Le Couronnement aux cieux de la Bienheureuse Vierge Marie."
        },
        ref: ["Ap12,1"]
      }]
    };
    var joyeux = {
      texte: {
        la: "In feria secunda et sabbato: Mysteria Gaudiosa",
        fr: "Le lundi et le samedi : Mystères joyeux"
      },
      mysteres: [{
        texte: {
          la: "I. - Quem, Virgo, concepisti.",
          fr: "I. - Annonciation."
        },
        ref: ["EV_Mt1,18", "EV_Lc1,26-38"]
      }, {
        texte: {
          la: "II. - Quem visitando Elisabeth portasti.",
          fr: "II. - Visitation."
        },
        ref: ["EV_Lc1,39-45"]
      }, {
        texte: {
          la: "III. - Quem, Virgo, genuisti.",
          fr: "III. - Nativité."
        },
        ref: ["EV_Lc2,6-12"]
      }, {
        texte: {
          la: "IV. - Quem in templo praesentasti.",
          fr: "IV. - Présentation au Temple."
        },
        ref: ["EV_Lc2,25-32"]
      }, {
        texte: {
          la: "V. - Quem in templo invenisti.",
          fr: "V. - Recouvrement au Temple."
        },
        ref: ["EV_Lc2,41-50"]
      }]
    };
    var lumineux = {
      texte: {
        la: "In feria quinta : Mysteria Luminosa",
        fr: "Le jeudi : Mystères lumineux"
      },
      mysteres: [{
        texte: {
          la: "I. - Qui apud Iordanem baptizatus est.",
          fr: "I. - Le Baptême au Jourdain."
        },
        ref: ["EV_Mt3,13-17", "EV_Mc1,7-11", "EV_Jn1,29-34"]
      }, {
        texte: {
          la: "II. - Qui ipsum revelavit apud Canense matrimonium.",
          fr: "II. - Les Noces de Cana."
        },
        ref: ["EV_Jn2,1-11"]
      }, {
        texte: {
          la: "III. - Qui Regnum Dei annuntiavit.",
          fr: "III. - L'annonce du Royaume."
        },
        ref: ["EV_Mc1,14-20", "EV_Lc10,1-12.17-20"]
      }, {
        texte: {
          la: "IV. - Qui transfiguratus est.",
          fr: "IV. - La Transfiguration."
        },
        ref: ["EV_Mt17,1-8", "EV_Mc9,2-9"]
      }, {
        texte: {
          la: "V. - Qui Eucharistiam instituit.",
          fr: "V. - L'institution de l'Eucharistie."
        },
        ref: ["EV_Jn6,51-58", "EV_Mt26,26-29", "EV_Mc14,12-16.22-26", "EV_Lc22,15-20"]
      }]
    };
    var mysteres;
    var jrSemaine = Number(js_date("w", Date.now() / 1000)) + 1; //A numeric representation of the day (0 for Sunday, 6 for Saturday)

    if (jrSemaine == 2 || jrSemaine == 7) mysteres = joyeux;
    if (jrSemaine == 5) mysteres = lumineux;
    if (jrSemaine == 3 || jrSemaine == 6) mysteres = douloureux;
    if (jrSemaine == 1 || jrSemaine == 4) mysteres = glorieux;
    document.getElementsByClassName("rosaire-texte-la")[0].innerHTML = mysteres.texte.la;
    document.getElementsByClassName("rosaire-texte-fr")[0].innerHTML = mysteres.texte.fr;
    localforage.getItem('syncedOrationes').then(function (syncedOrationes) {
      var signumCrucis = syncedOrationes.filter(function (d) {
        return d.id == 'signum_crucis';
      })[0].contenu;
      var credo = syncedOrationes.filter(function (d) {
        return d.id == 'symbolum_apostolorum';
      })[0].contenu;
      var pater = syncedOrationes.filter(function (d) {
        return d.id == 'pater';
      })[0].contenu;
      var ave_maria = syncedOrationes.filter(function (d) {
        return d.id == 'ave_maria';
      })[0].contenu;
      var gloria_patri = syncedOrationes.filter(function (d) {
        return d.id == 'gloria_patri';
      })[0].contenu;
      var oratio_Fatima = syncedOrationes.filter(function (d) {
        return d.id == 'Oratio_Fatima';
      })[0].contenu; ///////////// dizaine(mysteres,index)

      function finChapelet() {
        var slideFin = '' + '<div class="block block-strong inset">Vous avez terminé la prière du chapelet.</div>';
        allSlides.push(slideFin);
      }

      function debutChapelet() {
        var slideCredo = credo;
        var slidePater = pater;
        var slideAve = ave_maria;
        var slideGloria = gloria_patri;
        var slideOratio_Fatima = oratio_Fatima;
        allSlides.push(slideCredo, slidePater, slideAve, slideAve, slideAve, slideGloria, slideOratio_Fatima);
      }

      function dizaine(mysteres, index) {
        var slideBible = '' + '<table>' + '<tr>' + '<td class="gauche centre_gras">' + mysteres.mysteres[index].texte.la + '</td>' + '<td class="droite centre_gras">' + mysteres.mysteres[index].texte.fr + '</td>' + '</tr>' + '</table>';

        for (var i = 0; i < mysteres.mysteres[index].ref.length; i++) {
          var refBiblique = syncedOrationes.filter(function (d) {
            return d.id == mysteres.mysteres[index].ref[i];
          })[0].contenu;
          slideBible += refBiblique + "<hr />";
        }

        var slidePater = '' + '<table>' + '<tr>' + '<td class="gauche gauche centre_rouge">' + mysteres.mysteres[index].texte.la + '</td>' + '<td class="droite gauche centre_rouge">' + mysteres.mysteres[index].texte.fr + '</td>' + '</tr>' + '</table>' + pater;
        var slideAve = '' + '<table>' + '<tr>' + '<td class="gauche gauche centre_rouge rosaire-texte-la">' + mysteres.mysteres[index].texte.la + '</td>' + '<td class="droite gauche centre_rouge rosaire-texte-fr">' + mysteres.mysteres[index].texte.fr + '</td>' + '</tr>' + '</table>' + ave_maria;
        var slideGloria = '' + '<table>' + '<tr>' + '<td class="gauche gauche centre_rouge rosaire-texte-la">' + mysteres.mysteres[index].texte.la + '</td>' + '<td class="droite gauche centre_rouge rosaire-texte-fr">' + mysteres.mysteres[index].texte.fr + '</td>' + '</tr>' + '</table>' + gloria_patri;
        var slideOMiIesu = '' + '<table>' + '<tr>' + '<td class="gauche gauche centre_rouge rosaire-texte-la">' + mysteres.mysteres[index].texte.la + '</td>' + '<td class="droite gauche centre_rouge rosaire-texte-fr">' + mysteres.mysteres[index].texte.fr + '</td>' + '</tr>' + '</table>' + '<table>' + oratio_Fatima;
        allSlides.push(slideBible, slidePater, slideAve, slideAve, slideAve, slideAve, slideAve, slideAve, slideAve, slideAve, slideAve, slideAve, slideGloria, slideOMiIesu);
        console.log("//669 prieres.f7 allSlides : ", allSlides); //return result;
      }

      var dotIndex = 0;
      var dots;
      var allSlides = [];
      debutChapelet();
      dizaine(mysteres, 0);
      dizaine(mysteres, 1);
      dizaine(mysteres, 2);
      dizaine(mysteres, 3);
      dizaine(mysteres, 4);
      finChapelet(); //console.log("//934 : ");
      //console.log("//641 prieres.f7 allSlides : ",allSlides);

      var rosaireSwiper = $f7.swiper.create('#rosaireSwiper', {
        speed: 400,
        spaceBetween: 40,
        preloadImages: true,
        centeredSlides: true,
        allowSlidePrev: false,
        autoHeight: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        virtual: {
          slides: allSlides
        }
      });
      $('.swiper-wrapper').on('click', function () {
        console.log("//663 prieres.f7 rosaire swiper-wrapper clicked");
        rosaireSwiper.slideNext(400, true);
      });
      console.log("//620 prieres.f7 : rosaireSwiper : ", rosaireSwiper);
      var slidesIndex = 0;
      var dotIndex = -1; //document.getElementById("rosaireDizaine").style.display="block";

      rosaireSwiper.on("slidePrevTransitionStart", function () {
        if (slidesIndex > 5) {
          document.getElementById("chapeletDebut").style.display = "none";
          document.getElementById("rosaireDizaine").style.display = "block";
        }

        slidesIndex--;
        dotIndex--;

        if (slidesIndex == 5 || slidesIndex == 6 || slidesIndex == 7) {
          dotIndex = -1;
        }

        if (slidesIndex == 8 || slidesIndex == 22 || slidesIndex == 36 || slidesIndex == 50 || slidesIndex == 64) {
          dotIndex = 4;
        }

        dots = document.getElementsByClassName("dot");
        console.log("//531  slidesIndex = " + slidesIndex + " dotIndex " + dotIndex);

        for (var i = 0; i < dots.length; i++) {
          dots[i].classList.remove("black");
        }

        if (dots[dotIndex]) dots[dotIndex].classList.add("black");
      });
      rosaireSwiper.on("slideNextTransitionStart", function () {
        //console.log("//526 : slideNextTransitionEnd");

        /*
        if(slidesIndex == 76) {
        	var btnLausPerennisRosario = document.getElementById("btnLausPerennisRosario");
        	btnLausPerennisRosario.removeEventListener("click",valideRosario);
        	btnLausPerennisRosario.addEventListener("click",valideRosario);
        	console.log("//684");
        	console.log(btnLausPerennisRosario);
        }
        */
        if (slidesIndex > 5) {
          document.getElementById("chapeletDebut").style.display = "none";
          document.getElementById("rosaireDizaine").style.display = "block";
        }

        slidesIndex++;
        dotIndex++;

        if (slidesIndex == 5 || slidesIndex == 6 || slidesIndex == 7) {
          dotIndex = -1;
        }

        if (slidesIndex == 8 || slidesIndex == 22 || slidesIndex == 36 || slidesIndex == 50 || slidesIndex == 64) {
          dotIndex = 4;
        }

        dots = document.getElementsByClassName("dot");
        console.log("//780  slidesIndex = " + slidesIndex + " dotIndex " + dotIndex);

        for (var i = 0; i < dots.length; i++) {
          dots[i].classList.remove("black");
        }

        if (dots[dotIndex]) dots[dotIndex].classList.add("black");
      });

      function valideRosario() {
        console.log("//998 valideRosario : rosario " + js_date("Ymd Hi", Date.now() / 1000));
        rosaireSwiper.slideTo(0); // lausPerennis(type,contenu)

        lausPerennis("priere", "rosario");
        generalUpdateValidatedActivites("priere", "rosario");
      } /////////////

    }).catch(function (err) {
      // This code runs if there were any errors
      console.log(err);
    });
  }

  function share(selectedElts) {
    var contenu = "";
    var la, fr;

    for (var i = 0; i < selectedElts.length; i++) {
      la = selectedElts[i].firstElementChild.innerHTML;
      fr = selectedElts[i].lastElementChild.innerHTML;
      contenu += "<tr><td width=50%>" + la + "</td><td width=50%>" + fr + "</td></tr>";
    }

    if (selectedElts.length == 0) return;
    contenu = '<table>' + contenu + '</table>';
    var profil = $store.getters.profil;
    console.log("//790 prieres.f7 contenu à envoyer dans LP : ", contenu);
    var nouveauMessage = {};
    nouveauMessage.type = 'citation';
    nouveauMessage.text = contenu;
    nouveauMessage.auteur = profil.value.userPseudonyme;
    nouveauMessage.TS = Date.now() / 1000;
    $f7.emit('addNouveauMessage', nouveauMessage);
    var notificationFull = $f7.notification.create({
      icon: '<i class="fas fa-share-alt"></i>',
      title: 'Laus perennis',
      titleRightText: 'maintenant',
      subtitle: 'Partagé',
      text: 'Vous avez partégé cet extrait dans <i>Laus perennis</i>.',
      closeTimeout: 3000
    });
    notificationFull.open();
    $('.elmtSelected').removeClass('elmtSelected');
    $('.icon-fab').addClass("no-display");
  }

  function shareRS(selectedElts) {
    var contenu = "";
    var la, fr;

    for (var i = 0; i < selectedElts.length; i++) {
      la = selectedElts[i].firstElementChild.innerHTML;
      fr = selectedElts[i].lastElementChild.innerHTML;
      contenu += la + " | " + fr + " \n";
    }

    if (selectedElts.length == 0) return;
    var url;
    if ($f7.device.android) url = 'https://play.google.com/store/apps/details?id=org.societaslaudis.app';
    var socialShareOptions = {
      message: contenu,
      // not supported on some apps (Facebook, Instagram)
      subject: 'Societas laudis',
      // fi. for email
      //:files: ['', ''], // an array of filenames either locally or remotely
      url: url,
      chooserTitle: 'Choisissez : ' // Android only, you can override the default share sheet title
      //appPackageName: 'com.apple.social.facebook', // Android only, you can provide id of the App you want to share with
      //iPadCoordinates: '0,0,0,0' //IOS only iPadCoordinates for where the popover should be point.  Format with x,y,width,height

    };

    var onSuccess = function onSuccess(result) {
      console.log("Share completed? " + result.completed); // On Android apps mostly return false even while it's true

      console.log("Shared to app: " + result.app); // On Android result.app since plugin version 5.4.0 this is no longer empty. On iOS it's empty when sharing is cancelled (result.completed=false)
    };

    var onError = function onError(msg) {
      console.log("Sharing failed with message: " + msg);
    };

    $('.elmtSelected').removeClass('elmtSelected');
    $('.icon-fab').addClass("no-display");
    window.plugins.socialsharing.shareWithOptions(socialShareOptions, onSuccess, onError);
  }

  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
		
	
  <div class="page" data-name="prieres">
    <div class="navbar">
      <div class="navbar-bg"></div>
      <div class="navbar-inner">
          <div class="left">
          <a href="#" class="link icon-only panel-open" data-panel=".left-prieres">
            <i class="fas fa-bars"></i>
          </a>
        </div>
        <div class="title">Prières usuelles</div>
        <div class="right">
			<a href="#inv" class="tab-link icon-only inviter-popup-link">
				<i class="fas fa-user-plus"></i> 
			</a>		
          <a href="/messages/" class="tab-link icon-only">
            <i class="icon material-icons">mail_outline<span class="badge color-red newMessages" id="newMessages">3</span></i> 
          </a>
        </div>
      </div>
		<!-- navbar-inner -->
    </div>
    <!-- navbar -->
    
    
    <div class="panel panel-left left-prieres panel-reveal panel-resizable panel-init">
      <div class="page">
            <div class="navbar">
							<div class="navbar-bg"></div>
							<div class="navbar-inner">
								<div class="title">Prières usuelles</div>
							</div>
						</div>
						<div class="page-content">
							<div class="block">
								<button class="col button button-raised button-fill updatePriere signum_crucis">
									Signe de croix
								</button>
								<p></p>
								<button class="col button button-raised button-fill updatePriere pater">
									Notre Père
								</button>
								<p></p>
								<button class="col button button-raised button-fill updatePriere ave_maria">
									Je vous salue Marie
								</button>
								<p></p>
								<button class="col button button-raised button-fill updatePriere gloria_patri">
									Doxologie (Gloire au Père)
								</button>
								<hr/>
								
								<p></p>
								<button class="col button button-raised button-fill updatePriere angelus">
									Angelus
								</button>
								<p></p>
								<button class="col button button-raised button-fill updatePriere benedictio_mensae">
									Bénédictions de la table
								</button>
								<p></p>
								<button class="col button button-raised button-fill updatePriere rosaire ">
									Rosaire </button>
								<hr/>
								<p></p>
								<button class="col button button-raised button-fill updatePriere Anima_Christi">
									Ame du Christ
								</button>
								<p></p>
								<button class="col button button-raised button-fill updatePriere VENI_Sancte_Spiritus">
									Veni Sancte Spiritus
								</button>
								<p></p>
								<button class="col button button-raised button-fill updatePriere salve_regina">
									Salve Regina
								</button>
								<p></p>
								<button class="col button button-raised button-fill updatePriere alma_redemptoris_mater">
									Alma Redemptoris
								</button>
								<p></p>
								<button class="col button button-raised button-fill updatePriere Ave_Regina_Caelorum_et_vers">
									Ave Regina Caelorum
								</button>
								<p></p>
								<button class="col button button-raised button-fill updatePriere Regina_Caeli_et_vers">
									Regina caeli
								</button>
								<p></p>
								<button class="col button button-raised button-fill updatePriere Sub_tuum">
									Sub tuum
								</button>
								<p></p>
								<button class="col button button-raised button-fill updatePriere memorare">
									Memorare
								</button>
								<p></p>
								<button class="col button button-raised button-fill updatePriere Oratio_ad_Sanctum_Michael">
									A S. Michel archange
								</button>
								<p></p>
								<button class="col button button-raised button-fill updatePriere angele_dei">
									A l'ange gardien
								</button>
								<p></p>
								<button class="col button button-raised button-fill updatePriere Priere_S_Joseph_S_Francois_de_Sales">
									A S. Joseph
								</button>
								<p></p>
								<button class="col button button-raised button-fill updatePriere Oratio_pro_summo_Pontifice">
									Pour le pape
								</button>
								<p></p>
								<button class="col button button-raised button-fill updatePriere Oratio_S_Thomae_Aquinatis_ante_studium">
									Avant l'étude
								</button>
								<p></p>
								<button class="col button button-raised button-fill updatePriere Oratio_ante_colligationem">
									Avant de se connecter
								</button>
								<p></p>
								<button class="col button button-raised button-fill updatePriere Actus_Contritionis">
									Acte de contrition
								</button>
							</div>
						</div>
          </div>
    </div>
    
    <div id="fabBackdrop" class="fab-backdrop"></div>
			<div class="fab fab-left-top ">
			<a href="#" class="icon-fab no-display">
				<i class="fas fa-share"></i>
				<i class="fas fa-times"></i>
			</a>
			<div class="fab-buttons fab-buttons-bottom">
				<!-- <a >Partager</a> -->
				<a class="fab-label-button share fab-close" href="#LP">
					<span><i class="fas fa-share-square "></i></span>
					<span class="fab-label">Laus perennis</span>
				</a>
				<a class="fab-label-button shareRS fab-close" href="#RS">
					<span><i class="fas fa-share-alt-square"></i></span>
					<span class="fab-label">Réseaux sociaux</span>
				</a>
			</div>
	  </div>
    
	 	
      
      
    <div id="prieres" class="page-content block" style="padding-top: 4em; padding-bottom: 8em;">
		
		<div  class="priere">
			
		</div>
		<a id="boutonCheckPriere" href="#" class="button button-raised button-fill"><i class="fas fa-check"></i></a>
		
    </div>
    <!-- page-content -->
  </div>
	<!-- page -->
`
    }
    ;
}

framework7Component.id = '685f312154';
export default framework7Component;