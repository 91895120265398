/** @jsx $jsx */
import { $jsx } from 'framework7';

function framework7Component(props, _ref) {
  var $ = _ref.$,
      $f7 = _ref.$f7,
      $on = _ref.$on,
      $update = _ref.$update;
  console.log("//403 app.f7 localStorage.presentationVue : ", localStorage.presentationVue);
  if (localStorage.presentationVue == true) $f7.emit('displayLeSaviezVous');
  $on('pageBeforeIn', function () {
    console.log("//405 app.f7 pageBeforeIn"); //if(localStorage.presentationVue == true) $f7.emit('displayLeSaviezVous');
    //else $f7.emit('displayPresentation');

    if (!sessionStorage.leSaviezVous) {
      if (localStorage.presentationVue) $f7.emit('displayLeSaviezVous');
    }

    if (localStorage.presentationVue == undefined) $f7.emit('displayPresentation');
  });
  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
  <div id="app">

    <!-- Views/Tabs container -->
    <div class="views tabs safe-areas">
      <!-- Tabbar for switching views-tabs -->
      <div class="toolbar toolbar-bottom tabbar-labels">
        <div class="toolbar-inner">
          <a href="#view-ascese" class="tab-link tab-link-active">
            <i class="fas fa-hiking"></i>
            <span class="tabbar-label">Ierusalem</span>
          </a>
          <a href="#view-calendrier" class="tab-link">
            <i class="fas fa-calendar-alt"></i>
            <span class="tabbar-label">Date</span>
          </a>
          <a href="#view-liturgie" class="tab-link">
            <i class="fas fa-bible"></i>
            <span class="tabbar-label">Liturgie</span>
          </a>
          <a href="#view-prieres" class="tab-link">
            <i class="fas fa-pray"></i>
            <span class="tabbar-label">Prières</span>
          </a>
          <!-- <a href="#view-profil" class="tab-link"> -->
	  <a href="#view-profil" class="tab-link">
            <i class="fas fa-user"></i>
            <span class="tabbar-label">Profil</span>
          </a>
        </div>
      </div>

      <!-- Your main view/tab, should have "view-main" class. It also has "tab-active" class -->
      
      <!-- Ascese View -->
      <div id="view-ascese" class="view view-main view-init tab tab-active" data-name="ascese" data-url="/">
        <!-- Ascese page will be loaded here dynamically from / route -->
      </div>
      <!-- Calendrier View -->
      <div id="view-calendrier" class="view  view-init tab" data-name="calendrier" data-url="/calendrier/dateISO">
        <!-- Calendrier page will be loaded here dynamically from /calendrier/:datemd route -->
      </div>
      <!-- Liturgie View -->
      <div id="view-liturgie" class="view view-init tab" data-name="liturgie" data-url="/liturgie/">
        <!-- Liturgie page will be loaded here dynamically from /liturgie/:dateYmd route -->
      </div>
      
      <!-- Prieres View  -->
      <div id="view-prieres" class="view view-init tab" data-name="prieres" data-url="/prieres/">
        <!-- Prieres page will be loaded here dynamically from /prieres/ route -->
      </div>
      
      <!-- Settings View -->
      <div id="view-settings" class="view view-init tab" data-name="settings" data-url="/settings/">
        <!-- Settings page will be loaded here dynamically from /settings/ route -->
      </div>
      
      <!-- Profil View -->
      <div id="view-profil" class="view view-init tab" data-name="profil" data-url="/profil/">
        <!-- Profil page will be loaded here dynamically from /settings/ route -->
      </div>
      
      <!-- Messages View -->
      <div id="view-messages" class="view view-init tab" data-name="messages" data-url="/messages/">
        <!-- Messages page will be loaded here dynamically from /settings/ route -->
      </div>
      
    </div>
	
  
    <!-- presentation-popup -->
    <div class="popup"  id="presentation-popup">
      <div class="view">
        <div class="page">
          <div class="navbar">
            <div class="navbar-bg"></div>
            <div class="navbar-inner">
							
              <div class="title">Societas laudis</div>
              <div class="right">
                <a href="#popupPresentation" class="link popup-close popup-presentation-close"><i class="far fa-times-circle"></i></a>
              </div>
            </div>
          </div>
          <!-- navbar -->
          <div class="swiper-container presentation-swiper">
				
	    <div data-pagination='{"el": ".swiper-pagination"}' data-space-between="50" class="swiper-container swiper-init presentation-swiper">
	      <div class="swiper-pagination" style=“top:0”></div>
	      <div class="swiper-wrapper">
		      <div class="swiper-slide">
			      <div class="card presentation-card-header-pic">
				      <div style="background-image:url(static/presentation/img1-H200.jpg)"
					      class="card-header align-items-flex-end"><div class="card-image-title">Pour chaque jour</div></div>
				      <div class="card-content card-content-padding">
					      <p>L'application <i>Societas Laudis</i> vous acccompagne chaque jour dans votre vie chrétienne, que ce soit sur le plan de 
					      la prière liturgique, de la piété, ou des autres pratiques (renforcement, de vos connaissances catéchétiques et doctrinales, 
					      aide pour votre prière individuelle, votre pratique de l'ascèse chrétienne, et initiation à la méditation et l'oraison).</p>
				      </div>
			      </div>
		      </div>
		      <div class="swiper-slide">
			      <div class="card presentation-card-header-pic">
				      <div style="background-image:url(static/presentation/img2-H200.jpg)"
					      class="card-header align-items-flex-end"><div class="card-image-title">Pour chacune des Heures liturgiques</div></div>
				      <div class="card-content card-content-padding">
					      <p>Vous avez accès, même sans réseau, aux textes de l'Office divin et de la Messe, en consultant le panneau
					       de gauche dans <b>l'onglet liturgie</b>. Les textes s'affichent automatiquement en fonction du jour de l'année liturgique et 
					       de l'heure. L'ensemble des textes sont présentés en latin 
					       dans la colonne de gauche, avec une traduction française dans la colonne de droite.</p>
				      </div>
			      </div>
		      </div>
		      <div class="swiper-slide">
			      <div class="card presentation-card-header-pic">
				      <div style="background-image:url(static/presentation/img3-H200.jpg)"
					      class="card-header align-items-flex-end"><div class="card-image-title">Calendrier et ordo liturgique automatisés</div></div>
				      <div class="card-content card-content-padding">
					      <p>Vous pouvez naviguer dans l'année liturgique au travers de <b>l'onglet calendrier</b>. Les textes sont synchronisés avec 
					      le serveur afin que vous puissiez y accéder facilement hors ligne. Vous pouvez sauvergarder sur votre terminal jusqu'à 
					      15 jours d'avance. Les jours en gras sur le calendrier ont été téléchargés sur votre terminal.</p>
				      </div>
			      </div>
		      </div>
		      <div class="swiper-slide">
			      <div class="card presentation-card-header-pic">
				      <div style="background-image:url(static/presentation/img4-H200.jpg)"
					      class="card-header align-items-flex-end"><div class="card-image-title">Une formation au quotidien</div></div>
				      <div class="card-content card-content-padding">
					      <p>Vous bénéficiez également dans l'application pour chaque jour :
					      <br />- du commentaire de l'Evangile du jour,
					      <br />- d'un extrait du Compendium du Catéchisme,
					      <br />- de l'extrait quotidien de la Règle de S. Benoît avec son commentaire.
					      <br />Ces contenus, qui changent en fonction de l'ordo liturgique, sont accessibles comme les autres même 
					      si vous êtes hors réseau.</p>
				      </div>
			      </div>
		      </div>
		      <div class="swiper-slide">
			      <div class="card presentation-card-header-pic">
				      <div style="background-image:url(static/presentation/img5-H200.jpg)"
					      class="card-header align-items-flex-end"><div class="card-image-title">Retrouvez vos prières préférées</div></div>
				      <div class="card-content card-content-padding">
					      <p>Vous accéderez également à un ensemble de prières usuelles, avec notamment le chapelet, récitable directement 
					      sur l'application, et conçu à partir de la lettre apostolique de S. Jean-Paul II <i>Rosarium Virginis Mariae</i>. 
					      Les prières les plus communes sont également disponibles, comme le <i>Benedicite</i>, le <i>Veni Sancte Spiritus</i>, l'<i>Angelus</i>, 
					      les antiennes à la Bienheureuse Vierge Marie pour chaque temps liturgiques, et bien d'autres.</p>
				      </div>
			      </div>
		      </div>
		      <div class="swiper-slide">
			      <div class="card presentation-card-header-pic">
				      <div style="background-image:url(static/presentation/img6-H200.jpg)"
					      class="card-header align-items-flex-end"><div class="card-image-title">La société de la louange divine</div></div>
				      <div class="card-content card-content-padding">
					      <p>L'application a pour objectif de rendre concrète la participation de tous les utilisateurs à une "Société de Louange" 
					      (<i>Societas laudis</ii>). Vous pourrez encourager les autres et être encouragé par eux dans votre vie chrétienne et de prière au 
					      travers la consultation des messages en cliquant sur l'enveloppe en haut à droite. Choisissez un pseudonyme, renseignez votre profil public, 
					      et devenez "sociétaire" puis parcourez chacune des étapes.</p>
				      </div>
			      </div>
		      </div>
		      <div class="swiper-slide">
			      <div class="card presentation-card-header-pic">
				      <div style="background-image:url(static/presentation/img7-H200.jpg)"
					      class="card-header align-items-flex-end"><div class="card-image-title">Une prière commune à travers le monde entier</div></div>
				      <div class="card-content card-content-padding">
					      <p>Un verset, une antienne, une strophe d'hymne ou une prière vous a spécialement marqué ? Faites un double clic dessus, 
					      il se surlignera et vous pourrez le partager à tous les sociétaires. Cela encouragera tous les autres dans leur ferveur et 
					      leur proposera eux aussi de méditer sur cet extrait du patrimoine spirituel de l'Eglise qui vous a  
					      aidé dans votre prière. C'est aussi une façon de vous en souvenir et d'en faire une oraison jaculatoire.</p>
				      </div>
			      </div>
		      </div>
		      <div class="swiper-slide">
			      <div class="card presentation-card-header-pic">
				      <div style="background-image:url(static/presentation/img9-H200.jpg)"
					      class="card-header align-items-flex-end"><div class="card-image-title">En route vers Jérusalem</div></div>
				      <div class="card-content card-content-padding">
					      <p>L'application vous propose un parcours spirituel et ascétique qui prend la forme d'un pélerinage vers Jérusalem. 
					      Chaque action que vous validez sur l'application vous fait parcourir (virtuellement) chaque jour un certain nombre de kilomètres 
					       qui vous rapprochent du but. Au travers de <b>l'outil Progression</b> disponible dans <b>l'onglet Ierusalem</b>, 
					      vous pourrez suivre votre pélerinage, et connaître votre position (virtuelle !).</p>
				      </div>
			      </div>
		      </div>
		      <div class="swiper-slide">
			      <div class="card presentation-card-header-pic">
				      <div style="background-image:url(static/presentation/img10-H200.jpg)"
					      class="card-header align-items-flex-end"><div class="card-image-title">Un bilan quotidien</div></div>
				      <div class="card-content card-content-padding">
					      <p>Il est conseillé de faire un bilan quotidien de votre prière personnelle, communautaire ou liturgique, 
					      mais aussi de vos pratiques ascétiques ou de charité, dans le but de renforcer votre régularité dans votre vie chrétienne. 
					      . Vous accédez à votre bilan quotidien dans l'onglet Ierusalem. Bien sûr la validation de votre bilan quotidien ajoute des km à votre 
					      itinéraire virtuel vers Jérusalem.</p>
				      </div>
			      </div>
		      </div>
		      <div class="swiper-slide">
			      <div class="card presentation-card-header-pic">
				      <div style="background-image:url(static/presentation/img11-H200.jpg)"
					      class="card-header align-items-flex-end"><div class="card-image-title">Internet au service de la vie spirituelle</div></div>
				      <div class="card-content card-content-padding">
					      <p>Nous espérons que cette application rencontrera quelques uns de vos besoins en terme de vie spirituelle. Internet est 
					      une véritable chance pour unir les Chrétiens à travers le monde, qui partagent le même patrimoine spirituel, et en particulier 
					      le patrimoine de la liturgie romaine qui a une vocation universelle. 
					      C'est pourquoi nous tenons à utiliser le latin, dont les textes sont communs dans tous les pays du monde.</p>
				      </div>
			      </div>
		      </div>
		      <div class="swiper-slide">
			      <div class="card presentation-card-header-pic">
				      <div style="background-image:url(static/presentation/img12-H200.jpg)"
					      class="card-header align-items-flex-end"><div class="card-image-title">Participez aux traductions</div></div>
				      <div class="card-content card-content-padding">
					      <p>Les textes latins sont traduits au travers de notre site web. Sur ce dernier, vous pouvez à tout moment proposer des 
					      améliorations de traduction. A ce jour, seul l'office divin diurne de <i>Liturgia Horarum</i> est présent dans l'application. 
					      L'ajout de l'office divin selon le cursus monastique est en cours. Les textes de la Messe sont presque complets et ceux 
					      qui manquent sont ajoutés au fur et à mesure.</p>
				      </div>
			      </div>
		      </div>
		      <div class="swiper-slide">
			      <div class="card presentation-card-header-pic">
				      <div style="background-image:url(static/presentation/img13-H200.jpg)"
					      class="card-header align-items-flex-end"><div class="card-image-title">www.societalaudis.org</div></div>
				      <div class="card-content card-content-padding">
					      <p>Vous pouvez retrouver cette présentation dans <b>l'onglet 
					      Date</b>, panneau de gauche, en cliquant sur le <b>bouton Présentation</b>. Si vous avez des remarques, des difficultés, ou des propositions, 
					      n'hésitez pas à nous contacter au travers du site web.</p>
				      </div>
			      </div>
		      </div>
		      <div class="swiper-slide">
			      <div class="card presentation-card-header-pic">
				      <div style="background-image:url(static/presentation/img14-H200.jpg)"
					      class="card-header align-items-flex-end"><div class="card-image-title">Inscrivez-vous</div></div>
				      <div class="card-content card-content-padding">
					      <p>Vous pouvez maintenant commencer à utiliser l'application et procéder à votre inscription. En indiquant votre adresse email, vous recevrez 
					      immédiatement un message electronique avec un mot de passe vous permettant de la valider dans <b>l'onglet Profil</b>. A tout de suite sur <i>Societas Laudis</i> !</p>
					      
				      </div>
			      </div>
		      </div>
	      </div>
	      <!-- swiper-wrapper -->
	    </div>
	    <!-- data-pagination -->
	  </div>
	  <!-- swiper-container -->
          <div class="page-content">
			
          </div>
          <!-- page-content -->
        <!-- page -->
        </div>
				<!-- page -->
			</div>
      <!-- view -->
     </div>
    <!-- popup presentation-popup-->
	
	
	<!-- inviter-popup -->
    <div class="popup"  id="inviter-popup">
      <div class="view">
        <div class="page">
          <div class="navbar">
            <div class="navbar-bg"></div>
            <div class="navbar-inner">
							
              <div class="title">Inviter des amis :</div>
              <div class="right">
                <a href="#popupInviter" class="link popup-close popup-inviter-close"><i class="far fa-times-circle"></i></a>
              </div>
            </div>
          </div>
          <!-- navbar -->
         
          <div class="page-content">
			 <div class="block">(WhattsApp, Signal, Facebook, Email et autres réseaux sociaux...)
			  <button class="col button button-fill inviterDesAmis">Inviter des amis sur Societas laudis</button>
			 </div>
			 <div class="block">Vos amis sont avec vous ? Scanner le QR Code :<p></p>
				<div id="QRCodeInviter"></div>
			 </div>
          </div>
          <!-- page-content -->
        <!-- page -->
        </div>
		<!-- page -->
			</div>
      <!-- view -->
     </div>
    <!-- popup inviter-popup-->
	
	
	
  </div>
`
    }
    ;
}

framework7Component.id = '15308ca1f6';
framework7Component.style = "\n\t.page {\n\t\tbackground: #f1f0ec;\n\t}\n\t#presentation-popup{\n\t\t//background: black;\n\t}\n  .presentation-swiper .swiper-slide {\n    font-size: 1em;\n    font-family: georgia;\n    text-align:justify;\n    font-weight: 300;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    color: #000;\n  }\n\n  .presentation-swiper .swiper-slide {\n    box-sizing: border-box;\n    border: 0px solid #ddd;\n    \n    height:100%\n  }\n\n  .presentation-swiper {\n    margin: 30px 0 0 0;\n    font-size: 18px;\n  }\n \n // card\n \n .card-header {\n\t//height: 200px;\n  //color: white;\n  //font-weight: 900;\n }  \n \n .presentation-card-header-pic .card-header {\n    \n    height: 40vw;\n    background-size: cover;\n    background-position: center;\n    color: #fff;\n    font-weight: 900;\n  }\n.card-image-title {\n\n  background: rgb(0, 0, 0); /* Fallback color */\n  background: rgba(0, 0, 0, 0.3); /* Black background with 0.5 opacity */\n  width: 100%; /* Full width */\n  padding:0.5em;\n}\n  \n.swiper-pagination {\n\ttop: -10px;\n\t//background : white;\n}\n";
export default framework7Component;