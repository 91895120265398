/** @jsx $jsx */
import { $jsx } from 'framework7';
import js_date from '../js/utils.js/date.js';

function framework7Component(props, _ref) {
  var $ = _ref.$,
      $on = _ref.$on,
      $f7 = _ref.$f7,
      $f7route = _ref.$f7route,
      $store = _ref.$store;
  var profil;
  $on('pageBeforeIn', function () {
    $('.inviter-popup-link').on('click', function () {
      $f7.emit('displayInviterPopup');
    });
    console.log("//610 bilan.f7 on pageBeforeIn");
    $('#bilan-liturgie').on('click', function () {
      document.getElementById('bilan-liturgie-titre').scrollIntoView();
      var notificationClickToClose = $f7.notification.create({
        icon: '<i class="fas fa-bible "></i>',
        title: 'Bilan',
        titleRightText: 'Livre de l\'Apocalypse, 7,15',
        subtitle: 'Liturgie et sacrements',
        text: 'Ils sont devant le trône de Dieu, Le servant jour et nuit dans Son temple; ' + 'et Celui qui siège sur le trône étendra sur eux Sa tente.',
        closeButton: true,
        cssClass: 'leSaviezVous'
      });
      notificationClickToClose.open();
    });
    $('#bilan-piete').on('click', function () {
      document.getElementById('bilan-piete-titre').scrollIntoView();
      var notificationClickToClose = $f7.notification.create({
        icon: '<i class="fas fa-praying-hands"></i>',
        title: 'Bilan',
        titleRightText: 'Evangile selon S. Matthieu, 6,6',
        subtitle: 'Piété (méditations et autres prières.)',
        text: 'Mais toi, quand tu pries, entre dans ta chambre, et, après ' + 'avoir fermé la porte, prie ton Pére dans le secret: et ton Père, qui voit dans le secret, te le rendra.',
        closeButton: true,
        cssClass: 'leSaviezVous'
      });
      notificationClickToClose.open();
    });
    $('#bilan-ascese').on('click', function () {
      document.getElementById('bilan-ascese-titre').scrollIntoView();
      var notificationClickToClose = $f7.notification.create({
        icon: '<i class="fas fa-pray"></i>',
        title: 'Bilan',
        titleRightText: 'Lettre aux Hébreux 12,4.',
        subtitle: 'Pratiques ascétiques',
        text: 'Car vous n\'avez pas encore résisté jusqu\'au sang, en combattant contre le péché. ',
        closeButton: true,
        cssClass: 'leSaviezVous'
      });
      notificationClickToClose.open();
    });
    $('#bilan-caritas').on('click', function () {
      document.getElementById('bilan-caritas-titre').scrollIntoView();
      var notificationClickToClose = $f7.notification.create({
        icon: '<i class="fas fa-burn"></i>',
        title: 'Bilan',
        titleRightText: '1ère lettre de S. Paul aux Corinthiens 13,4-8',
        subtitle: 'Caritas',
        text: 'La charité est patiente, elle est pleine de bonté. La charité n\'est point envieuse,' + ' elle n\'agit pas avec témérité,' + ' elle ne s\'enfle pas d\'orgueil ; ' + ' elle n\'est pas ambitieuse, elle ne cherche pas ses propres intérêts, elle ne s\'irrite pas, elle ne pense pas le mal, ' + ' elle ne se réjouit pas de l\'injustice, mais elle se réjouit de la vérité ; ' + ' elle souffre tout, elle croit tout, elle espère tout, elle supporte tout.' + '  La charité ne finira jamais; pas même lorsque les prophéties disparaîtront, que les langues cesseront, ' + '  et que la science sera détruite.',
        closeButton: true,
        cssClass: 'leSaviezVous'
      });
      notificationClickToClose.open();
    });
    $('#bilan-fin').on('click', function () {
      document.getElementById('bilan-fin-titre').scrollIntoView();
      var notificationClickToClose = $f7.notification.create({
        icon: '<i class="fas fa-check-circle"></i>',
        title: 'Bilan',
        titleRightText: '2ème lettre de S. Paul à Timothée, 4,7-8',
        subtitle: 'Bilan quotidien terminé !',
        text: 'J\'ai combattu le bon combat, j\'ai achevé ma course, j\'ai gardé la foi.' + ' Reste la couronne de justice qui m\'est réservée, que le Seigneur, le juste juge,' + ' me rendra en ce jour-là; et non seulement à moi, mais aussi à ceux qui aiment Son avènement.',
        closeButton: true,
        cssClass: 'leSaviezVous'
      });
      notificationClickToClose.open();
    });
    displayBilan();
  });

  function displayBilan() {
    profil = $store.getters.profil;
    console.log("//612 bilan.f7 profil.value.validatedActivities", profil.value.validatedActivities);
    if (!profil.value.validatedActivities) profil.value.validatedActivities = []; // Dates formatées

    $(".dateFormatee").html(js_date("j/n/Y")); // ICI on désactive certains choix (et on décoche) si on n'a pas validé offices / prières /méditation quotidienne
    // On change le la couleur de fond en cas de desactivation

    var officeCelebrated,
        priereCelebrated,
        meditationCelebrated,
        messeCelebrated = false;
    var cesValidatedActivites = profil.value.validatedActivities.filter(function (d) {
      return js_date('Y-m-d', d.dateTS) == js_date("Y-m-d");
    });
    console.log("//623 bilan.f7 cesValidatedActivites", cesValidatedActivites);

    for (var i = 0; i < cesValidatedActivites.length; i++) {
      if (cesValidatedActivites[i].type == "office") officeCelebrated = true;
      if (cesValidatedActivites[i].type == "priere") priereCelebrated = true;
      if (cesValidatedActivites[i].type == "meditation") meditationCelebrated = true;
      if (cesValidatedActivites[i].activite == "messe") messeCelebrated = true;
    }

    console.log("//632 bilan.f7 officeCelebrated : ", officeCelebrated);

    if (officeCelebrated) {
      // On active le choix pour les offices hors messe
      $('#asceseBilanOfficeSung').removeClass('item-disabled');
      $('.checkAsceseBilanOffice').removeAttr('disabled');
      $('#asceseBilanOfficeInCommon').removeClass('item-disabled');
    }

    if (messeCelebrated) {
      // On active le choix pour la messe
      $('#asceseBilanParticipationMesse').removeClass('item-disabled');
      $('.checkAsceseBilanMesse').removeAttr('disabled');
    }

    if (meditationCelebrated) {
      // On active le choix pour la méditation
      $('#asceseBilanMeditationNodistraction').removeClass('item-disabled');
      $('.checkAsceseBilanMeditation').removeAttr('disabled');
      $('#asceseBilanMeditationComprehension').removeClass('item-disabled');
      $('#asceseBilanMeditationPersonalprayer').removeClass('item-disabled');
    }

    if (priereCelebrated) {
      // On active le choix pour les prières
      $('#asceseBilanPriereConcentration').removeClass('item-disabled');
      $('.checkAsceseBilanPriere').removeAttr('disabled');
    }

    var bilans = profil.value.bilans;
    if (!bilans) bilans = [];
    var r = bilans.filter(function (d) {
      return d.dateISO == js_date("Y-m-d");
    });
    var ceBilan = r[0]; // Si il existe une valeur bilan dans profil.bilans afin de retrouver le formulaire dans l'état précédent

    if (ceBilan) {
      // asceseBilanOfficeSung
      console.log("676 bilan.f7 #asceseBilanOfficeSung", $('#asceseBilanOfficeSung'));
      document.getElementById("asceseBilanOfficeSung").children[0].checked = ceBilan.asceseBilanOfficeSung.checked; //document.getElementById("asceseBilanOfficeSung").children[2].children[0].children[1].innerHTML=ceBilan.asceseBilanOfficeSung.hour;
      //asceseBilanOfficeInCommon 

      document.getElementById("asceseBilanOfficeInCommon").children[0].checked = ceBilan.asceseBilanOfficeInCommon.checked; //document.getElementById("asceseBilanOfficeInCommon").children[2].children[0].children[1].innerHTML=ceBilan.asceseBilanOfficeInCommon.hour;
      //asceseBilanParticipationMesse

      document.getElementById("asceseBilanParticipationMesse").children[0].checked = ceBilan.asceseBilanParticipationMesse.checked; //document.getElementById("asceseBilanParticipationMesse").children[2].children[0].children[1].innerHTML=ceBilan.asceseBilanParticipationMesse.hour;
      //asceseBilanCommunionReceived

      document.getElementById("asceseBilanCommunionReceived").children[0].checked = ceBilan.asceseBilanCommunionReceived.checked; //document.getElementById("asceseBilanCommunionReceived").children[2].children[0].children[1].innerHTML=ceBilan.asceseBilanCommunionReceived.hour;
      //asceseBilanConfession 

      document.getElementById("asceseBilanConfession").children[0].checked = ceBilan.asceseBilanConfession.checked; //document.getElementById("asceseBilanConfession").children[2].children[0].children[1].innerHTML=ceBilan.asceseBilanConfession.hour;
      //asceseBilanMeditationPrepared

      document.getElementById("asceseBilanMeditationPrepared").children[0].checked = ceBilan.asceseBilanMeditationPrepared.checked; //document.getElementById("asceseBilanMeditationPrepared").children[2].children[0].children[1].innerHTML=ceBilan.asceseBilanMeditationPrepared.hour;
      //asceseBilanMeditationNodistraction

      document.getElementById("asceseBilanMeditationNodistraction").children[0].checked = ceBilan.asceseBilanMeditationNodistraction.checked; //document.getElementById("asceseBilanMeditationNodistraction").children[2].children[0].children[1].innerHTML=ceBilan.asceseBilanMeditationNodistraction.hour;
      //asceseBilanMeditationComprehension

      document.getElementById("asceseBilanMeditationComprehension").children[0].checked = ceBilan.asceseBilanMeditationComprehension.checked; //document.getElementById("asceseBilanMeditationComprehension").children[2].children[0].children[1].innerHTML=ceBilan.asceseBilanMeditationComprehension.hour;
      //asceseBilanMeditationPersonalprayer

      document.getElementById("asceseBilanMeditationPersonalprayer").children[0].checked = ceBilan.asceseBilanMeditationPersonalprayer.checked; //document.getElementById("asceseBilanMeditationPersonalprayer").children[2].children[0].children[1].innerHTML=ceBilan.asceseBilanMeditationPersonalprayer.hour;
      //asceseBilanPriereConcentration

      document.getElementById("asceseBilanPriereConcentration").children[0].checked = ceBilan.asceseBilanPriereConcentration.checked; //document.getElementById("asceseBilanPriereConcentration").children[2].children[0].children[1].innerHTML=ceBilan.asceseBilanPriereConcentration.hour;
      //asceseBilanMortificationNoHotShower

      document.getElementById("asceseBilanMortificationNoHotShower").children[0].checked = ceBilan.asceseBilanMortificationNoHotShower.checked; //document.getElementById("asceseBilanMortificationNoHotShower").children[2].children[0].children[1].innerHTML=ceBilan.asceseBilanMortificationNoHotShower.hour;
      //asceseBilanMortificationNoAlcool

      document.getElementById("asceseBilanMortificationNoAlcool").children[0].checked = ceBilan.asceseBilanMortificationNoAlcool.checked; //document.getElementById("asceseBilanMortificationNoAlcool").children[2].children[0].children[1].innerHTML=ceBilan.asceseBilanMortificationNoAlcool.hour;
      //asceseBilanMortificationNoEcran

      document.getElementById("asceseBilanMortificationNoEcran").children[0].checked = ceBilan.asceseBilanMortificationNoEcran.checked; //document.getElementById("asceseBilanMortificationNoEcran").children[2].children[0].children[1].innerHTML=ceBilan.asceseBilanMortificationNoEcran.hour;
      //asceseBilanSilence

      document.getElementById("asceseBilanSilence").children[0].checked = ceBilan.asceseBilanSilence.checked; //document.getElementById("asceseBilanSilence").children[2].children[0].children[1].innerHTML=ceBilan.asceseBilanSilence.hour;
      //asceseBilanSommeil

      document.getElementById("asceseBilanSommeil").children[0].checked = ceBilan.asceseBilanSommeil.checked; //document.getElementById("asceseBilanSommeil").children[2].children[0].children[1].innerHTML=ceBilan.asceseBilanSommeil.hour;
      //asceseBilanJeune

      document.getElementById("asceseBilanJeune").children[0].checked = ceBilan.asceseBilanJeune.checked; //document.getElementById("asceseBilanJeune").children[2].children[0].children[1].innerHTML=ceBilan.asceseBilanJeune.hour;
      //asceseBilanCaritasPatience

      document.getElementById("asceseBilanCaritasPatience").children[0].checked = ceBilan.asceseBilanCaritasPatience.checked; //document.getElementById("asceseBilanCaritasPatience").children[2].children[0].children[1].innerHTML=ceBilan.asceseBilanCaritasPatience.hour;
      //asceseBilanCaritasDouceur

      document.getElementById("asceseBilanCaritasDouceur").children[0].checked = ceBilan.asceseBilanCaritasDouceur.checked; //document.getElementById("asceseBilanCaritasDouceur").children[2].children[0].children[1].innerHTML=ceBilan.asceseBilanCaritasDouceur.hour;
      //asceseBilanCaritasBonte

      document.getElementById("asceseBilanCaritasBonte").children[0].checked = ceBilan.asceseBilanCaritasBonte.checked; //document.getElementById("asceseBilanCaritasBonte").children[2].children[0].children[1].innerHTML=ceBilan.asceseBilanCaritasBonte.hour;
      //asceseBilanCaritasDesinteressement

      document.getElementById("asceseBilanCaritasDesinteressement").children[0].checked = ceBilan.asceseBilanCaritasDesinteressement.checked; //document.getElementById("asceseBilanCaritasDesinteressement").children[2].children[0].children[1].innerHTML=ceBilan.asceseBilanCaritasDesinteressement.hour;
      //asceseBilanCaritasEndurance

      document.getElementById("asceseBilanCaritasEndurance").children[0].checked = ceBilan.asceseBilanCaritasEndurance.checked; //document.getElementById("asceseBilanCaritasEndurance").children[2].children[0].children[1].innerHTML=ceBilan.asceseBilanCaritasEndurance.hour;
    }

    $('#btnValideBilanQuotidien').on('click', function (e) {
      console.log("//786 bilan.f7 btnValideBilanQuotidien clicked");
      asceseValideBilanQuotiden();
    }); // ICI on met à jour les heures en fonction des modifications des entrées dans le bilan

    $('.item-checkbox').on('click', function (e) {
      asceseBilanUpdateHeureChoix(e);
    });

    function asceseBilanUpdateHeureChoix(e) {
      //console.log("//804 bilan.f7 asceseBilanUpdateHeureChoix(e)  : ",e.path[1].children[0].children[1]);
      //console.log("//805 bilan.f7 asceseBilanUpdateHeureChoix(e)  : name ",e.path[1].children[0].children[1].name);
      var currentTab = document.getElementsByClassName("btnBilan tab-link-active")[0];
      currentTab.getElementsByClassName("badge")[0].classList.remove("no-display");
      ; //e.path[1].children[0].children[1].innerHTML = js_date("H:i");
      //this.getElementsByClassName("item-after")[0].innerHTML = js_date("H:i");
    }
  }
  /* ******* */


  function asceseValideBilanQuotiden() {
    console.log("//788 bilan.f7 asceseValideBilanQuotiden()");
    var bilanQuotidien = {
      dateTS: Date.now() / 1000,
      dateISO: js_date('Y-m-d'),
      asceseBilanOfficeSung: {
        checked: document.getElementById("asceseBilanOfficeSung").children[0].checked,
        hour: document.getElementById("asceseBilanOfficeSung").children[2].children[0].children[1].innerHTML
      },
      asceseBilanOfficeInCommon: {
        checked: document.getElementById("asceseBilanOfficeInCommon").children[0].checked,
        hour: document.getElementById("asceseBilanOfficeInCommon").children[2].children[0].children[1].innerHTML
      },
      asceseBilanParticipationMesse: {
        checked: document.getElementById("asceseBilanParticipationMesse").children[0].checked,
        hour: document.getElementById("asceseBilanParticipationMesse").children[2].children[0].children[1].innerHTML
      },
      asceseBilanCommunionReceived: {
        checked: document.getElementById("asceseBilanCommunionReceived").children[0].checked,
        hour: document.getElementById("asceseBilanCommunionReceived").children[2].children[0].children[1].innerHTML
      },
      asceseBilanConfession: {
        checked: document.getElementById("asceseBilanConfession").children[0].checked,
        hour: document.getElementById("asceseBilanConfession").children[2].children[0].children[1].innerHTML
      },
      asceseBilanMeditationPrepared: {
        checked: document.getElementById("asceseBilanMeditationPrepared").children[0].checked,
        hour: document.getElementById("asceseBilanMeditationPrepared").children[2].children[0].children[1].innerHTML
      },
      asceseBilanMeditationNodistraction: {
        checked: document.getElementById("asceseBilanMeditationNodistraction").children[0].checked,
        hour: document.getElementById("asceseBilanMeditationNodistraction").children[2].children[0].children[1].innerHTML
      },
      asceseBilanMeditationComprehension: {
        checked: document.getElementById("asceseBilanMeditationComprehension").children[0].checked,
        hour: document.getElementById("asceseBilanMeditationComprehension").children[2].children[0].children[1].innerHTML
      },
      asceseBilanMeditationPersonalprayer: {
        checked: document.getElementById("asceseBilanMeditationPersonalprayer").children[0].checked,
        hour: document.getElementById("asceseBilanMeditationPersonalprayer").children[2].children[0].children[1].innerHTML
      },
      asceseBilanPriereConcentration: {
        checked: document.getElementById("asceseBilanPriereConcentration").children[0].checked,
        hour: document.getElementById("asceseBilanPriereConcentration").children[2].children[0].children[1].innerHTML
      },
      asceseBilanMortificationNoHotShower: {
        checked: document.getElementById("asceseBilanMortificationNoHotShower").children[0].checked,
        hour: document.getElementById("asceseBilanMortificationNoHotShower").children[2].children[0].children[1].innerHTML
      },
      asceseBilanMortificationNoAlcool: {
        checked: document.getElementById("asceseBilanMortificationNoAlcool").children[0].checked,
        hour: document.getElementById("asceseBilanMortificationNoAlcool").children[2].children[0].children[1].innerHTML
      },
      asceseBilanMortificationNoEcran: {
        checked: document.getElementById("asceseBilanMortificationNoEcran").children[0].checked,
        hour: document.getElementById("asceseBilanMortificationNoEcran").children[2].children[0].children[1].innerHTML
      },
      asceseBilanSilence: {
        checked: document.getElementById("asceseBilanSilence").children[0].checked,
        hour: document.getElementById("asceseBilanSilence").children[2].children[0].children[1].innerHTML
      },
      asceseBilanSommeil: {
        checked: document.getElementById("asceseBilanSommeil").children[0].checked,
        hour: document.getElementById("asceseBilanSommeil").children[2].children[0].children[1].innerHTML
      },
      asceseBilanJeune: {
        checked: document.getElementById("asceseBilanJeune").children[0].checked,
        hour: document.getElementById("asceseBilanJeune").children[2].children[0].children[1].innerHTML
      },
      asceseBilanCaritasPatience: {
        checked: document.getElementById("asceseBilanCaritasPatience").children[0].checked,
        hour: document.getElementById("asceseBilanCaritasPatience").children[2].children[0].children[1].innerHTML
      },
      asceseBilanCaritasDouceur: {
        checked: document.getElementById("asceseBilanCaritasDouceur").children[0].checked,
        hour: document.getElementById("asceseBilanCaritasDouceur").children[2].children[0].children[1].innerHTML
      },
      asceseBilanCaritasBonte: {
        checked: document.getElementById("asceseBilanCaritasBonte").children[0].checked,
        hour: document.getElementById("asceseBilanCaritasBonte").children[2].children[0].children[1].innerHTML
      },
      asceseBilanCaritasDesinteressement: {
        checked: document.getElementById("asceseBilanCaritasDesinteressement").children[0].checked,
        hour: document.getElementById("asceseBilanCaritasDesinteressement").children[2].children[0].children[1].innerHTML
      },
      asceseBilanCaritasEndurance: {
        checked: document.getElementById("asceseBilanCaritasEndurance").children[0].checked,
        hour: document.getElementById("asceseBilanCaritasEndurance").children[2].children[0].children[1].innerHTML
      }
    };
    console.log("//878 bilan.f7 Bilan mis à jour pour le " + js_date("Y-m-d") + " : ", bilanQuotidien);
    var profil = $store.getters.profil;

    if (!profil.value.bilans) {
      profil.value.bilans = [];
    }

    if (!profil.value.bilans.filter(function (d) {
      return d.dateISO == js_date("Y-m-d");
    })[0]) {
      profil.value.bilans.push(bilanQuotidien);
    }

    if (profil.value.bilans.filter(function (d) {
      return d.dateISO == js_date("Y-m-d");
    })[0]) {
      // Ici on enregistre la nouvelle valeur de bilans dans profil.value 
      console.log("//940 bilan.f7 Ici on enregistre la nouvelle valeur de bilans dans profil.value "); //profil.value.bilans.filter(d => d.dateISO == js_date("Y-m-d"))[0] = bilanQuotidien;

      profil.value.bilans[profil.value.bilans.length - 1] = bilanQuotidien;
      console.log("//942 bilan.f7 bilanQuotidien : ", bilanQuotidien);
    }

    console.log("//943 bilan.f7 bilan d'aujourd'hui dans profil : ", profil.value.bilans.filter(function (d) {
      return d.dateISO == js_date("Y-m-d");
    })[0]);
    $store.dispatch('updateProfil', profil.value); // event profil updated

    $f7.emit('profilUpdated'); // downSyncSyncedUtilisateurs via un event

    $f7.emit('utilisateurUpdated');
    console.log("//949 bilan.f7 profil.value.bilans : ", profil.value.bilans); //Affichage notification

    var notificationFull = $f7.notification.create({
      icon: '<i class="f7-icons">info_circle</i>',
      title: 'Bilan',
      titleRightText: 'Maintenant',
      subtitle: '',
      text: "Votre bilan a été mis à jour. Vous pouvez le modifier jusqu\'à minuit.",
      closeTimeout: 3000
    });
    notificationFull.open(); //profilCalculStatistiques();
    //TODO event calcul stats / profil updated 

    $f7.emit('profilUpdated', true); //mute = true
  }
  /* ******* */


  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
  <div class="page" data-name="bilan">
    <div class="navbar">
      <div class="navbar-bg"></div>
      <div class="navbar-inner">
        <div class="left">
          <a href="#" class="link back">
            <i class="icon icon-back"></i>
            <span class="if-not-md">Retour</span>
          </a>
        </div>
        
        <div class="title">Bilan pour le <span class="dateFormatee"></span></div>
        <div class="right">
			<a href="#inv" class="tab-link icon-only inviter-popup-link">
				<i class="fas fa-user-plus"></i> 
			</a>
			<a href="#view-messages" class="tab-link icon-only">
				<i class="icon material-icons">mail_outline<span class="badge color-red newMessages" id="newMessages">3</span></i> 
			</a>
        </div>
      <div class="subnavbar">
				<div class="subnavbar-inner">
				  <div class="segmented segmented-strong bg-color-yellow">
					<a id="bilan-liturgie" class="button tab-link btnBilan tab-link-active" href="#tab1"><i class="fas fa-bible "><span class="badge bilanOngletChecked no-display"><i class="fas fa-check badge-bilan-checked"></i></span></i></a>
					<a id="bilan-piete" class="button tab-link btnBilan" href="#tab2"><i class="fas fa-praying-hands"><span class="badge bilanOngletChecked no-display"><i class="fas fa-check badge-bilan-checked "></i></span></i></a>
					<a id="bilan-ascese" class="button tab-link btnBilan" href="#tab3"><i class="fas fa-pray"><span class="badge bilanOngletChecked no-display"><i class="fas fa-check badge-bilan-checked "></i></span></i></a>
					<a id="bilan-caritas" class="button tab-link btnBilan" href="#tab4"><i class="fas fa-burn"><span class="badge bilanOngletChecked no-display"><i class="fas fa-check badge-bilan-checked "></i></span></i></a>
					<a id="bilan-fin" class="button tab-link btnBilan" href="#tab5"><i class="fas fa-check-circle"></i></a>
					<span class="segmented-highlight"></span>
				  </div>
				</div>
			  </div>
      </div>
    </div>
    <!-- navbar -->
    <div class="page-content">	
		<div class="tabs" id="form-bilan">
				<div class="tab tab-active" id="tab1">
					<p id="bilan-liturgie-titre"><br /><br /></p>
					<div class="block title"><h3>Liturgie et sacrements</h3></div>
						
					<div class="list media-list">
						  <ul>
							<li class="bilanDeactivated">
							  <label class="item-checkbox item-content item-disabled" id="asceseBilanOfficeSung">
								<input type="checkbox" disabled="true" value="1" class="checkAsceseBilanOffice"/>
								<i class="icon icon-checkbox"></i>
								<div class="item-inner">
								  <div class="item-title-row">
									<div class="item-title">Office divin</div>
									<div class="item-after"></div>
								  </div>
								  <div class="item-subtitle">J'ai chanté l'office.</div>
								  <div class="item-text">L'office divin est conçu pour être chanté et non simplement récité.</div>
								</div>
							  </label>
							</li>
							<li class="bilanDeactivated">
							  <label class="item-checkbox item-content item-disabled" id="asceseBilanOfficeInCommon">
								<input type="checkbox" disabled="true" value="1" class="checkAsceseBilanOffice"/>
								<i class="icon icon-checkbox"></i>
								<div class="item-inner">
								  <div class="item-title-row">
									<div class="item-title">Office divin</div>
									<div class="item-after"></div>
								  </div>
								  <div class="item-subtitle">J'ai célébré l'office divin <br/> avec d'autres personnes.</div>
								  <div class="item-text">L'office divin est conçu pour célébré en communauté.</div>
								</div>
							  </label>
							</li>
							<li class="bilanDeactivated">
							  <label class="item-checkbox item-content item-disabled" id="asceseBilanParticipationMesse">
								<input type="checkbox" disabled="true" value="1" class="checkAsceseBilanOffice"/>
								<i class="icon icon-checkbox"></i>
								<div class="item-inner">
								  <div class="item-title-row">
									<div class="item-title">Messe</div>
									<div class="item-after"></div>
								  </div>
								  <div class="item-subtitle">J'ai participé à la Messe.</div>
								  <div class="item-text">Je n'ai pas uniquement lu les textes sur l'application.</div>
								</div>
							  </label>
							</li>
							<li>
							  <label class="item-checkbox item-content" id="asceseBilanCommunionReceived">
								<input type="checkbox" value="1" class="checkAsceseBilanOffice"/>
								<i class="icon icon-checkbox"></i>
								<div class="item-inner">
								  <div class="item-title-row">
									<div class="item-title">Eucharistie</div>
									<div class="item-after"></div>
								  </div>
								  <div class="item-subtitle">Sainte communion.</div>
								  <div class="item-text">J'ai reçu le Très Saint Sacrement.</div>
								</div>
							  </label>
							</li>
							<li>
							  <label class="item-checkbox item-content" id="asceseBilanConfession">
								<input type="checkbox" value="1"/>
								<i class="icon icon-checkbox"></i>
								<div class="item-inner">
								  <div class="item-title-row">
									<div class="item-title">Réconciliation</div>
									<div class="item-after"></div>
								  </div>
								  <div class="item-subtitle">Je me suis confessé.</div>
								  <div class="item-text">J'ai reçu l'absolution. J'ai effectué ma pénitence.</div>
								</div>
							  </label>
							</li>
						  </ul>
						</div>
					<!-- list media-list -->
					<div class="block contentJustify sky">
						<p>La prière est pour l’homme le premier des biens. Elle est sa lumière, sa nourriture, 
						sa vie même, puisqu’elle le met en rapport avec Dieu, qui est lumière, nourriture 
						et vie. Mais, de nous-mêmes, nous ne savons pas prier comme il faut ; il est nécessaire
						 que nous nous adressions à Jésus-Christ, et que nous lui disions comme les Apôtres : 
						 Seigneur, enseignez-nous à prier. Lui seul peut délier la langue des muets, rendre diserte 
						 la bouche des enfants, et il fait ce prodige en envoyant son Esprit de grâce et de prières, 
						 qui prend plaisir à aider notre faiblesse, suppliant en nous par un gémissement inénarrable.</p>
						<p>Or, sur cette terre, c’est dans la sainte Église que réside ce divin Esprit. Il est descendu 
						vers elle comme un souffle impétueux, en même temps qu’il apparaissait sous l’emblème expressif 
						de langues enflammées. Depuis lors, il fait sa demeure dans cette heureuse Épouse ; il est le principe
						 de ses mouvements ; il lui impose ses demandes, ses vœux, ses cantiques de louange, son enthousiasme
						  et ses soupirs. De là vient que, depuis dix-huit siècles, elle ne se tait ni le jour, ni la nuit ; 
						  et sa voix est toujours mélodieuse, sa parole va toujours au cœur de l’Époux.</p>
						<p>Tantôt, sous l’impression de cet Esprit qui anima le divin Psalmiste et les Prophètes, 
						elle puise dans les Livres de l’ancien Peuple le thème de ses chants ; tantôt, fille et sœur des saints
						 Apôtres, elle entonne les cantiques insérés aux Livres de la Nouvelle Alliance ; tantôt enfin,
						  se souvenant qu’elle aussi a reçu la trompette et la harpe, elle donne passage à l’Esprit qui l’anime,
						   et chante à son tour un cantique nouveau ; de cette triple source émane l’élément divin 
						   qu’on nomme la Liturgie.</p>
						<div class="block-footer">Dom Prosper Guéranger, <q>Préface générale à L'année liturgique</q>.</div>	
					</div>
					<!-- block contentJustify sky -->
			  </div>
				<!-- tab1 -->
				
				<div class="tab" id="tab2">
					<p id="bilan-piete-titre"><br /><br /></p>
					<div  class="block title"><h3>Piété (méditations et autres prières.)</h3></div>
					
					<div class="list media-list">
						  <ul>
							<li>
							  <label class="item-checkbox item-content" id="asceseBilanMeditationPrepared">
								<input type="checkbox" value="1"/>
								<i class="icon icon-checkbox" ></i>
								<div class="item-inner">
								  <div class="item-title-row">
									<div class="item-title">Préparation</div>
									<div class="item-after"></div>
								  </div>
								  <div class="item-subtitle">Méditation</div>
								  <div class="item-text">J'ai préparé la méditation en lisant le texte la veille.</div>
								</div>
							  </label>
							</li>
							<li class="bilanDeactivated">
							  <label class="item-checkbox item-content item-disabled" id="asceseBilanMeditationNodistraction">
								<input type="checkbox" disabled="true" value="1" class="checkAsceseBilanMeditation"/>
								<i class="icon icon-checkbox"></i>
								<div class="item-inner">
								  <div class="item-title-row">
									<div class="item-title">Distractions </div>
									<div class="item-after"></div>
								  </div>
								  <div class="item-subtitle">Méditation</div>
								  <div class="item-text">Je me suis isolé pour tirer tous les fruits de la méditation.</div>
								</div>
							  </label>
							</li>
							<li class="bilanDeactivated">
							  <label class="item-checkbox item-content item-disabled" id="asceseBilanMeditationComprehension">
								<input type="checkbox" disabled="true" value="1" class="checkAsceseBilanMeditation" />
								<i class="icon icon-checkbox"></i>
								<div class="item-inner">
								  <div class="item-title-row">
									<div class="item-title">Compréhension</div>
									<div class="item-after"></div>
								  </div>
								  <div class="item-subtitle">Méditation</div>
								  <div class="item-text">J'ai bien lu et compris chacun des paragraphes.</div>
								</div>
							  </label>
							</li>
							<li class="bilanDeactivated">
							  <label class="item-checkbox item-content item-disabled" id="asceseBilanMeditationPersonalprayer">
								<input type="checkbox" disabled="true" value="1" class="checkAsceseBilanMeditation" />
								<i class="icon icon-checkbox"></i>
								<div class="item-inner">
								  <div class="item-title-row">
									<div class="item-title">Implication </div>
									<div class="item-after"></div>
								  </div>
								  <div class="item-subtitle">Méditaton</div>
								  <div class="item-text">J'ai pu méditer et prier personellement.</div>
								</div>
							  </label>
							</li>
							<li class="bilanDeactivated">
							  <label class="item-checkbox item-content item-disabled" id="asceseBilanPriereConcentration">
								<input type="checkbox" disabled="true" value="1" class="checkAsceseBilanPriere" />
								<i class="icon icon-checkbox"></i>
								<div class="item-inner">
								  <div class="item-title-row">
									<div class="item-title">Autres prières</div>
									<div class="item-after"></div>
								  </div>
								  <div class="item-subtitle">Concentration.</div>
								  <div class="item-text">Il est préférable de prier intensément et brièvement.</div>
								</div>
							  </label>
							</li>
						  </ul>

						</div>
					<!-- list media-list -->
					<div class="block contentJustify sky">
					<p>Il faut avoir un modèle que vous puissiez regarder sans cesse, méditer et vous approprier 
					de manière à vous élever peu à peu à des pensées plus parfaites. Voici cette règle que vous cherchez, 
					cette formule de la prière , que tout religieux qui désire se souvenir continuellement de Dieu, 
					doit s’accoutumer à méditer sans cesse dans son coeur, en en bannissant toute autre pensée ; 
					car il ne pourra jamais la retenir s’il ne s’affranchit de toute inquiétude et de tous soins corporels.
					</p>

					<p>C’est un secret que nous ont laissé quelques-uns de nos anciens Pères, et que nous ne disons qu’au 
					petit nombre de personnes qui le désirent avec ardeur. Cette formule qui vous rappellera toujours Dieu, 
					et dont vous ne devez jamais vous séparer, est celle-ci : <b>« Mon Dieu, venez à mon aide ; hâtez-vous, 
					Seigneur, de me secourir. »</b>
					</p>

					<p>Ainsi, nous devons sans cesse adresser à Dieu cette courte prière, afin de n'être pas abattus par 
					l'adversité, ou orgueilleux dans la prospérité. Oui, méditez sans cesse ce verset dans votre coeur, 
					récitez-le pendant votre travail, au milieu de vos occupations et lorsque vous êtes en voyage. Que votre 
					esprit s'en nourrisse en dormant, en mangeant, en subissant toutes les nécessités de la nature; 
					que sa méditation devienne pour vous comme une formule puissante et salutaire qui non-seulement vous 
					préservera de toutes les attaques du démon, mais encore vous purifiera des vices et de la contagion 
					de la terre, pour vous élever à la contemplation des choses invisibles et célestes, et vous faire arriver 
					à cette ineffable ardeur de la prière, que bien peu connaissent.
					</p>

					<p>Endormez-vous en récitant ce verset , de manière que, par habitude , vous le disiez encore pendant votre
					 sommeil ; et lorsque vous vous réveillerez, que ce soit la première chose qui se présente à votre esprit.
					  Dites-le en vous agenouillant, dès que vous quittez votre lit, et qu'il vous accompagne ainsi d'action
					   en action pendant tout le cours de la journée.
					   </p>

					<p>Méditez-le selon le précepte divin : « soit que vous reposiez dans votre maison, soit que vous soyez
					 en voyage , soit que vous dormiez , soit que vous vous leviez. Ecrivez-le sur vos lèvres et sur votre porte ;
					  gravez-le sur les murs de votre demeure et au plus profond de votre âme », afin qu'il en découle 
					  naturellement, lorsque vous vous mettez en prière, et qu'il vous accompagne ensuite comme une oraison
					   fervente et continuelle dans toutes les occupations de votre vie.
					   </p>

						<div class="block-footer">Saint Jean Cassien <q>Dixième conférence sur la prière</q>.
						</div>
					</div>	
					<!-- block contentJustify sky -->
				</div>
				<!-- tab2 -->
				
				<div class="tab" id="tab3">
					<p id="bilan-ascese-titre"><br /><br /></p>
					<div class="block title"><h3>Pratiques ascétiques</h3></div>
					
					<div class="list media-list">
						  <ul>
							<li>
							  <label class="item-checkbox item-content" id="asceseBilanMortificationNoHotShower">
								<input type="checkbox" value="1"/>
								<i class="icon icon-checkbox"></i>
								<div class="item-inner">
								  <div class="item-title-row">
									<div class="item-title">Douche </div>
									<div class="item-after"></div>
								  </div>
								  <div class="item-subtitle">Mortification</div>
								  <div class="item-text">Je n'ai pas pris de douche chaude.</div>
								</div>
							  </label>
							</li>
							<li>
							  <label class="item-checkbox item-content" id="asceseBilanMortificationNoAlcool">
								<input type="checkbox" value="1"/>
								<i class="icon icon-checkbox"></i>
								<div class="item-inner">
								  <div class="item-title-row">
									<div class="item-title">Alcool </div>
									<div class="item-after"></div>
								  </div>
								  <div class="item-subtitle">Mortification</div>
								  <div class="item-text">Je n'ai pas pris de vin ou d'autres boissons alcoolisées.</div>
								</div>
							  </label>
							</li>
							<li>
							  <label class="item-checkbox item-content" id="asceseBilanMortificationNoEcran">
								<input type="checkbox" value="1"/>
								<i class="icon icon-checkbox"></i>
								<div class="item-inner">
								  <div class="item-title-row">
									<div class="item-title">Ecrans</div>
									<div class="item-after"></div>
								  </div>
								  <div class="item-subtitle">Mortification</div>
								  <div class="item-text">Je n'ai pas consulté d'ecrans sans vraie nécessité.</div>
								</div>
							  </label>
							</li>
							<li>
							  <label class="item-checkbox item-content" id="asceseBilanSilence">
								<input type="checkbox" value="1"/>
								<i class="icon icon-checkbox"></i>
								<div class="item-inner">
								  <div class="item-title-row">
									<div class="item-title">Silence</div>
									<div class="item-after"></div>
								  </div>
								  <div class="item-subtitle">Radio ou télévision</div>
								  <div class="item-text">Je n'ai pas écouté la radio, la télévision ou de musique d'ambiance.</div>
								</div>
							  </label>
							</li>
							<li>
							  <label class="item-checkbox item-content" id="asceseBilanSommeil">
								<input type="checkbox" value="1"/>
								<i class="icon icon-checkbox"></i>
								<div class="item-inner">
								  <div class="item-title-row">
									<div class="item-title">Sommeil</div>
									<div class="item-after"></div>
								  </div>
								  <div class="item-subtitle">J'ai suffisamment dormi.</div>
								</div>
							  </label>
							</li>
							<li>
							  <label class="item-checkbox item-content" id="asceseBilanJeune">
								<input type="checkbox" value="1"/>
								<i class="icon icon-checkbox"></i>
								<div class="item-inner">
								  <div class="item-title-row">
									<div class="item-title">Jeûne</div>
									<div class="item-after"></div>
								  </div>
								  <div class="item-subtitle">J'ai jeûné.</div>
								</div>
							  </label>
							</li>
						  </ul>
					</div>
					<!-- list media-list -->
					<div class="block contentJustify sky">
					<p>La première chose que vous avez à faire à votre réveil, c’est d’ouvrir les yeux 
					de l’âme et de vous considérer comme en un champ clos, avec cette loi expresse que 
					celui qui ne combat pas doit périr à jamais. Là, vous vous figurerez être en présence 
					de votre ennemi, je veux dire de cette inclination mauvaise que vous avez déjà entrepris 
					de combattre et qui se tient tout armée pour vous blesser et vous donner la mort.</p> 
					<p>À votre droite, vous verrez Jésus-Christ votre invincible capitaine, la Vierge Marie 
					avec Saint Joseph son époux bien-aimé, d’innombrables troupes d’anges et de saints, parmi 
					lesquels l’archange saint Michel ; à votre gauche, vous verrez le démon et ses satellites 
					prêts à exciter la passion ennemie et à vous persuader de céder à ses suggestions. 
					Vous vous imaginerez alors entendre la voix de votre ange gardien, vous parlant de la sorte : 
					« Vous avez aujourd’hui à combattre contre cet ennemi, et contre d’autres encore. Ne craignez point, 
					ne perdez point courage ; ne cédez ni à la frayeur ni à quelque considération que ce soit ; 
					car votre Seigneur et votre capitaine est ici près de vous avec ses glorieuses phalanges, 
					pour combattre avec elles contre vos ennemis et il ne souffrira pas qu’ils vous soumettent 
					par la force ou la ruse. </p>
					<p>Demeurez ferme, faites-vous violence, quoiqu’il doive vous en coûter parfois. Criez souvent 
					au secours du plus profond de votre cœur ; appelez à votre aide votre Seigneur, la Vierge Marie 
					et tous les saints, et vous remporterez infailliblement la victoire.</p>
					<div class="block-footer">Lorenzo Scupoli, <q>Le combat spirituel</q> (chap. XVI), Trad. R.P. Jean Brignon, Chez Durand, Paris, 1774.</div>
					</div>
					<!-- block contentJustify sky -->
				</div>
				<!-- tab3 -->
				
				<div class="tab" id="tab4">
					<p id="bilan-caritas-titre"><br /><br /></p>
					<div class="block title"><h3>Caritas</h3></div>
					
					<div class="list media-list">
					  <ul>
						<li>
						  <label class="item-checkbox item-content" id="asceseBilanCaritasPatience">
							<input name="asceseBilanCaritasPatience" type="checkbox" value="1"/>
							<i class="icon icon-checkbox"></i>
							<div class="item-inner">
							  <div class="item-title-row">
								<div class="item-title">Patience</div>
								<div class="item-after" name="hourAsceseBilanCaritasPatience"></div>
							  </div>
							  <div class="item-subtitle">1 Cor 13,4-8</div>
							  <div class="item-text">J'ai été patient envers mes proches, collaborateurs, chefs et subordonnés.</div>
							</div>
						  </label>
						</li>
						<li>
						  <label class="item-checkbox item-content" id="asceseBilanCaritasDouceur">
							<input name="asceseBilanCaritasDouceur" type="checkbox" value="1"/>
							<i class="icon icon-checkbox"></i>
							<div class="item-inner">
							  <div class="item-title-row">
								<div class="item-title">Douceur</div>
								<div class="item-after" name="hourAsceseBilanCaritasDouceur"></div>
							  </div>
							  <div class="item-subtitle">1 Cor 13,4-8</div>
							  <div class="item-text">J'ai été doux et bienfaisant envers autrui.</div>
							</div>
						  </label>
						</li>
						<li>
						  <label class="item-checkbox item-content" id="asceseBilanCaritasBonte">
							<input name="asceseBilanCaritasBonte" type="checkbox" value="1"/>
							<i class="icon icon-checkbox"></i>
							<div class="item-inner">
							  <div class="item-title-row">
								<div class="item-title">Bonté</div>
								<div class="item-after" name="hourAsceseBilanCaritasBonte"></div>
							  </div>
							  <div class="item-subtitle">1 Cor 13,4-8</div>
							  <div class="item-text">J'ai, sans sentiment d'envie, remercié Dieu pour ce que les autres ont et sont.</div>
							</div>
						  </label>
						</li>
						<li>
						  <label class="item-checkbox item-content" id="asceseBilanCaritasDesinteressement">
							<input name="asceseBilanCaritasDesinteressement" type="checkbox" value="1"/>
							<i class="icon icon-checkbox"></i>
							<div class="item-inner">
							  <div class="item-title-row">
								<div class="item-title">Désinteressement</div>
								<div class="item-after" name="hourAsceseBilanCaritasDesinteressement"></div>
							  </div>
							  <div class="item-subtitle">1 Cor 13,4-8</div>
							  <div class="item-text">J'ai d'abord cherché l'intérêt d'autrui.</div>
							</div>
						  </label>
						</li>
						<li>
						  <label class="item-checkbox item-content" id="asceseBilanCaritasEndurance">
							<input name="asceseBilanCaritasEndurance" type="checkbox" value="1"/>
							<i class="icon icon-checkbox"></i>
							<div class="item-inner">
							  <div class="item-title-row">
								<div class="item-title">Endurance</div>
								<div class="item-after" name="hourAsceseBilanCaritasEndurance"></div>
							  </div>
							  <div class="item-subtitle">1 Cor 13,4-8 </div>
							  <div class="item-text">J'ai subi les torts causés dans l'espérance, en silence et avec humilité .</div>
							</div>
						  </label>
						</li>
					  </ul>
					</div>
					<!-- list media-list -->
					<div class="block contentJustify sky">
					
					<p>Etre affermi dans le chemin du salut, c'est être enraciné dans la charité ; c'est avoir la vigueur, 
					et non pas seulement l'apparence de la piété. Cette apparence est déjà précieuse, elle est sainte; mais à quoi bon l'apparence sans 
					la racine de la charité? Ne jette-t-on pas au feu le sarment qu'on a retranché du cep? Aie donc cette forme de la piété, mais qu'elle 
					soit soutenue par la charité. Or, comment vous enraciner de manière a ne pas être déracinés? Attachez-vous à la charité, 
					selon l'expression de l'Apôtre Paul: «Soyez enracinés et fondés dans la charité (Eph 3,17)». </p>
					<p>Et toutefois, par quel moyen s'enracinera-t-elle 
					en nous au milieu des ronces et des épines que l'amour du monde y fait croître en foule? 
					Arrachez tous ces buissons sauvages: vous devez jeter dans le champ de votre âme une semence qui réclame beaucoup de terrain; 
					il n'y faut, par conséquent, rien laisser dont cette semence puisse souffrir; voici qui nous aidera à défricher le champ de votre âme; 
					ce sont les paroles de Jean, précédemment citées: «N'aimez ni le monde, ni ce qui est dans le monde; si quelqu'un aime le monde, 
					la charité du Père n'est point en lui.</p>
					<div class="block-footer">Saint Augustin, <q>Traités sur l'Epitre de S. Jean aux Parthes, Deuxième traité. (Chap. 2,12-17) La Charité, sourcs de lumières</q>.</div>
					</div>
					<!-- block contentJustify sky -->
					<br />
					<div class="block contentJustify sky">
					<p>
					Il en est peut-être parmi vous pour s'étonner de ce que, depuis le premier mot de la lettre que nous vous expliquons, 
					le bienheureux Jean ne nous ait rien recommandé aussi expressément que la charité fraternelle.
					</p> 
					<p>Il dit : « Celui qui aime son frère (1 Jn 2, 10) » et encore : « Un commandement nous a été donné, c'est de nous aimer les uns les autres (1 Jn 3, 23) ». 
					Continuellement il nous a parlé de la charité fraternelle pour l'amour de Dieu, c'est-à-dire, pour cet amour que nous devons avoir pour Dieu, 
					il n'en a point parlé aussi souvent et cependant il n'a pas tout à fait gardé le silence à son égard.
					</p> 
					<p>Quant à l'amour des ennemis, il n'y a, à vrai dire, fait presque aucune allusion dans le cours de cette lettre. 
					En nous prêchant, en nous recommandant vivement la charité, il ne nous dit pas d'aimer nos ennemis, mais il nous dit d'aimer nos frères. 
					Lorsque, tout à l'heure, on nous a lu l'évangile, nous avons entendu ces paroles : « Si vous aimez ceux qui vous aiment, quelle récompense en aurez-vous?
					</p>
					 	
					<p>Les publicains ne le font-ils pas aussi (Mt 5, 46) ». Pourquoi donc l'apôtre Jean attache-t-il à la charité fraternelle une si grande importance
					 qu'il nous la recommande comme un moyen d'être parfaits, tandis que d'après le Sauveur, il ne suffit pas d'aimer ses frères, mais qu'il faut pousser 
					 la charité au point d'aimer même ses ennemis? Celui qui va jusqu'à aimer ses ennemis, ne néglige point d'aimer ses frères. 
					 </p>
					
					 <p>
					 Il en est d'elle comme du feu : il lui faut d'abord s'attaquer aux objets les plus rapprochés pour de là s'étendre jusqu'aux plus éloignés. 
					 Ton frère est plus proche de toi que je ne sais quel homme. À son tour, cet homme que tu ne connaissais pas encore et qui ne nourrit contre 
					 toi aucune animosité, est plus rapproché de toi qu'un ennemi qui joint l'action à ses mauvais sentiments. Étends ta charité jusqu'aux plus proches, 
					 mais ne dis pas que tu l'as étendue, car aimer ceux qui te touchent c'est aimer non loin de toi; étends-la jusqu'à ceux que tu ne connais pas 
					 et qui ne t'ont fait aucun mal. Va même plus loin qu'eux, pousse la charité jusqu'à aimer tes ennemis, sans aucun doute, Dieu le commande. 
					 Mais pourquoi n'a-t-il point parlé de l'amour des ennemis ?
					 </p>
				
					<p>Toute charité, même celle qu'on nomme charnelle et qui est à vrai dire, non pas la charité mais bien plutôt l’amour (car le mot charité 
					se dit d'ordinaire dans les meilleures occasions, on l'emploie pour signifier les plus nobles sentiments) toute charité, mes très-chers frères, 
					présuppose un certain bon vouloir pour ceux que l'on aime. Le Sauveur s'était lui-même servi du mot aimer, quand il a dit : « Pierre, m'aimes-tu ? » (Jn 21, 17) 
					Nous ne devons pas chérir, nous ne pouvons pas chérir ou aimer les hommes de toute manière, nous ne devons pas les aimer dans le sens qu'attachent 
					à ce mot les viveurs lorsque nous les entendons dire : « J'aime les grives ». Tu demandes pourquoi ? Parce qu'ils veulent les tuer et les manger. 
					</p>
					
					<p>Ces gens-là disent qu'ils aiment les grives, mais c'est pour les anéantir, c'est pour qu'il n'en soit plus question et tout ce que nous aimons 
					dans le dessein de le manger, nous l'aimons pour en faire la fin et réparer nos forces. Devons-nous aimer les hommes comme s'ils étaient réservés 
					à nous nourrir? Mais il y a une amitié de bienveillance qui nous porte parfois à rendre service à ceux que nous aimons. 
					</p>
					
					<p>Qu'en sera-t-il si nous ne pouvons leur être utiles ? Alors notre amitié se bornera à être bienveillante. Car nous ne devons pas désirer qu'il y ait des malheureux, 
					pour avoir l'occasion de faire des oeuvres de miséricorde. Tu donnes du pain à celui qui a faim, mais il vaudrait mieux que personne n'eût faim et que tu ne fusses généreux 
					à l'égard de personne. Tu donnes des vêtements à celui qui est nu, plaise à Dieu que tous soient vêtus et qu'aucun ne se trouve dans la nécessité à cet égard ! 
					Tu ensevelis les morts; si seulement commençait bientôt cette vie bienheureuse au sein de laquelle personne ne mourra ! 
					Tu rétablis la concorde parmi des dissidents, ah, que bientôt encore vienne cette paix de la Jérusalem éternelle qui ne sera troublée par aucune discorde! 
					Tous les bons offices sont nécessités par le besoin d'autrui. Fais disparaître les malheureux, à l'instant même cesseront les oeuvres de miséricorde. 
					Les oeuvres de miséricorde cesseront, mais le feu de la charité s'éteindra-t-il? 
					</p>
					
					<p>Tu aimes de meilleur coeur l'homme heureux à qui tu n'as à rendre aucun service, en ce cas ton amitié est plus pure et beaucoup plus sincère. 
					De fait si tu viens en aide à un malheureux, peut-être désires-tu t'élever au-dessus de lui, peut-être veux-tu voir au-dessous de toi celui 
					qui est la cause du bien que tu as fait. Cet homme s'est trouvé dans le besoin, tu as subvenu à ses nécessités, 
					parce que tu l'as aidé il te semble être plus grand que celui à qui tu as rendu service. Désire l'avoir pour égal, 
					afin que tous deux vous soyez soumis à Celui-là seul qui n'a besoin de rien.
					</p>
					
					<div class="block-footer">Saint Augustin <q>Commentaire de la première lettre de saint Jean</q> (Sermon 8, n° 4-5)</div>
					</div>
					<!-- block contentJustify sky -->
				</div>
				<!-- tab4 -->
				
				<div class="tab" id="tab5">
					<p id="bilan-fin-titre"><br /><br /></p>
					<div class="block title"><h3>Bilan quotidien terminé !</h3></div>
					
					<div class="block block-strong">
						<button id="btnValideBilanQuotidien" class="col button button-raised button-fill button-round color-orange">Validez pour le : <span class="dateFormatee"></span>
						</button>
					</div>
					<!-- btnValideBilanQuotidien -->
					<div class="block contentJustify sky">
						<p>Qui que tu sois donc, qui te hâtes vers la patrie céleste, accomplis, avec l’aide du Christ, 
						cette ébauche de règle écrite pour des débutants. C'est alors aux sommets de doctrine et de vertu évoqués plus haut que, 
						sous la protection de Dieu, tu parviendras. Amen.
						</p>
						<div class="block-footer">Saint Benoît <q>Regula monachorum</q> Dernier chapitre.</div>
						
					</div>
					<!-- block contentJustify sky -->
				</div>
				<!-- tab5 -->
				
				
			</div>
			<!-- tabs -->  
	</div> 
	<!-- page content -->
		
  </div>
`
    }
    ;
}

framework7Component.id = '631778eb86';
export default framework7Component;