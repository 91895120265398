/** @jsx $jsx */
import { $jsx } from 'framework7';

function ferveurLevel(ferveur) {
  var ferveurLevel = 6;
  if (ferveur < 125) ferveurLevel = 5;
  if (ferveur < 100) ferveurLevel = 4;
  if (ferveur < 75) ferveurLevel = 3;
  if (ferveur < 50) ferveurLevel = 2;
  if (ferveur < 25) ferveurLevel = 1;
  if (ferveur == 0) ferveurLevel = 0;
  var stars = "";

  for (var i = 0; i < ferveurLevel; i++) {
    stars += ' &#9734; ';
  }

  return decodeHTML(stars);
}

var decodeHTML = function decodeHTML(html) {
  var txt = document.createElement('textarea');
  txt.innerHTML = html;
  return txt.value;
};

function parseDate(date) {
  var parsed = Date.parse(date);

  if (!isNaN(parsed)) {
    return parsed;
  }

  return Date.parse(date.replace(/-/g, '/').replace(/[a-z]+/gi, ' '));
}

function framework7Component(props, _ref) {
  var $ = _ref.$,
      $on = _ref.$on,
      $f7route = _ref.$f7route,
      $store = _ref.$store;
  //let utilisateurs = $store.getters.utilisateurs;
  console.log("//188 utilisateur.f7 props : ", props);
  var statuts = $store.getters.statuts; //let userPseudonyme = $f7route.params.userPseudonyme;

  var VezelayToJerusalem = $store.getters.VezelayToJerusalem;
  var currentUtilisateur;
  $on('pageBeforein', function () {
    console.log("//195 utilisateur.f7 pageBeforein");
    displayUtilisateur();
    $('.inviter-popup-link').on('click', function () {
      $f7.emit('displayInviterPopup');
    });
  });

  function displayUtilisateur() {
    console.log("//202 utilisateur.f7 displayUtilisateur()");
    localforage.getItem('syncedConnectedUsers', function (err, syncedConnectedUsers) {
      currentUtilisateur = syncedConnectedUsers.filter(function (d) {
        return d.userPseudonyme == props.userPseudonyme;
      })[0];
      console.log("//196 utilisateur.f7 currentUtilisateur : ", currentUtilisateur);
      currentUtilisateur.niveauFerveur = ferveurLevel(currentUtilisateur.todayFerveur);
      $('.utilisateurNiveauFerveur').html(currentUtilisateur.niveauFerveur);
      currentUtilisateur.statutAppellation = statuts.value[currentUtilisateur.statut.length - 1].appellation;
      currentUtilisateur.statutCouleur = statuts.value[currentUtilisateur.statut.length - 1].couleur; //if(currentUtilisateur.statut.length < 2) currentUtilisateur.displayEscouade = "no-display";
      //if(currentUtilisateur.statut.length < 3) currentUtilisateur.displayDepart = "no-display";
      //if(currentUtilisateur.statut.length < 4) currentUtilisateur.displayPosition = "no-display";

      var options = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      };
      console.log("//227 utilisateur.f7 currentUtilisateur.statut[0]", currentUtilisateur.statut[0]);
      currentUtilisateur.dateInscription = new Date(parseDate(currentUtilisateur.statut[0])).toLocaleDateString("fr-FR", options); //console.log("utilisateur: ",utilisateur);
      //if(currentUtilisateur.statut[3]) {	
      //	delete options.weekday;
      //	currentUtilisateur.dateDepart = new Date(currentUtilisateur.statut[3]).toLocaleDateString("fr-FR", options);
      //}
      // calcul position

      localforage.getItem('syncedJSONContents').then(function (syncedJSONContents) {
        var VezelayToJerusalem = JSON.parse(syncedJSONContents.filter(function (d) {
          return d.id == "Vezelay-Jerusalem";
        })[0].contenu).contents;
        var positionPrecedente = VezelayToJerusalem.filter(function (d) {
          return d.km < currentUtilisateur.totalKm;
        });
        positionPrecedente = positionPrecedente[positionPrecedente.length - 1];
        var positionExacte = VezelayToJerusalem.filter(function (d) {
          return d.km == currentUtilisateur.totalKm;
        })[0];
        var positionSuivante = VezelayToJerusalem.filter(function (d) {
          return d.km > currentUtilisateur.totalKm;
        })[0];
        console.log("//230 utilisateur.f7 positionPrecedente : ", positionPrecedente);
        console.log("//231 utilisateur.f7 positionExacte : ", positionExacte);
        console.log("//232 utilisateur.f7 positionSuivante : ", positionSuivante);
        if (positionExacte) currentUtilisateur.position = positionExacte.lieu;else currentUtilisateur.position = positionPrecedente.lieu;
        $('#position').html(currentUtilisateur.position); //current.positionLocaliteSuivante = positionSuivante.lieu;

        $('#positionLocaliteSuivante').html(positionSuivante.lieu);

        if (positionPrecedente) {
          currentUtilisateur.aKmDeLocalitePrecedente = (currentUtilisateur.totalKm - positionPrecedente.km).toFixed(1);
          if (positionExacte) currentUtilisateur.aKmDeLocalitePrecedente = "A :";else currentUtilisateur.aKmDeLocalitePrecedente = currentUtilisateur.aKmDeLocalitePrecedente + " km depuis :";
          $('#aKmDeLocalitePrecedente').html(currentUtilisateur.aKmDeLocalitePrecedente);
        }

        currentUtilisateur.kmToLocaliteSuivante = (positionSuivante.km - currentUtilisateur.totalKm).toFixed(1);
        $('#kmToLocaliteSuivante').html(currentUtilisateur.kmToLocaliteSuivante);
        var maspUrl = currentUtilisateur.position.replace(/, /gi, "+");
        currentUtilisateur.maspUrl = maspUrl; // new Date(currentUtilisateur.dateDepartISO).toLocaleDateString("fr-FR", options);

        $('.utilisateurStatutAppellation').html(currentUtilisateur.statutAppellation);
        $('.utilisateurUserPseudonyme').html(currentUtilisateur.userPseudonyme);
        $('.utilisateurStatutAppellation').html(currentUtilisateur.statut.appellation); //$('.utilisateurDateDepart').html(currentUtilisateur.dateDepartISO);

        console.log("//270 utilisateur.f7 currentUtilisateur.dateDepartISO", currentUtilisateur.dateDepartISO);

        if (currentUtilisateur.dateDepartISO) {
          $('.utilisateurDateDepart').html(new Date(parseDate(currentUtilisateur.dateDepartISO)).toLocaleDateString("fr-FR", options));
          $('.dateDepart').show();
        } else {
          $('.dateDepart').hide();
        }

        $('.utilisateurPosition').html('A : ' + currentUtilisateur.position);
      }).catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });

      if (currentUtilisateur.userResidence) {
        $('.utilisateurUserResidence').html('De : ' + currentUtilisateur.userResidence);
        $('.userResidence').show();
      } else {
        $('.userResidence').hide();
      }

      if (currentUtilisateur.escouade) {
        $('.utilisateurEscouade').html(currentUtilisateur.escouade.nom);
        $('.escouade').show();
      } else {
        $('.escouade').hide();
      }

      $('.utilisateurUserPrenom').html(currentUtilisateur.userPrenom);
      $('.utilisateurUserPatronyme').html(currentUtilisateur.userPatronyme);
      $('.utilisateurSexe').html(currentUtilisateur.sexe);
      $('.utilisateurTodayKm').html(currentUtilisateur.todayKm);
      $('.utilisateurTotalKm').html(currentUtilisateur.totalKm);
      $('.utilisateurDateInscription').html(currentUtilisateur.dateInscription);
      $('.utilisateurComment').html(currentUtilisateur.comment);
      $('#carte').attr('style', 'height: 300px; background:' + currentUtilisateur.statutCouleur);
    }); //LF getItem('syncedConnectedUsers'
  }

  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
  <div class="page" data-name="utilisateur">
    <div class="navbar">
      <div class="navbar-bg"></div>
      <div class="navbar-inner sliding">
        <div class="left">
          <a href="#" class="link back">
            <i class="icon icon-back"></i>
            <span class="if-not-md">Retour</span>
          </a>
        </div>
        
        <div class="title utilisateurUserPseudonyme" >[userPseudonyme]</div>
        <div class="right">
			<a href="#inv" class="tab-link icon-only inviter-popup-link">
				<i class="fas fa-user-plus"></i> 
			</a>
			<a href="/messages/" class="tab-link icon-only">
            <i class="icon material-icons">mail_outline<span class="badge color-red newMessages" id="newMessages">3</span></i> 
          </a>
        </div>
      </div>
    </div>
    <div class="page-content">
		<div class="card card-expandable">
		<div class="card-content">
			<div id="carte" class="text-color-white" style="height: 300px; background: ">
				<div class="card-header display-block">
					<span class="utilisateurUserPseudonyme">[userPseudonyme]</span> <span class="utilisateurNiveauFerveur" style="font-size: 0.6em">[niveauFerveur]</span>
					<div class="list">
						<ul>
							<li>
							<div class="item-content">
							  <div class="item-media"><i class="fas fa-certificate"></i></div>
							  <div class="item-inner">
								<div class="item-title utilisateurStatutAppellation"> [statutAppellation] </div>
							  </div>
							</div>
							</li>
							<li class="">
							<div class="item-content dateDepart">
							  <div class="item-media"><i class="fas fa-calendar-check"></i></div>
							  <div class="item-inner">
								  <div class="item-header">Départ le</div>
								<div id="messagesUserDepart" class="item-title utilisateurDateDepart">[dateDepart]</div>
							  </div>
							</div>
							</li>
							<li class="">
							<div class="item-content">
							  <div class="item-media"><i class="fas fa-map-marked-alt"></i></div>
							  <div class="item-inner">
								<div id="messagesUserPosition" class="item-title utilisateurPosition">[position]</div>
							  </div>
							</div>
							</li>
							<li class="">
							<div class="item-content escouade">
							  <div class="item-media"><i class="fas fa-users"></i></div>
							  <div class="item-inner">
								<div class="item-title utilisateurEscouade">[escouade]</div>
							  </div>
							</div>
						  </li>
						  <li>
							<div class="item-content userResidence">
							  <div class="item-media"><i class="fas fa-house-user"></i></div>
							  <div class="item-inner">
								<div class="item-title utilisateurUserResidence"> de [userResidence]</div>
							  </div>
							</div>
						  </li>
						</ul>
					</div>
				</div>
				<div class="link card-close card-opened-fade-in color-white" style="position: absolute; right: 15px; top: 30px">
					<i class="icon f7-icons">xmark_circle_fill</i>
				</div>
			</div>
			<div class="card-content-padding">
			  <div class="list">
				<ul>
				  <li>
					<div class="item-content">
					  <div class="item-media"><i class="far fa-address-card"></i></div>
					  <div class="item-inner">
						<div class="item-title">
						  <div class="item-header">Identité</div><span class="utilisateurUserPrenom">[userPrenom]</span> <span class="utilisateurUserPatronyme">[userPatronyme]</span>
						</div>
						
					  </div>
					</div>
				  </li>
				  <li>
					<div class="item-content">
					  <div class="item-media"><i class="fas fa-venus-mars"></i></div>
					  <div class="item-inner">
						<div class="item-title">
						  <div class="item-header">Sexe</div><span class="utilisateurSexe">[sexe]</span>
						</div>
						
					  </div>
					</div>
				  </li>
				  <li>
					<div class="item-content">
					  <div class="item-media"><i class="fas fa-route"></i></div>
					  <div class="item-inner">
						<div class="item-title">
						  <div class="item-header">Distance parcourue aujourd'hui</div><span class="utilisateurTodayKm">[todayKm]</span> km.
						</div>
						
					  </div>
					</div>
				  </li>
				  <li>
					<div class="item-content">
					  <div class="item-media"><i class="fas fa-road"></i></div>
					  <div class="item-inner">
						<div class="item-title">
						  <div class="item-header">Distance totale parcourue</div><span class="utilisateurTotalKm">[totalKm]</span> km.
						</div>
						
					  </div>
					</div>
				  </li>
				
			  
			  <li>
					<div class="item-content">
					  <div class="item-media"><i class="fas fa-sign-in-alt"></i></div>
					  <div class="item-inner">
						<div class="item-title">
						  <div class="item-header">Inscription</div><span class="utilisateurDateInscription">[dateInscription]</span>
						</div>
						
					  </div>
					</div>
				  </li>
				  <li>
					<div class="item-content">
					  <div class="item-media"><i class="far fa-hand-pointer"></i></div>
					  <div class="item-inner">
						<div class="item-title">
						  <div class="item-header">Commentaire</div>
						  <div class="item-text utilisateurComment"><span>[comment]</span></div>
						  
						</div>
						
					  </div>
					</div>
				  </li>
				</ul>
			  </div>
			</div>
		</div>
	</div> <!-- card -->
   
    </div>
  </div>
`
    }
    ;
}

framework7Component.id = '6f2b0703e2';
export default framework7Component;