
import { createStore } from 'framework7';
import js_date from './utils.js/date.js';
const store = createStore({
  state: {
		profil: {
			passwordChanged: false,
			comment: null,
			escouade: null,
			dateDepart : null,
			email:null,
			password:null,
			hashEmail: null,
			hashPassword: null,
			
			sexe: "homme",
			statut: [js_date("Y-m-d H:i")],
			updated: 0, // js_date("Y-m-d H:i")
			userPatronyme: null,
			userPrenom: null,
			userPseudonyme: null,
			userResidence: null,
			syncParams: {
				duree_sync:8,
				automaticSync:["oui"]
				},
			meditationsValidees:[],
			stats: [],
			validatedActivities:[],
			candidatures: []
		},
		statuts: [
			{
				statutId : "invite",
				couleur:'#2b170a', //  graphite - invite
				appellation:'Invité',
				refBiblique:'Mt 22,2.4' 
			},
			{
				statutId : "visiteur",
				couleur:'#555555', // gray - visiteur (inscrit)
				appellation:'Visiteur',
				refBiblique:'Tob 1,19'
			},
			{
				statutId : "etranger",
				couleur:'#999999', //  gris clair - etranger (volontaire)
				appellation:'Etranger et de passage',
				refBiblique:'Heb 11,13-16'
			},
			{
				statutId : "enPartance",
				couleur:'#933d04', // Oregon - enPartance
				appellation:'En partance',
				refBiblique:'Sir 8,15'
			},
			{
				statutId : "enChemin",
				couleur:'#360944', // Amarante - enChemin
				appellation:'En chemin (aspirant Garde)',
				refBiblique:'Esd 8,31'
			},
			{
				statutId : "arrive",
				couleur:'#c07106', //  Indochine - arrive
				appellation:'Arrivé (aspirant Garde)',
				refBiblique:'Ps 121'
			},
			{
				statutId : "garde",
				couleur:'#da9405', //  Tahiti gold- garde
				appellation:'Garde',
				refBiblique:'Mt 1,19-25'
			}		
		],
    utilisateurs: [
      {
        userPseudonyme: 'Administrateur',
        sexe:'homme',
        userPrenom:'',
        userNom:'',
        userResidence:'Nouakchott',
        comment:'Gendarmerie nationale, vos papiers s\'il vous plaît.',
        lastConnexion:'2021-02-18 08:45',
        escouade:[],
        todayKm:0,
        totalKm:0,
        todayFerveur:0,
        icone:'<i class="fas fa-info-circle"></i>',
        statut:['2021-02-18 08:45'] 
      },
      {
        userPseudonyme: 'Xavier',
        sexe:'homme',
        userPrenom:'',
        userNom:'',
        userResidence:'Nouakchott',
        comment:'En route, en avant toute.',
        lastConnexion:'2021-02-18 08:45',
        escouade:'Saint Maur',
        todayKm:23,
        totalKm:45,
        todayFerveur:54,
        statut:["2021-02-03 08:11","2021-02-03 10:22","2021-02-04 07:17","2021-02-04 07:17","2021-02-16 08:20","2021-02-2 08:20"] 
      },
      {
        userPseudonyme: 'Aurélie',
        sexe:'femme',
        userPrenom:'Aurélie',
        userNom:'',
        userResidence:'',
        comment:'Sur ma dune.',
        lastConnexion:'2021-02-18 08:45',
        escouade:'Pélerinage seul',
        todayKm:32,
        totalKm:72,
        todayFerveur:60,
        statut:["2021-02-03 08:11","2021-02-03 10:22","2021-02-04 07:17","2021-02-04 07:17"] 
      },
      {
        userPseudonyme: 'Paulin',
        sexe:'homme',
        userPrenom:'',
        userNom:'',
        userResidence:'Nouakchott',
        comment:'Vive la vie !',
        lastConnexion:'2021-02-18 08:45',
        escouade:'',
        todayKm:23,
        totalKm:45,
        todayFerveur:54,
        statut:["2021-02-03 08:11"]
      },
    ],
    monEscouade: {},
    messagesToSend: [
		],
		VezelayToJerusalem: [
			{ 
				lieu: "Vézelay, 89450, France", 
				km: 0
			},
			{
				lieu: "Savigny-En-Terre-Pleine, 89420, France",
				km: 30
			},
			{
				lieu: "Epoisses, 21460, France",
				km: 37.5
			},
			{
				lieu: "Semur-en-Auxois, 21140, France",
				km: 50.5
			},
			{
				lieu: "Pouillenay, 21150, France",
				km: 61.3
			},
			{
				lieu: "Flavigny-sur-Ozerain, 21150, France",
				km: 67
			},
			{
				lieu: "Boux-sous-Salmaise, 21690, France",
				km: 77.3
			},
			{
				lieu: "Saint-Seine-l'Abbaye, 21440, France",
				km: 90
			},
			{
				lieu: "Curtil-Saint-Seine, 21380, France",
				km: 103
			},
			{
				lieu: "Saussy, 21380, France",
				km: 106.4
			},
			{
				lieu: "Chaignay, 21120, France",
				km: 115.5
			},
			{
				lieu: "Lux, 21120, France",
				km: 128.2
			},
			{
				lieu: "Fontaine-Française, 21610, France",
				km: 142.3
			},
			{
				lieu: "Vars, France",
				km: 155.8
			},
			{
				lieu: "Oyrières, 70600, France",
				km: 158.5
			},
			{
				lieu: "Dampierre-sur-Salon, 70180, France",
				km: 167.4
			},
			{
				lieu: "Seveux, 70130 Seveux-Motey, France",
				km: 172.9
			},
			{
				lieu: "Fresne-Saint-Mamès, 70130, France",
				km: 183.3
			},
			{
				lieu: "Noidans-le-Ferroux, 70130, France",
				km: 190.9
			},
			{
				lieu: "Raze, 70000, France",
				km: 195.7
			},
			{
				lieu: "Vesoul, 70000, France",
				km: 210.5
			},
			{
				lieu: "Noroy-le-Bourg, 70000, France",
				km: 223.1
			},
			{
				lieu: "Mollans, 70240, France",
				km: 230
			},
			{
				lieu: "Vy-lès-Lure, 70200, France",
				km: 236.1
			},
			{
				lieu: "Ronchamp, 70250, France",
				km: 252.1
			},
			{
				lieu: "Giromagny, 90200, France",
				km: 269.1
			},
			{
				lieu: "Étueffont, 90170, France",
				km: 276.9
			},
			{
				lieu: "Guewenheim, France",
				km: 293.1
			},
			{
				lieu: "Cernay, 68700, France",
				km: 304.4
			},
			{
				lieu: "Ensisheim, 68190, France",
				km: 320.8
			}
		]
  },
  getters: {
		profil({ state }) {
			return state.profil;
		},
    utilisateurs({ state }) {
      return state.utilisateurs;
    },
    statuts({ state }) {
      return state.statuts;
    },
    monEscouade({ state }) {
      return state.monEscouade;
    },
    escouades({ state }) {
      return state.escouades;
    },
    messagesToSend({ state }) {
      return state.messagesToSend;
    },
    VezelayToJerusalem({ state }) {
      return state.VezelayToJerusalem;
    },
  },
  actions: {
		updateProfil({ state },profil){
			Object.assign(state.profil,profil);
			console.log("//315 store.js updateProfil",state.profil);
		},
		updateMonEscouade({ state },monEscouade){
			Object.assign(state.monEscouade,monEscouade);
			//state.utilisateurs = [state.monEscouade, monEscouade];
			console.log("//319 store.js updateMonEscouade ",state.monEscouade);
		},
    addUtilisateur({ state }, utilisateur) {
      state.utilisateurs = [...state.utilisateurs, utilisateur];
    },
    addEscouade({ state }, escouade) {
      state.escouades = [...state.escouades, escouade];
    },
    addMessageToSend({ state }, messageToSend) {
      state.messagesToSend = [...state.messagesToSend, messageToSend];
    },
    updateVezelayToJerusalem({ state }, VezelayToJerusalem) {
			state.VezelayToJerusalem = VezelayToJerusalem;
		}
    
	}
		
})
export default store;
