/** @jsx $jsx */
import { $jsx } from 'framework7';
import js_date from '../js/utils.js/date.js';

function parseDate(date) {
  var parsed = Date.parse(date);

  if (!isNaN(parsed)) {
    return parsed;
  }

  return Date.parse(date.replace(/-/g, '/').replace(/[a-z]+/gi, ' '));
}

function framework7Component(props, _ref) {
  var $ = _ref.$,
      $on = _ref.$on,
      $f7 = _ref.$f7,
      $f7route = _ref.$f7route,
      $store = _ref.$store;
  var escouades = $store.getters.escouades;
  var currentEscouade;
  console.log("//169 escouades.f7 props", props);
  $on('pageAfterin', function (e, page) {
    console.log("//171 escouade.f7 $on('pageAfterin',");
    displayEscouade();
    $('.inviter-popup-link').on('click', function () {
      $f7.emit('displayInviterPopup');
    });
  });

  function displayEscouade() {
    var profil = $store.getters.profil;
    localforage.getItem('syncedEscouades', function (err, syncedEscouades) {
      var cetteEscouade = syncedEscouades.filter(function (d) {
        return d.nom == props.id;
      })[0];
      console.log("//177 escouades.f7 cetteEscouade", cetteEscouade);
      currentEscouade = cetteEscouade;
      currentEscouade.nbInscrits = currentEscouade.inscrits.length;
      currentEscouade.nbCandidats = currentEscouade.candidats.length;
      currentEscouade.nbValides = currentEscouade.candidatsValidated.length;
      console.log("//181 escouades.f7 currentEscouade.dateDepartISO", currentEscouade.dateDepartISO);
      currentEscouade.departjnY = js_date('j/n/Y', parseDate(currentEscouade.dateDepart));
      var enseigne = false;
      if (currentEscouade.enseigne == profil.value.userPseudonyme) enseigne = true;
      var displayEscouade = '' + '<div id="card" class="card-header" style="background: ' + currentEscouade.couleur + '"><b>Escouade ' + currentEscouade.nom + '</b></div>' + '<div class="card-content card-content-padding">' + '<div class="list">' + '<ul>' + '<li>';
      if (enseigne) displayEscouade += '<a href="#popup" data-popup="#escouade-gestion-popup" class="popup-open item-link item-content tab-link">';else displayEscouade += '<div class=" item-content">';
      displayEscouade += '<div class="item-media"><i class="fas fa-map-marked-alt"></i></div>' + '<div class="item-inner">' + '<div class="item-title">' + '<div class="item-header">Escouade établie à : </div>' + currentEscouade.lieu + '</div>';
      if (enseigne) displayEscouade += '<div class="item-after no-display">Modifier</div>';
      displayEscouade += '</div>';
      if (enseigne) displayEscouade += '</a>';else displayEscouade += '</div>';
      displayEscouade += '</li>' + '<li>' + '<a href="/utilisateur/' + currentEscouade.enseigne + '/" class="item-link item-content tab-link user">' + '<span class="user-id"></span>' + '<div class="item-media"><i class="fas fa-user-shield"></i></div>' + '<div class="item-inner">' + '<div class="item-title">' + '<div class="item-header">Enseigne :</div> <b>' + currentEscouade.enseigne + '</b>' + '<div class="item-text"><i>En avant</i></div>' + '</div>' + '</div>' + '</a>' + '</li>' + '<li>';
      if (enseigne) displayEscouade += '<a href="#popup" data-popup="#escouade-gestion-popup" class="popup-open item-link item-content tab-link">';else displayEscouade += '<div class=" item-content ">'; // new Date(currentEscouade.dateDepartISO).toLocaleDateString("fr-FR", options);

      var options = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      };
      displayEscouade += '<div class="item-media"><i class="fas fa-calendar-alt"></i></div>' + '<div class="item-inner">' + '<div class="item-title">' + '<div class="item-header">Date de départ</div>' + new Date(currentEscouade.dateDepartISO).toLocaleDateString("fr-FR", options) + '</div>';
      if (enseigne) displayEscouade += '<div class="item-after no-display ">Modifier</div>';
      displayEscouade += '</div>';
      if (enseigne) displayEscouade += '</a>';else displayEscouade += '</div>'; // ICI on met un link pour confirmer qu'on adhère à l'escouade.

      function checkValidated(value) {
        return value == profil.value.userPseudonyme;
      }

      var confirme = '';
      var moi = '';

      if (cetteEscouade.candidatsValidated.find(checkValidated)) {
        confirme = '<a href="#confirme" id="confirmerAdhesionLink" class="item-text text-color-orange">Je confirme</a>';
        moi = ' (dont moi)';
      }

      displayEscouade += '</li>' + '<li>' + '<div class=" item-content ">' + '<div class="item-media"><i class="fas fa-users"></i></div>' + '<div class="item-inner">' + '<div class="item-title">' + '<div class="item-header">Inscrits</div>' + currentEscouade.nbInscrits + '<div class="item-footer">Inscriptions ouvertes</div>' + '</div>' + '<div class="item-after"></div>' + '</div>' + '</div>' + '</li>' + '<span id="escouadeInscrits"></span>' + '<li>' + '<div class="item-content">' + '<div class="item-media"><i class="fas fa-users-cog"></i></div>' + '<div class="item-inner">' + '<div class="item-title">' + '<div class="item-header">Approuvés par l\'enseigne :</div>' + currentEscouade.nbValides + moi + '</div>' + confirme + '</div>' + '</li>' + '<li>';
      if (enseigne) displayEscouade += '<a href="#popup" data-popup="#escouade-gestion-popup" class="popup-open item-link item-content tab-link">';else displayEscouade += '<div class="item-content">';
      displayEscouade += '<div class="item-media"><i class="fas fa-users-cog"></i></div>' + '<div class="item-inner">' + '<div class="item-title">' + '<div class="item-header">En attente d\'approbation par l\'enseigne :</div>' + currentEscouade.nbCandidats + '</div>';
      if (enseigne) displayEscouade += '<div class="item-after no-display">Approuver</div>';
      displayEscouade += '</div>';
      if (enseigne) displayEscouade += '</a>';else displayEscouade += '</div>';
      +'</li>' + '</ul>' + '</div>' + '</div>' + '<div class="card-footer">' + currentEscouade.description + '</div>';
      $('#displayEscouade').html(displayEscouade);
      $('#confirmerAdhesionLink').on('click', function () {
        confirmerAdhesion();
      }); //INSCRITS

      localforage.getItem('syncedConnectedUsers', function (err, syncedConnectedUsers) {
        var escouadeInscritsToDisplay = '';
        var monTotalKm = 0;

        for (var i = 0; i < profil.value.stats.length; i++) {
          monTotalKm += profil.value.stats[i].km;
        }

        for (var i = 0; i < currentEscouade.inscrits.length; i++) {
          var cetUtilisateur = syncedConnectedUsers.filter(function (d) {
            return d.userPseudonyme == currentEscouade.inscrits[i];
          })[0];

          if (cetUtilisateur.userPseudonyme == profil.value.userPseudonyme) {
            //cetUtilisateur.userPseudonyme+=" (vous)";
            cetUtilisateur.after = '(vous)';
          } else {
            //TODO calcul et affichage des positions relatives par rapport au profil avec prise en compte de la date de départ de l'escouade.
            var distance = cetUtilisateur.totalKm - monTotalKm;
            if (distance > 0) cetUtilisateur.after = 'A ' + distance + ' km devant vous.';
            if (distance < 0) cetUtilisateur.after = 'A ' + distance + ' km derrière vous.';
            if (distance == 0) cetUtilisateur.after = 'Vous êtes dans le même lieu.';
          }

          escouadeInscritsToDisplay += '' + '<li>' + '<a href="/utilisateur/' + cetUtilisateur.userPseudonyme + '/" class="item-link item-content tab-link user">' + '<div class="item-media"><i class="fas fa-hiking"></i></div>' + '<div class="item-inner">' + '<div class="item-title">' + '<div class="item-header"></div>' + '<b>' + cetUtilisateur.userPseudonyme + '</b>' + '<div class="item-text"><i>' + cetUtilisateur.comment + '</i></div>' + '<div class="item-after">' + cetUtilisateur.after + '</div>' + '</div>' + '</div>' + '</a>' + '</li>';
        }

        $('#escouadeInscrits').html(escouadeInscritsToDisplay); //CANDIDATS

        var escouadeCandidatsToDisplay = '';

        for (var i = 0; i < currentEscouade.candidats.length; i++) {
          var cetUtilisateur = syncedConnectedUsers.filter(function (d) {
            return d.userPseudonyme == currentEscouade.candidats;
          })[0];
          escouadeCandidatsToDisplay += '' + '<li>' + '<label class="item-checkbox item-content">' + '<input type="checkbox" name="candidatsValidated" value="' + cetUtilisateur.userPseudonyme + '" />' + '<i class="icon icon-checkbox"></i>' + '<div class="item-inner">' + '<div class="item-title-row">' + '<div class="item-title">' + cetUtilisateur.userPseudonyme + '</div>' //+'<div class="item-after">Voir</div>'
          + '</div>' + '<div class="item-subtitle">' + cetUtilisateur.comment + '</div>' //+'<div class="item-text">'+cetUtilisateur.comment
          + '</label>' + '</li>';
        }

        $('#escouadeListeCandidats').html(escouadeCandidatsToDisplay);
        $f7.form.fillFromData('#gestion-escouade', currentEscouade);
        $('#gestionEscouadeNom').html(currentEscouade.nom);
        /*
        $('#gestionEscouadeNom').prop('value',currentEscouade.nom);
        $('#gestionEscouadeLieu').prop('value',currentEscouade.lieu);
        $('#gestionEscouadeDateDepart').prop('value',currentEscouade.dateDepartISO);
        $('#gestionEscouadeDescription').prop('value',currentEscouade.description);
        */
      }); //LF getItem('syncedConnectedUsers'
    }); //LF getItem('syncedEscouades'
  }

  function modifierEscouade() {
    console.log("//312 escouade.f7 modifierEscouade()"); // $('#gestionEscouadeNom').prop('value','Saint Placide');

    var formData = $f7.form.convertToData('#gestion-escouade');
    formData.nom = $('#gestionEscouadeNom').text();
    var profil = $store.getters.profil;
    var credentials = {
      hashEmail: profil.value.hashEmail,
      hashPassword: profil.value.hashPassword,
      GDH: js_date('Y-m-d H:i:s'),
      userPseudonyme: profil.value.userPseudonyme,
      formData: formData
    };
    console.log("//312 escouade.f7 modifierEscouade() credentials", credentials);
    $f7.request.post($f7.params.server + '/societas2/sync.php', {
      action: 'updateEscouade',
      credentials: JSON.stringify(credentials)
    }).then(function (res) {
      console.log("//327 escouade.f7 updateEscouade res.data = ", res.data); //on met à jour en local les escouades par un event.

      $f7.emit('escouadeUpdated');
      var notificationCallbackOnClose = $f7.notification.create({
        icon: '<i class="f7-icons">info_circle</i>',
        title: 'Modification',
        titleRightText: 'maintenant',
        //subtitle: 'Vous partez maintenant depuis Vézelay vers Jérusalem.',
        text: 'Vous avez modifié cette escouade.',
        closeOnClick: true,
        on: {
          close: function close() {
            // On met à jour l'affichage de l'escouade en rappelant displayEscouade()
            displayEscouade(); // On ferme le popup

            $f7.popup.close('#escouade-gestion-popup', true); //TODO on envoie un message à l'user concerné signé par l'enseigne lui disant qu'il a été approuvé
          }
        }
      });
      notificationCallbackOnClose.open();
    }); // request updateEscouade
  }

  function confirmerAdhesion() {
    console.log("//401 escouade.f7 confirmerAdhesion()");
    var profil = $store.getters.profil;
    $f7.dialog.confirm('Vous allez confirmer votre adhésion à cette escouade. Etes-vous sûr ?', 'Engagement définitif !', function () {
      var credentials = {
        hashEmail: profil.value.hashEmail,
        hashPassword: profil.value.hashPassword,
        GDH: js_date('Y-m-d H:i:s'),
        userPseudonyme: profil.value.userPseudonyme,
        escouade: props.id
      };
      console.log("//391 escouade.f7 confirmerAdhesion() credentials", credentials);
      $f7.request.post($f7.params.server + '/societas2/sync.php', {
        action: 'confirmeAdhesionEscouade',
        credentials: JSON.stringify(credentials)
      }).then(function (res) {
        //console.log("//395 escouade.f7 confirmeAdhesionEscouade res.data = ",res.data);
        var reponse = JSON.parse(res.data);
        console.log("//398 escouade.f7 confirmeAdhesionEscouade reponse : ", reponse); //On met à jour le statut

        profil.value.statut['3'] = js_date('Y-m-d H:i');
        localforage.getItem('syncedEscouades', function (err, syncedEscouades) {
          var cetteEscouade = syncedEscouades.filter(function (d) {
            return d.nom == credentials.escouade;
          })[0];
          profil.value.escouade = {};
          profil.value.escouade.nom = credentials.escouade;
          profil.value.escouade.clef = reponse.profil.escouade.clef;
          profil.value.dateDepartISO = cetteEscouade.dateDepartISO;
          profil.value.candidatures = []; //profil.value.dateDepartISO = ;

          $store.dispatch('updateProfil', profil.value);
          $f7.emit('escouadeUpdated'); // event profil updated

          $f7.emit('profilUpdated'); // downSyncSyncedUtilisateurs via un event

          $f7.emit('utilisateurUpdated');
          displayEscouade();
        });
        var notificationCallbackOnClose = $f7.notification.create({
          icon: '<i class="f7-icons">info_circle</i>',
          title: 'Confirmaton',
          titleRightText: 'maintenant',
          //subtitle: 'Vous partez maintenant depuis Vézelay vers Jérusalem.',
          text: 'Vous avez confirmé votre adhésion à cette escouade. Votre statut a été mis à jour.',
          cssClass: 'leSaviezVous',
          closeOnClick: true,
          on: {
            close: function close() {
              displayEscouade();
            }
          }
        });
        notificationCallbackOnClose.open();
      }); // request confirmeAdhesionEscouade 
    }); // dialog.confirm
  }

  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
  <div class="page" data-name="escouade">
    <div class="navbar">
      <div class="navbar-bg"></div>
      <div class="navbar-inner sliding">
        <div class="left">
          <a href="#" class="link back">
            <i class="icon icon-back"></i>
            <span class="if-not-md">Retour</span>
          </a>
        </div>
        
        <div class="title">Escouade </div>
        <div class="right">
			<a href="#inv" class="tab-link icon-only inviter-popup-link">
				<i class="fas fa-user-plus"></i> 
			</a>
					<a href="/messages/" class="tab-link icon-only">
            <i class="far fa-envelope" ></i>
          </a>
        </div>
      </div>
    </div>
    <div class="page-content">
      <div id="displayEscouade" class="card card-outline">
	      
      </div>
      <!-- /#displayEscouade --> 
      
      <!-- escouade-inscrits-popup -->
      <div class="popup" id="escouade-inscrits-popup">
      <div class="view">
        <div class="page">
          <div class="navbar">
            <div class="navbar-bg"></div>
            <div class="navbar-inner">
							
              <div class="title">Inscrits</div>
              <div class="right">
                <a href="#" class="link popup-close"><i class="far fa-times-circle"></i></a>
              </div>
            </div>
          </div>
          <div class="page-content">
	    <div class="list">
	      <ul id="escouadeInscrits"></ul>
            </div>
          </div>
	  <!-- page-content -->
	</div>
      </div>
    
    </div>
    <!-- escouade-inscrits-popup -->
    
    
    
    <!-- escouade-gestion-popup -->
      <div class="popup" id="escouade-gestion-popup">
      <div class="view">
        <div class="page">
          <div class="navbar">
            <div class="navbar-bg"></div>
            <div class="navbar-inner">
							
              <div class="title">Escouade : <span id="gestionEscouadeNom"></span></div>
              <div class="right">
                <a href="#" class="link popup-close"><i class="far fa-times-circle"></i></a>
              </div>
            </div>
          </div>
          <div class="page-content">
	    
            <div class="list media-list" id="gestion-escouade">
	      <ul>
		<!-- /li -->
		
		<li class="item-content item-input">
		  <div class="item-media">
			  <i class="fas fa-hiking"></i>
		  </div>
		  <!-- item-media -->
		  <div class="item-inner">
			  <div class="item-title item-label">Date prévue de départ vers Jérusalem :</div>
			  <div class="item-input-wrap">
			  <input name="dateDepartISO" type="date" value="2021-01-15" placeholder="Choisir ..." />
			  </div>
		  </div>
		  <!-- item-inner -->
		</li>
		<!-- /li -->
		
		<li class="item-content item-input">
		    <div class="item-media">
			    <i class="fas fa-map-marked-alt"></i>
		    </div>
		    <!-- item-media -->
		    <div class="item-inner">
			    <div class="item-title item-label">Lieu :</div>
			    <div class="item-input-wrap">
			    <textarea name="lieu" class="resizable" placeholder="Pays, région, paroisse ..."></textarea>
			    </div>
		    </div>
		    <!-- item-inner -->
		</li>
		<!-- /li -->
		
		<li class="item-content item-input">
		    <div class="item-media">
			    <i class="far fa-hand-point-right"></i>
		    </div>
		    <!-- item-media -->
		    <div class="item-inner">
			    <div class="item-title item-label">Description :</div>
			    <div class="item-input-wrap">
				    <textarea name="description" placeholder="Quelques mots sur l'escouade."></textarea>
			    </div>
		    </div>
		    <!-- item-inner -->
		</li>
		<!-- /li -->
		<li class="item-content">
		    <div class="item-media">
			    <i class="fas fa-users-cog"></i>
		    </div>
		    <!-- item-media -->
		    <div class="item-inner">
		      <div class="item-text">Accepter les demandes d'adhésion suivantes :</div>
		    </div>
		    <!-- item-inner -->
		</li>
		<!-- /li -->
		  <span id="escouadeListeCandidats"></span>    
		  <button id="modifierEscouadeBtn" @click=${modifierEscouade} class="col button button-fill color-gray">Gestion de l\'escouade</button>
		      
	      </ul>
	      <!-- /ul -->
      </div>
	      <!-- list media-list -->
            
          </div>
	  <!-- page-content -->
	</div>
      </div>
    
    </div>
    <!-- escouade-gestion-popup -->
    
	</div> 
	<!-- page content -->
		
  </div>
`
    }
    ;
}

framework7Component.id = '6af94523e7';
export default framework7Component;