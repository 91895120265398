/** @jsx $jsx */
import { $jsx } from 'framework7';
import js_date from '../js/utils.js/date.js';

function parseDate(date) {
  var parsed = Date.parse(date);

  if (!isNaN(parsed)) {
    return parsed;
  }

  return Date.parse(date.replace(/-/g, '/').replace(/[a-z]+/gi, ' '));
}

function framework7Component(props, _ref) {
  var $ = _ref.$,
      $on = _ref.$on,
      $f7 = _ref.$f7,
      $f7route = _ref.$f7route,
      $store = _ref.$store;
  var escouades = $store.getters.escouades; //let id = $f7route.params.id;

  var current = {};
  var profil = $store.getters.profil;
  current.num_meditation = props.id;
  current.dateTS = Date.now() / 1000;
  current.datejnY = js_date("j/n/Y");
  console.log("//150 meditation.f7 props : ", props);
  localforage.getItem('syncedContents', function (err, syncedContents) {
    var r = syncedContents.filter(function (d) {
      return d.id == "ascese_de_lentree_en_oraison";
    });
    $('#ascese_de_lentree_en_oraison').html(r[0].contenu);
    var r = syncedContents.filter(function (d) {
      return d.id == "ascese_De_l_examen_de_l_oraison";
    });
    $('#ascese_De_l_examen_de_l_oraison').html(r[0].contenu);
  }); //$on('pageInit',() => {
  //	console.log("//67 meditation.f7 pageInit");

  displayMeditation(); //});

  $on('pageAfterIn', function (e, page) {
    console.log("//165 meditation.f7 pageAfterIn");
    $('#prev_meditation').on('click', updateMeditationPrecedente);
    $('#next_meditation').on('click', updateMeditationSuivante);
    $('#boutonCheckMeditation').on('click', checkMeditation);
    $('.inviter-popup-link').on('click', function () {
      $f7.emit('displayInviterPopup');
    });
  });

  function updateMeditationPrecedente() {
    console.log("//172 meditation.f7 updateMeditationPrecedente()");
    current.num_meditation = Number(current.num_meditation) - 1;
    current.dateTS = current.dateTS - 60 * 60 * 24;
    current.datejnY = js_date("j/n/Y", current.dateTS);
    displayMeditation();
  }

  function updateMeditationSuivante() {
    current.num_meditation = Number(current.num_meditation) + 1;
    current.dateTS = current.dateTS + 60 * 60 * 24;
    current.datejnY = js_date("j/n/Y", current.dateTS);
    displayMeditation();
  }

  function addActivity(type, activite) {
    var profil = $store.getters.profil;
    console.log("//105 meditation.f7 profil.value.validatedActivities", profil.value.validatedActivities);
    var newActivity = {
      type: type,
      activite: activite,
      dateTS: Date.now() / 1000,
      dateYmdHi: js_date("Y-m-d H:i")
    };
    profil.value.validatedActivities.push(newActivity);
    profil.value.updated = js_date("Y-m-d H:i");
    $store.dispatch('updateProfil', profil.value);
    $f7.emit('profilUpdated', true); //mute = true

    $f7.emit('utilisateurUpdated');
    var nouveauMessage = {
      auteur: profil.value.userPseudonyme,
      text: activite,
      type: type,
      TS: Date.now() / 1000
    };

    if ($f7.params.isOnLine == true) {
      // on notifie d'envoi du message sur Laus Perennis
      var notificationFull = $f7.notification.create({
        icon: '<i class="far fa-envelope"></i>',
        title: 'Laus perennis',
        titleRightText: 'Maintenant',
        subtitle: 'Notification',
        text: 'Vous avez contribué à la louange pérenne.',
        closeTimeout: 3000
      });
      notificationFull.open();
    } else {
      var notificationFull = $f7.notification.create({
        icon: '<i class="far fa-envelope"></i>',
        title: 'Laus perennis',
        titleRightText: 'Maintenant',
        subtitle: 'Notification',
        text: 'Vous avez contribué à la louange pérenne. La notification apparaîtra lorque votre terminal sera relié à internet.',
        closeTimeout: 3000
      });
      notificationFull.open();
    }

    $f7.emit('addNouveauMessage', nouveauMessage);
  }

  function checkMeditation() {
    console.log("//152 prieres.f7 Check de " + current.num_meditation);
    var profil = $store.getters.profil;
    profil.value.meditationsValidees.push(js_date("Y-m-d"));
    $('#boutonCheckMeditation').html(js_date("H:i"));
    $('#boutonCheckMeditation').css("background", "gray");
    $('#boutonCheckMeditation').off('click', checkMeditation); //$f7.view.current.router.refreshPage();

    addActivity('meditation', current.num_meditation);
  }

  function displayMeditation() {
    console.log("//247 meditation.f7 displayMeditation()");
    console.log("//248 meditation.f7 displayMeditation() current.num_meditation :", current.num_meditation);
    localforage.getItem('syncedJSONMeditations', function (err, syncedJSONMeditations) {
      $('#dateJnY_meditation').html('A faire le ' + current.datejnY);
      if (current.datejnY == js_date('j/n/Y')) $('#boutonCheckMeditation').show();else $('#boutonCheckMeditation').hide();

      if (profil.value.meditationsValidees.length >= current.num_meditation) {
        console.log("//255 meditation.f7 displayMeditation() a déjà été validée le " + profil.value.meditationsValidees[Number(current.num_meditation) - 1]);
        var dateMeditationFaite = parseDate(profil.value.meditationsValidees[Number(current.num_meditation) - 1]) / 1000;
        $('#dateJnY_meditation').html('Faite le ' + js_date('j/n/Y', dateMeditationFaite));
        $('#boutonCheckMeditation').hide();
      }

      var cetteMeditation = syncedJSONMeditations.filter(function (d) {
        return d.id == current.num_meditation;
      });
      var prevNum_meditation = Number(current.num_meditation) - 1;
      var prevMeditation = syncedJSONMeditations.filter(function (d) {
        return d.id == prevNum_meditation;
      });

      if (prevMeditation[0] == undefined) {
        //console.log("//106 meditation.f7 pas de prevMeditation");
        $('#prev_meditation').hide();
      } else $('#prev_meditation').show();

      var nextNum_meditation = Number(current.num_meditation) + 1;
      var nextMeditation = syncedJSONMeditations.filter(function (d) {
        return d.id == nextNum_meditation;
      });

      if (nextMeditation[0] == undefined) {
        //console.log("//106 meditation.f7 pas de nextMeditation");
        $('#next_meditation').hide();
      } else $('#next_meditation').show(); //console.log("//73 meditation.f7 cetteMeditation[0] : ",cetteMeditation[0].contenu);


      $('#meditation').html(cetteMeditation[0].contenu);
      $('#num_meditation').html(current.num_meditation);
      var allP = document.getElementsByTagName('p');

      for (var i = 0; i < allP.length; i++) {
        //console.log("//300 prieres.f7 allTr : ",allTr[i]);
        allP[i].addEventListener('dbltap', function () {
          this.classList.toggle('elmtSelected');
          if ($('.elmtSelected').length > 0) $('.icon-fab').removeClass("no-display");else $('.icon-fab').addClass("no-display");
          $('.share').on('click', function () {
            console.log("//305 meditation.f7 .share clicked");
            console.log("//306 meditation.f7 $('.elmtSelected')", $('.elmtSelected'));
            share($('.elmtSelected'));
          });
          $('.shareRS').on('click', function () {
            shareRS($('.elmtSelected'));
          });
        });
      }
    }); // LF getItem('syncedMeditations'

    function share(selectedElts) {
      //addActivity('share','meditation')
      var contenu = "";
      console.log("//321 meditation.f7 selectedElts : ", selectedElts);

      for (var i = 0; i < selectedElts.length; i++) {
        contenu += '<p>' + selectedElts[i].innerHTML + '</p>';
      }

      console.log("//326 meditation.f7 contenu : ", contenu);
      if (selectedElts.length == 0) return; //contenu='<table>'+contenu+'</table>';

      var profil = $store.getters.profil;
      console.log("//330 meditation.f7 contenu à envoyer dans LP : ", contenu);
      var nouveauMessage = {};
      nouveauMessage.type = 'citation';
      nouveauMessage.text = contenu;
      nouveauMessage.auteur = profil.value.userPseudonyme;
      nouveauMessage.TS = Date.now() / 1000;
      $f7.emit('addNouveauMessage', nouveauMessage);
      var notificationFull = $f7.notification.create({
        icon: '<i class="fas fa-share-alt"></i>',
        title: 'Laus perennis',
        titleRightText: 'maintenant',
        subtitle: 'Partagé',
        text: 'Vous avez partégé cet extrait dans <i>Laus perennis</i>.',
        closeTimeout: 3000
      });
      notificationFull.open();
      $('.elmtSelected').removeClass('elmtSelected');
      $('.icon-fab').addClass("no-display");
    }

    function shareRS(selectedElts) {
      var contenu = "";

      for (var i = 0; i < selectedElts.length; i++) {
        contenu += ' ' + selectedElts[i].innerText;
      }

      if (selectedElts.length == 0) return;
      var url;
      if ($f7.device.android) url = 'https://play.google.com/store/apps/details?id=org.societaslaudis.app';
      var socialShareOptions = {
        message: contenu,
        // not supported on some apps (Facebook, Instagram)
        subject: 'Societas laudis',
        // fi. for email
        //:files: ['', ''], // an array of filenames either locally or remotely
        url: url,
        chooserTitle: 'Choisissez : ' // Android only, you can override the default share sheet title
        //appPackageName: 'com.apple.social.facebook', // Android only, you can provide id of the App you want to share with
        //iPadCoordinates: '0,0,0,0' //IOS only iPadCoordinates for where the popover should be point.  Format with x,y,width,height

      };

      var onSuccess = function onSuccess(result) {
        console.log("Share completed? " + result.completed); // On Android apps mostly return false even while it's true

        console.log("Shared to app: " + result.app); // On Android result.app since plugin version 5.4.0 this is no longer empty. On iOS it's empty when sharing is cancelled (result.completed=false)
      };

      var onError = function onError(msg) {
        console.log("Sharing failed with message: " + msg);
      };

      $('.elmtSelected').removeClass('elmtSelected');
      $('.icon-fab').addClass("no-display");
      window.plugins.socialsharing.shareWithOptions(socialShareOptions, onSuccess, onError);
    }
  }

  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
  <div class="page" data-name="meditation">
    <div class="navbar">
			<div class="navbar-bg"></div>
			<div class="navbar-inner">
			<div class="left">
				<a href="#" class="link back">
				<i class="icon icon-back"></i>
				<span class="if-not-md">Retour</span>
				</a>
			</div>
			<!-- left -->
			<div class="title">Méditation n°<span id="num_meditation"></span></div>
				<div class="right">
					<a href="#inv" class="tab-link icon-only inviter-popup-link">
				<i class="fas fa-user-plus"></i> 
			</a>
					<a href="/messages/" class="tab-link icon-only">
            <i class="icon material-icons">mail_outline<span class="badge color-red newMessages" id="newMessages">3</span></i> 
          </a>
				</div>
				<!-- right -->
			</div>
			<!-- navbar-inner -->
			
			<div class="subnavbar">
        <div class="subnavbar-inner">
			  <!-- Subnavbar content -->
			  <div class="left">
					<a href="#" id="prev_meditation" class="link">
					<i class="fas fa-angle-left"></i>
					</a>
				</div>
						<!-- left -->
			  <div id="dateJnY_meditation"></div>
			  <div class="right">
					<a href="#" id="next_meditation" class="link">
					<i class="fas fa-angle-right"></i>
					</a>
				</div>
				<!-- right -->
				</div>
				<!-- subnavbar-inner -->
			</div>
			<!-- subnavbar -->
			
			</div>
			<!-- navbar-->
    
     <div id="fabBackdrop" class="fab-backdrop"></div>
			<div class="fab fab-left-top ">
			<a href="#" class="icon-fab no-display">
				<i class="fas fa-share"></i>
				<i class="fas fa-times"></i>
			</a>
			<div class="fab-buttons fab-buttons-bottom">
				<!-- <a >Partager</a> -->
				<a class="fab-label-button share fab-close" href="#LP">
					<span><i class="fas fa-share-square "></i></span>
					<span class="fab-label">Laus perennis</span>
				</a>
				<a class="fab-label-button shareRS fab-close" href="#RS">
					<span><i class="fas fa-share-alt-square"></i></span>
					<span class="fab-label">Réseaux sociaux</span>
				</a>
			</div>
	  </div>
    
    
    <div class="page-content">
			<br />
			<a href="#" class="button button-outline button-round popup-open" data-popup="#entree-oraison-popup">De l'entrée en oraison</a>	
			<div class="block block-strong contentJustify" id="meditation">
			</div>
		<a id="boutonCheckMeditation" href="#" class="button button-raised button-fill"><i class="fas fa-check"></i></a>	  
		<br />
		<a href="#" class="button button-outline button-round popup-open" data-popup="#examen-oraison-popup">De l'examen de l'oraison</a>	  
		<br />
		
		
		<!-- entree-oraison-popup -->
    <div class="popup" id="entree-oraison-popup">
      <div class="view">
        <div class="page">
          <div class="navbar">
            <div class="navbar-bg"></div>
            <div class="navbar-inner">				
              <div class="title">De l'entrée en oraison.</div>
              <div class="right">
                <a href="#" class="link popup-close"><i class="far fa-times-circle"></i></a>
              </div>
            </div>
          </div>
          <div class="page-content">
            <div class="block">
							   
								
								<div class="contentJustify">
									<h3>Comment bien faire sa méditation et / ou son oraison ?</h3> 
									<h4>Indications pratiques et rappels du directoire.</h4>
									<p>Avant mon oraison, je me suis efforcé d'en lire le texte la veille, et 
									j'ai convenu d'une heure précise et d'un créneau suffisant où je pourrai la faire, dans un lieu isolé, où je serai 
									certain de ne pas être dérangé, ni par mes proches, ni par le téléphone notamment mobile. Idéalement, 
									j'ai aménagé un oratoire dans mon lieu d'habitation, dans lequel il y a de saintes images, et / ou un crucifix.
									 Il peut être opportun de prévenir mes proches de ne pas me déranger à l'heure dite. 
									 L'expérience montre également que l'oraiosn se fait mieux, 
									 pour des raisons pratiques, le matin, avant toute activité professionnelle ou familiale.
									 </p>
									<h4>Indications spirituelles.</h4>
									<div id="ascese_de_lentree_en_oraison"></div>
								</div>
									
            </div>
						
          </div>
        </div>
      </div>
    
    </div>
	<!-- entree-oraison-popup -->
	
	<!-- examen-oraison-popup -->
    <div class="popup" id="examen-oraison-popup">
      <div class="view">
        <div class="page">
          <div class="navbar">
            <div class="navbar-bg"></div>
            <div class="navbar-inner">				
              <div class="title">De l'examen de l'oraison.</div>
              <div class="right">
                <a href="#" class="link popup-close"><i class="far fa-times-circle"></i></a>
              </div>
            </div>
          </div>
          <div class="page-content">
            <div class="block">
							<div class="contentJustify">
								<h3>Après sa méditation et / ou son oraison :</h3> 
									<h4>Indications pratiques et rappels du directoire.</h4>
									<p>Après l'oraison ou la méditation, il s'agit pour moi d'en faire un bilan honnête, ses points positifs et négatifs,
									et essayer de mémoriser chacun des points abordés par le texte proposé, afin de pouvoir les évoquer de nouveau dans mon esprit au cours de la journée. 
									Je suis attentif à mesurer le temps passé à la méditation quotidienne, et j'essaie, dans la mesure du possible, de l'allonger. 
									Je fais ensuite faire le bilan quotidien dans l'application.</p>
									<h4>Indications spirituelles.</h4>
								<div id="ascese_De_l_examen_de_l_oraison" >De l'examen de l'oraison.</div>
							</div>
            </div>
          </div>
        </div>
      </div>
    
    </div>
	<!-- examen-oraison-popup -->
		
		</div>
		<!-- page-content -->
  </div>
`
    }
    ;
}

framework7Component.id = '64745518fe';
export default framework7Component;