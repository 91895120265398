/** @jsx $jsx */
import { $jsx } from 'framework7';
import js_date from '../js/utils.js/date.js';
import js_mktime from '../js/utils.js/mktime.js';

function framework7Component(props, _ref) {
  var $f7 = _ref.$f7,
      $f7route = _ref.$f7route,
      $store = _ref.$store,
      $el = _ref.$el,
      $on = _ref.$on,
      $ = _ref.$;
  console.log("//57 messages-contents.f7 $f7route.params ", $f7route.params);
  console.log("//58 messages-contents.f7 props ", props);
  console.log("//59 messages-contents.f7 #barreDeMessagerie ", $('#barreDeMessagerie'));
  var profil = $store.getters.profil;

  function messageEnF7(ceFilteredMessage) {
    console.log("//60 messages-contents.f7 messageEnF7() ");
    var content = [];
    content["invitatoire"] = "Invitatoire.", content["laudes"] = "Office des Laudes du matin.";
    content["tierce"] = "Office de Tierce.";
    content["messe"] = "Messe.";
    content["sexte"] = "Office de Sexte.";
    content["none"] = "Office de None.";
    content["vepres"] = "Office de Vêpres.";
    content["complies"] = "Office des Complies.";
    content["martyrologe"] = "Martyrologe du jour.";
    content["commentaire"] = "Commentaire de l'Evangile.";
    content["regula"] = "Extrait de la Règle de S. Benoît du jour et son commentaire.";
    content["compendium"] = "Extrait du Compendium du CEC du jour.";
    content["Anima_Christi"] = "<i>Anima Christi</i> - Ame du Christ.";
    content["signum_crucis"] = "<i>Signum Crucis</i> - Signe de Croix.";
    content["pater"] = "<i>Pater noster</i> - Notre Père";
    content["ave_maria"] = "<i>Ave Maria</i> - Je vous salue Marie";
    content["gloria_patri"] = "Doxologie";
    content["angelus"] = "Prière de l'Angelus";
    content["benedictio_mensae"] = "<i>Benedicite</i> - Bénédiction de la table";
    content["rosario"] = "Rosaire médité";
    content["rosaire"] = "Rosaire médité";
    content["VENI_Sancte_Spiritus"] = "<i>Veni Sancte Spiritus.</i> - Viens Esprit Saint; avant toute occasion importante.";
    content["salve_regina"] = "<i>Salve Regina</i> - Salut ô Reine";
    content["alma_redemptoris_mater"] = "<i>Alma Redemptoris Mater</i> - Mère du Rédempteur";
    content["Ave_Regina_Caelorum_et_vers"] = "<i>Ave Regina Caelorum</i> - Salut; Reine des Cieux";
    content["Regina_Caeli_et_vers"] = "<i>Regina caeli</i> - Reine du ciel";
    content["Sub_tuum"] = "<i>Sub tuum</i> - Sous l'abri";
    content["memorare"] = "<i>Memorare</i> - Souvenez-vous";
    content["Oratio_ad_Sanctum_Michael"] = "Prière à S. Michel";
    content["angele_dei"] = "Prière à l'ange gardien";
    content["Priere_S_Joseph_S_Francois_de_Sales"] = "Prière à S. Joseph de S. François de Sales";
    content["Oratio_pro_summo_Pontifice"] = "Prière pour le souverain Pontife";
    content["Oratio_S_Thomae_Aquinatis_ante_studium"] = "Prière de S. Thomas avant l'étude.";
    content["Oratio_ante_colligationem"] = "Prière avant de se connecter à Internet.";
    content["Actus_Contritionis"] = "Acte de Contrition";
    var profil = $store.getters.profil; // ici on formate en lisible le message en cours

    if (content[ceFilteredMessage.text]) ceFilteredMessage.text = content[ceFilteredMessage.text]; // ici on créée le message à afficher à partir du contenu dans LF / syncedMesssages

    var ceMessage = {
      text: ceFilteredMessage.text,
      attrs: {
        TS: ceFilteredMessage.TS
      },
      avatar: 'static/hiking.png'
    };

    if (profil.value) {
      if (profil.value.userPseudonyme && ceFilteredMessage.to == profil.value.userPseudonyme) {
        // message privé
        ceMessage.textHeader = 'Personnel';
        ceMessage.cssClass = 'messagePersonnel';
      }
    }

    if (ceFilteredMessage.header) ceMessage.textHeader = ceFilteredMessage.header; //TODO à changer sources d'erreurs potentielles

    if (ceFilteredMessage.type == 'meditation') {
      // c'est une méditation
      ceMessage.textHeader = 'Méditation'; //var num = ceNewSyncedMessage.text.replace('meditation', '');

      ceMessage.text = 'Méditation n°' + ceMessage.text;
    }

    if (ceFilteredMessage.footer) ceMessage.textFooter = ceFilteredMessage.footer;
    ceMessage.textFooter = js_date("H:i", ceFilteredMessage.TS);

    if (profil.value) {
      if (ceFilteredMessage.auteur == profil.value.userPseudonyme) {
        //console.log("//132 messages-contents.f7 ceNewSyncedMessage ",ceNewSyncedMessage);
        if (ceFilteredMessage.upSynced == false) ceMessage.textFooter += ' <i style="font-size: 0.9em;" class="fas fa-history"></i>';else ceMessage.textFooter += ' <i style="font-size: 0.9em;" class="fas fa-check"></i>';
      }
    }

    if (ceFilteredMessage.auteur) ceMessage.name = ceFilteredMessage.auteur;

    if (ceFilteredMessage.isTitle) {
      // C'est une date
      console.log("//144 messages.content.f7 c'est une date");
      var dateOptions = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      };
      var ms = (ceFilteredMessage.TS + 60 * 60 * 6) * 1000;
      var n = new Date(ms);
      ceMessage.text = n.toLocaleString('fr-FR', dateOptions);
      if (js_date('Y-m-d', ceFilteredMessage.TS + 60 * 60 * 6) == js_date('Y-m-d')) ceMessage.text = 'Aujourd\'hui';
      ceMessage.isTitle = ceFilteredMessage.isTitle;
    }

    ceMessage.type = 'received';

    if (profil.value) {
      if (ceFilteredMessage.auteur == profil.value.userPseudonyme) {
        ceMessage.type = 'sent';
        ceMessage.avatar = null;
      }
    }

    return ceMessage;
  } // fin messagesEnF7


  function getSyncedJSONMessages() {
    // On cherche les messages dans LF.syncedJSONMessages
    var filteredMessages;
    localforage.getItem('syncedJSONMessages', function (err, syncedJSONMessages) {
      if (!syncedJSONMessages) syncedJSONMessages = []; // On les filtre en fonction de la catégorie affichée.
      // 1 - Laus perennis

      if (props.type == "lausPerennis") {
        filteredMessages = syncedJSONMessages.filter(function (d) {
          return d.to == undefined;
        });
      } // 2- Administrateur


      if (props.type == "Administrateur") {
        filteredMessages = syncedJSONMessages.filter(function (d) {
          return d.auteur == "Administrateur" || d.isTitle != undefined;
        });
      } // 3- Escouade


      if (props.type == "escouade") {
        var monEscouade = $store.getters.monEscouade;
        console.log("//191 messages-contents.f7 monEscouade ", monEscouade.value);
        console.log("//192 messages-contents.f7 monEscouade.inscrits ", monEscouade.value.inscrits); // var fruits = ["Banana", "Orange", "Apple", "Mango"];
        // var n = fruits.includes("Mango");

        filteredMessages = syncedJSONMessages.filter(function (d) {
          return monEscouade.value.inscrits.includes(d.auteur) && (d.to == undefined || d.to == monEscouade.value.nom) || d.isTitle != undefined;
        });
        console.log("//204 messages-contents.f7 4-Escouade filteredMessages : ", filteredMessages);
      } // 4- profils


      if (props.type == "personnel") {
        filteredMessages = syncedJSONMessages.filter(function (d) {
          return d.auteur == props.vue || d.to == props.vue || d.isTitle != undefined;
        });
        $("#messages-contents-title").html(props.vue);
      } // On les formate var formatedMessages = formateMessages(syncedJSONMessages)


      var formatedMessages = formateMessages(filteredMessages); // on les ajoute dans messages messages.addMessages(formatedMessages,"append", false);

      messages.addMessages(formatedMessages, "append", false);
    });
  } //fin getSyncedJSONMessages

  /*   
     const people = [
       {
         name: 'Kate Johnson',
         avatar: 'https://cdn.framework7.io/placeholder/people-100x100-9.jpg'
       },
       {
         name: 'Blue Ninja',
         avatar: 'https://cdn.framework7.io/placeholder/people-100x100-7.jpg'
       },
     ];
     const answers = [
       'Yes!',
       'No',
       'Hm...',
       'I am not sure',
       'And what about you?',
       'May be ;)',
       'Lorem ipsum dolor sit amet, consectetur',
       'What?',
       'Are you sure?',
       'Of course',
       'Need to think about it',
       'Amazing!!!',
     ];
  */


  var responseInProgress = false;
  var messagebar;
  var messages;

  function upSyncMessages(messagesToSend) {
    var profil = $store.getters.profil; //console.log("//206 messages-content.f7 upSyncMessages profil.value",profil.value);

    console.log("//207 messages-content.f7 upSyncMessages messagesToSend : ", messagesToSend);
    var credentials = {
      dateISO: js_date('Y-m-d H:i'),
      dateLastSyncedMessagesTS: localStorage.dateLastSyncedMessagesTS,
      hashEmail: profil.value.hashEmail,
      hashPassword: profil.value.hashPassword,
      userPseudonyme: profil.value.userPseudonyme,
      GDH: js_date('Y-m-d H:i'),
      messagesToUpSync: messagesToSend
    };
    console.log("//217 messages-content.f7 upSyncMessages credentials", credentials);
    $f7.request.post($f7.params.server + '/societas2/sync.php', {
      action: 'upSyncJSONMessages',
      credentials: JSON.stringify(credentials)
    }).then(function (res) {
      console.log("//221 messages-content.f7 upSyncMessages res.data : ", res.data);
      var reponse = JSON.parse(res.data);
      console.log("//222 app.js action:'downSyncJSONMessages' reponse.messages : ", reponse.messages); // TODO on met à jour la valeur de dateLastSyncedMessagesTS

      localStorage.dateLastSyncedMessagesTS = Date.now() / 1000; //	Puis on enregistre dans LF avec un push

      localforage.getItem('syncedJSONMessages', function (err, syncedJSONMessages) {
        for (var i = 0; i < reponse.messages.length; i++) {
          syncedJSONMessages.push(reponse.messages[i]);
        }

        localforage.setItem('syncedJSONMessages', syncedJSONMessages).then(function (syncedJSONMessages) {
          console.log("//233 messages-content.f7 syncedJSONMessages", syncedJSONMessages);
        }).catch(function (err) {
          console.log(err);
        }); // LF setItem('syncedJSONMessages'
      }); // LF getItem('syncedJSONMessages'
      //  Puis on ajoute les messages non présents à l'écran

      console.log("//241 messages-content.f7 messages", messages); // On retire les messages déjà présents à l'écran et qui viennent d'être downsynced et insérés dans LF

      for (var i = 0; i < reponse.messages.length; i++) {
        // boucle nouveaux messages
        for (var j = messages.messages.length - 1; j >= 0; j--) {
          // boucle messages présents à l'écran
          //messages.removeMessage(message);	Remove message
          //	message - HTMLElement or string (with CSS Selector) or number (with index number of message from messages array) of message to remove
          //	Method returns Messages instance
          if (messages.messages[j].attrs.TS == reponse.messages[i].TS) messages.removeMessage(j);
        }
      } // On met en conformité LF et messages à l'écran


      var formatedMessages = formateMessages(reponse.messages);
      messages.addMessages(formatedMessages);
    }); // request upSyncJSONMessages
  }

  ;

  var sendMessage = function sendMessage() {
    var text = messagebar.getValue().replace(/\n/g, '<br />').trim();
    var messagesToSend = [];

    if (text.trim().length) {
      messagesToSend.push({
        text: text,
        textFooter: js_date('H:i') //TODO ici ajouter l'icône

      });
    } // Clear area


    messagebar.clear(); // Focus area

    if (text.length) messagebar.focus(); // Exit when nothing to send

    if (!messagesToSend.length) return; // Send message

    messages = messages.addMessages(messagesToSend); //upSyncMessages(messagesToSend);

    var nouveauMessage = {
      //auteur: profil.value.userPseudonyme,
      auteur: profil.value.userPseudonyme,
      text: messagesToSend[0].text,
      type: "chat",
      to: props.vue,
      upSynced: false,
      TS: Date.now() / 1000
    };
    $f7.emit('addNouveauMessage', nouveauMessage);
    setTimeout(function () {
      getSyncedJSONMessages();
    }, 4000);
    /*
       // Mock response
       if (responseInProgress) return;
       responseInProgress = true;
       setTimeout(function () {
         var answer = answers[Math.floor(Math.random() * answers.length)];
         var person = people[Math.floor(Math.random() * people.length)];
         messages.showTyping({
           header: person.name + ' is typing',
           avatar: person.avatar
         });
         setTimeout(function () {
           messages.addMessage({
             text: answer,
             type: 'received',
             name: person.name,
             avatar: person.avatar
           });
           messages.hideTyping();
           responseInProgress = false;
         }, 4000);
       }, 1000);
       */
    // fin mock reponse
  }; // fin sendMessage


  $on('pageBeforeIn', function () {
    console.log("//384 messages-contents.f7 pageBeforeIn");
  });
  $on('pageInit', function () {
    $('.tabbar-labels').hide(true);
    messagebar = $f7.messagebar.create({
      el: $el.value.find('.messagebar')
    }); // messagebar.create

    messages = $f7.messages.create({
      el: $el.value.find('.messages'),
      firstMessageRule: function firstMessageRule(message, previousMessage, nextMessage) {
        if (message.isTitle) return false;
        if (!previousMessage || previousMessage.type !== message.type || previousMessage.name !== message.name) return true;
        return false;
      },
      lastMessageRule: function lastMessageRule(message, previousMessage, nextMessage) {
        if (message.isTitle) return false;
        if (!nextMessage || nextMessage.type !== message.type || nextMessage.name !== message.name) return true;
        return false;
      },
      tailMessageRule: function tailMessageRule(message, previousMessage, nextMessage) {
        if (message.isTitle) return false;
        if (!nextMessage || nextMessage.type !== message.type || nextMessage.name !== message.name) return true;
        return false;
      }
    }); // messages.create

    if (props.type == "lausPerennis") $('#barreDeMessagerie').hide(); // 

    getSyncedJSONMessages();
  }); //pageInit

  $on('pageBeforeRemove', function () {
    messagebar.destroy();
    messages.destroy();
    $('.tabbar-labels').show(true);
  }); // pageBeforeRemove

  function formateMessages(filteredMessages) {
    var formattedMessages = [];

    for (var i = 0; i < filteredMessages.length; i++) {
      formattedMessages.push(messageEnF7(filteredMessages[i]));
    }

    return formattedMessages;
  }

  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
  <div class="page" id="messages-contents" data-name="messages-contents">
    <div class="navbar">
      <div class="navbar-bg"></div>
      <div class="navbar-inner">
        <div class="left">
          <a href="#" class="link back">
            <i class="icon icon-back"></i>
            <span class="if-not-md">Retour</span>
          </a>
        </div>
        <div class="title" id="messages-contents-title">Laus perennis</div>
				<div class="right">
					<a href="#inv" class="tab-link icon-only inviter-popup-link">
				<i class="fas fa-user-plus"></i> 
			</a>
				</div>

				 </div>
    <!-- navbar-inner  -->		
    </div>
    <!-- navbar -->
    
    <div id="barreDeMessagerie" class="toolbar messagebar">
			<div class="toolbar-inner">
				
				<div class="messagebar-area">
					<textarea class="resizable" style="max-height: 230px" placeholder="Votre message..."></textarea>
				</div>
				<div class='send-message-container'>
					<a href="#" class="link" @click=${sendMessage}>
						<i class="fas fa-paper-plane"></i>
					</a>
				</div>
			</div>
		</div>
		<!-- messagebar -->
    <div class="page-content messages-content">
      
      <div class="messages">
				<!--
        
        -->
      </div>
			<!-- messages -->
    </div>
  </div>
`
    }
    ;
}

framework7Component.id = '3e069da99b';
export default framework7Component;