/** @jsx $jsx */
import { $jsx } from 'framework7';
import js_date from '../js/utils.js/date.js';

function framework7Component(props, _ref) {
  var $ = _ref.$,
      $on = _ref.$on;
  console.log("//45 contenu.f7 props", props);
  localforage.getItem('syncedContents', function (err, syncedContents) {
    var r = syncedContents.filter(function (d) {
      return d.id == props.id;
    })[0];
    $('#contenu').html(r.contenu);
  });
  $on('pageBeforeIn', function (e, page) {
    console.log("//53 contenu.f7 pageBeforeIn");
    $('#contenu-titre').html(props.titre);
  });
  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
  <div class="page" data-name="contenu">
    <div class="navbar">
			<div class="navbar-bg"></div>
			<div class="navbar-inner">
			<div class="left">
				<a href="#" class="link back">
				<i class="icon icon-back"></i>
				<span class="if-not-md">Retour</span>
				</a>
			</div>
			<!-- left -->
			<div class="title" id="contenu-titre"></div>
				<div class="right">
					
					<a href="/messages/" class="tab-link icon-only">
            <i class="icon material-icons">mail_outline<span class="badge color-red newMessages" id="newMessages">3</span></i> 
          </a>
				</div>
				<!-- right -->
			</div>
			<!-- navbar-inner -->
			
			</div>
			<!-- navbar-->
    
     
    
    <div class="page-content">
		<div id="contenu" class="block contentJustify">
			
		</div>
		
		
	</div>
	<!-- page-content -->
  </div>
`
    }
    ;
}

framework7Component.id = 'd3aec0aed7';
export default framework7Component;